import * as Enum from '@/Enum';

/**
 * Calculate the viewport height by window.innerHeight
 * and set the --vh custom property
 *
 * @return void
 */
export function adjustViewportHeight() {
	document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`);
}

/**
 * @param string pathTo
 * @return string
 */
export function assetRef(pathTo: string): any {
	if (!pathTo || pathTo?.trim().length < 1) {
		console.warn('🔺 Attempting to get empty asset');
		return '';
	}

	// Check if is a full url
	if (pathTo.indexOf('http') > -1) {
		return pathTo;
	}

	try {
		return pathTo[0] === '/' ? require('@/Asset' + pathTo) : require('@/Asset/' + pathTo);
	} catch (e) {
		return '';
	}
}

/**
 * @return Enum.Orientation
 */
export function checkDeviceOrientation(): Enum.Orientation {
	if (window.matchMedia('(orientation: landscape)').matches && window.matchMedia('(max-height: 500px)').matches) {
		return Enum.Orientation.LANDSCAPE_PHONE;
	} else if (window.matchMedia('(orientation: portrait)').matches) {
		return Enum.Orientation.PORTRAIT;
	} else {
		return Enum.Orientation.LANDSCAPE;
	}
}

/**
 * @return void
 */
export function checkGyroscopeAccess(callback: (hasGyroAccess: boolean) => void) {
	let hasAccess = false;

	const handleEvent = () => {
		hasAccess = true;
		window.removeEventListener('devicemotion', handleEvent);
		callback(true);
	};

	window.addEventListener('devicemotion', handleEvent, { once: true });

	setTimeout(() => {
		if (!hasAccess) {
			window.removeEventListener('devicemotion', handleEvent);
			callback(false);
		}
	}, 1000);
}

/**
 * @return boolean
 */
export function isWindows(): boolean {
	return navigator.platform.indexOf('Win') > -1;
}

/**
 * @return void
 */
export function disableSafariPinchZoom(): void {
	document.addEventListener('gesturestart', function (e) {
		e.preventDefault();

		// document.body.style.zoom = 0.99;
	});

	document.addEventListener('gesturechange', function (e) {
		e.preventDefault();

		// document.body.style.zoom = 0.99;
	});
	document.addEventListener('gestureend', function (e) {
		e.preventDefault();

		// document.body.style.zoom = 1;
	});
}

/**
 * @param string url
 * @return Promise<void>
 */
export async function downloadFile(url: string, filename: string): Promise<void> {
	try {
		const response = await fetch(url);
		if (!response.ok) throw new Error(`Cannot fetch file: ${response.statusText}`);

		const data = await response.blob();
		const downloadUrl = window.URL.createObjectURL(data);

		const a = document.createElement('a');
		a.href = downloadUrl;
		a.download = filename;
		document.body.appendChild(a);

		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(downloadUrl);
	} catch (error) {
		console.error('Download error:', error);
	}
}

/**
 * @param string[] urls
 * @return Promise<void>
 */
export async function downloadImagesFromUrls(urls: Record<string, string>[]): Promise<void> {
	for (let i = 0; i < urls.length; i++) {
		await downloadFile(urls[i].url, urls[i].filename);
	}
}

/**
 * @param string prospectUrl
 * @param string buddyUrl
 * @return Promise<void>
 */
export async function mergeImagesAndDownload(prospectUrl: string, buddyUrl: string) {
	// Create new Image objects for both images
	const prospectImage = new Image();
	const buddyImage = new Image();

	// Wait for both images to load
	await Promise.all([
		new Promise((resolve) => {
			prospectImage.onload = resolve;
			prospectImage.crossOrigin = 'anonymous';
			prospectImage.src = prospectUrl;
		}),
		new Promise((resolve) => {
			buddyImage.onload = resolve;
			buddyImage.crossOrigin = 'anonymous';
			buddyImage.src = buddyUrl;
		}),
	]);

	// Create a new canvas and get the context
	const canvas: HTMLCanvasElement = document.createElement('canvas');
	const context: CanvasRenderingContext2D = canvas.getContext('2d') as CanvasRenderingContext2D;

	// Set the canvas size to accommodate both images
	canvas.width = prospectImage.width + buddyImage.width; // Assuming both images have the same width
	canvas.height = prospectImage.height;

	// Draw the images onto the canvas
	context.drawImage(prospectImage, 0, 0);
	context.drawImage(buddyImage, prospectImage.width, 0);

	// Convert to a data URL
	const dataUrl: string = canvas.toDataURL('image/png');

	// Create a link and trigger the download
	const link: HTMLAnchorElement = document.createElement('a');
	link.href = dataUrl;
	link.download = 'combined-image.png'; // Name the file as desired
	document.body.appendChild(link); // Temporarily add the link to the body to trigger download
	link.click();

	// Clean up by removing the link and canvas from the document
	document.body.removeChild(link);
	canvas.remove();
}

/**
 * @param File[] selectedFiles
 * @param string title
 * @param string text
 * @param string url
 * @return Promise<boolean>
 */
export async function shareFiles(files: File[] = [], title: string = '', text: string = '', url: string = ''): Promise<boolean> {
	let shareData = {
		files,
		text,
		title,
		url,
	};

	// Check if we are capable of sharing this data
	if (!navigator.canShare(shareData)) {
		return false;
	}

	// Attempt to share data
	await navigator.share(shareData);

	// Assume success
	return true;
}

/**
 * @param number ms
 * @return Promise<void>
 */
export function sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}
