import * as Collection from '@/Collection';
import * as Model from '@/Model';
import LuckyBuddiesJson from '@/Asset/json/lucky-buddies.json';

/**
 * @return Collection.LuckyBuddy
 */
export function collection(): Collection.LuckyBuddy {
	return Collection.LuckyBuddy.hydrate(LuckyBuddiesJson.data);
}
