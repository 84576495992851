
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewCosmicText extends ViewBase {
		/**
		 * @type string
		 */
		@Prop({ default: 'left' })
		public align!: string;

		/**
		 * @type string
		 */
		@Prop()
		public text!: string;
	}
