
	import ViewBase from '@/View/Base.vue';
	import gsap from 'gsap';
	import { Component, Prop } from 'vue-property-decorator';
	import { Input, Utility } from 'buck-ts';
	import { beforeDestroy, created, mounted } from '@/Utility/Decorators';
	import { scaleBezierPath } from '@/Utility/Path';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Birthdate
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewBirthdateSelector extends ViewBase {
		/**
		 * How many items in total, like 2024-1939
		 *
		 * @type number
		 */
		public get itemCount(): number {
			return this.endValue - this.startValue;
		}

		/**
		 * 10 * 10 * 84 = 8400
		 *
		 * @type number
		 */
		protected get maxScroll(): number {
			return this.spacing * 10 * (this.endValue - this.startValue) + window.innerHeight;
		}

		/**
		 * @return number
		 */
		protected get totalDistance(): number {
			// Calculate the total distance each item needs to travel.
			// This includes the entire height of the container (represented by 100%)
			// plus the spacing for all items except the first one.
			// return 100 + (this.itemCount - 1) * this.spacing;

			// I guess 50 is like.. half way?
			return this.magicalDistanceOffset + (this.itemCount - 1) * this.spacing;
		}

		/**
		 * Month = 30
		 */
		@Prop({ default: 0 })
		public magicalDistanceOffset!: number;

		/**
		 * Month = 20
		 */
		@Prop({ default: 0 })
		public magicalPositionOffset!: number;

		/**
		 * @type string[]
		 */
		@Prop({ default: [] })
		public captions!: string[];

		/**
		 * @type boolean
		 */
		@Prop({ default: false })
		public enabled!: boolean;

		/**
		 * @type number
		 */
		@Prop({ default: 2024 })
		public endValue!: number;

		/**
		 * @type number
		 */
		@Prop({ default: 30 })
		public nearestPercentage!: number;

		/**
		 * @type boolean
		 */
		@Prop({ default: false })
		public reverse!: boolean;

		/**
		 * @type number
		 */
		@Prop({ default: 1940 })
		public startValue!: number;

		/**
		 * @type string
		 */
		@Prop({ default: 'year' })
		public type!: string;

		/**
		 * @type number
		 */
		public value: number = 0;

		/**
		 * @type gsap something
		 */
		protected currentAnimation: any;

		/**
		 * @type boolean
		 */
		protected hasScrolled: boolean = false;

		/**
		 * @type number
		 */
		protected scrollRatio: number = 0;

		/**
		 * Ratio of spacing between items
		 *
		 * @type number
		 */
		protected spacing: number = 15;

		/**
		 * @type number[]
		 */
		protected values: number[] = [];

		/**
		 * @type Input.Pointer
		 */
		private pointer: Input.Pointer = new Input.Pointer('pointer', true);

		/**
		 * @return void
		 */
		@mounted
		public resetScroll(): void {
			this.$el.scrollTop = 0;
			this.scrollRatio = 0;

			// Scroll to bottom to page
			this.jumpToRatio(1);
		}

		/**
		 * @param number ratio
		 * @return void
		 */
		public jumpToRatio(ratio: number): void {
			this.$el.scrollTo(0, ratio * this.maxScroll);
			console.log('Jumping to', this.$el);
		}

		/**
		 * Attempts to pull an index into focus, like scrolling to the 5th item
		 *
		 * @param number index
		 * @param number duration
		 * @return void
		 */
		public scrollToApproximateIndex(fromIndex: number, toIndex: number, duration: number = 1, setScrollTop: boolean = false): Promise<void> {
			const targetRect = this.$el.getBoundingClientRect();

			return new Promise((resolve) => {
				const targetOptions = {
					duration: duration,
					ease: 'power2.inOut',
					onComplete: () => {
						this.$el.scrollTop = (1 - this.scrollRatio) * (this.maxScroll - targetRect.height);
						resolve();
					},
					scrollRatio: toIndex / this.itemCount,
				};

				if (fromIndex >= 0) {
					gsap.fromTo(this, { scrollRatio: fromIndex / this.itemCount }, targetOptions);
				} else {
					gsap.to(this, targetOptions);
				}
			});
		}

		/**
		 * Calculates the position for an item based on scroll ratio and item index.
		 *
		 * Applied directly to the style of each items
		 *
		 * @param number index - The index of the item (0-based).
		 * @returns number The position of the item as a percentage of the motion path.
		 */
		protected calculateItemPosition(index: number): number {
			// Calculate the starting offset for each item so that they are all out of view initially.
			// The first item starts at 0%, and each subsequent item is 50px further up.
			// 20 is the px offset
			const startOffset = index * this.spacing - this.magicalPositionOffset;

			// The entire length of the path multipled by our 0-1 ratio
			// then subtract the starting offset to get the position of the item.
			const position = this.totalDistance * this.scrollRatio - startOffset;

			// Save to values
			this.values[index] = position;

			// Return the position as a percentage of the total distance
			// to be used for setting each item's CSS top or transform property.
			return position;
		}

		/**
		 * @param number ratio
		 * @return number
		 */
		protected calculateItemBlur(index: number): number {
			const position = this.values[index];
			const blurStart = 0,
				blurEnd = 10,
				blurResume = 40, // depends on viewport height
				blurFull = 60;

			if (position <= blurEnd) {
				return (1 - position / blurEnd) * 10;
			} else if (position >= blurResume) {
				return ((position - blurResume) / (blurFull - blurResume)) * 10;
			}

			return 0;
		}

		/**
		 * @param number ratio
		 * @return number
		 */
		protected calculateItemOpacity(index: number): number {
			const position = this.values[index];
			const opacityMin = 12,
				opacityMax = 25; // depends on viewport height

			if (position < opacityMin) {
				return position / opacityMin;
			} else if (position > opacityMax) {
				return (60 - position) / (60 - opacityMax);
			}

			return 1;
		}

		/**
		 * @param number value
		 * @return string
		 */
		protected getCaptionByValue(value: number): string {
			return this.reverse ? (this.startValue - value + this.endValue).toString() : this.captions[value - 1] || value.toString();
		}

		/**
		 * @param number percentage
		 * @return number
		 */
		protected getValueNearestPercentage(percentage: number = 20): number {
			const values = this.values;
			const target = percentage;
			let closest = values[0];

			// If it's not enabled, don't set the value
			if (this.enabled === false) {
				return this.value;
			}

			// Find closest value
			for (let i = 0; i < values.length; i++) {
				if (Math.abs(target - values[i]) < Math.abs(target - closest)) {
					closest = values[i];
				}
			}

			// Save actual value, not the index
			this.value = this.startValue + values.indexOf(closest);

			// Handle value if reversed
			// if (this.reverse) {
			// 	this.value = this.startValue - this.value + this.endValue;
			// }

			// Emit value to others
			if (this.reverse) {
				this.$emit('input', this.startValue - this.value + this.endValue);
			} else {
				this.$emit('input', this.value);
			}

			return this.value;
		}

		/**
		 * @param any e
		 * @return Promise<void>
		 */
		protected async Handle_OnClick(e: any): Promise<void> {
			const { x, y } = e.data;

			const percentageY = y / window.innerHeight;
			const nearestValue = this.getValueNearestPercentage(percentageY * 100);
			const modifiedValue = this.startValue - nearestValue + this.endValue;

			// Get index from value
			const startIndex = this.startValue - this.value + this.endValue - this.startValue;
			const targetIndex = modifiedValue - this.startValue;

			// Scroll to index
			this.scrollToApproximateIndex(-1, this.itemCount - targetIndex + 1, 0.75, true);
		}

		/**
		 * @param Event e
		 * @return Promise<void>
		 */
		protected async Handle_OnScroll(e: Event): Promise<void> {
			const targetRect = this.$el.getBoundingClientRect();

			// Set new target position
			if (!this.hasScrolled) {
				this.$el.scrollTop = (1 - this.scrollRatio) * (this.maxScroll - targetRect.height);
			}

			// Set new target position
			this.scrollRatio = 1 - this.$el.scrollTop / (this.maxScroll - targetRect.height);

			// Clamp between 0.2 and 0.8
			this.scrollRatio = Utility.Math.clamp(this.scrollRatio, 0.01, 0.99);

			// Let us know we scrolled so we don't do fancy animations
			this.hasScrolled = true;

			// If we have an animation, kill it
			if (this.currentAnimation) {
				this.currentAnimation.kill();
				this.currentAnimation = null;
			}
		}
	}
