
	import ViewBase from '@/View/Base.vue';
	import gsap from 'gsap';
	import { Component, Prop, Ref } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';
	import { sleep } from '@/Utility/Helpers';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Graphic
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewGraphicBirthdayHelper extends ViewBase {
		/**
		 * @type boolean
		 */
		protected shouldAnimate: boolean = false;

		/**
		 * @return Promise<void>
		 */
		public async swipe(): Promise<void> {
			this.shouldAnimate = true;

			await sleep(1000 * 2.5);

			// await gsap.fromTo(
			// 	this.pointer,
			// 	{ y: 0 },
			// 	{
			// 		duration: 2,
			// 		ease: 'power2.inOut',
			// 		y: -50,
			// 	},
			// );

			// await gsap.to(this.pointer, {
			// 	duration: 0.5,
			// 	ease: 'power2.inOut',
			// 	y: 0,
			// });
		}
	}
