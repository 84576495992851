import * as Core from '@/Core';
import * as Enum from '@/Enum';
import * as Model from '@/Model';

/**
 * @class Interaction
 * @package Collection
 * @project BunnyDragon
 */
export class Interaction extends Core.Collection<Model.Interaction> {
	/**
	 * @type Model.Interaction
	 */
	public model: Model.Interaction = new Model.Interaction();

	/**
	 * @param string tag
	 * @return Model.Interaction[]
	 */
	public getByTag(tag: string): Model.Interaction[] {
		return this.filter((Interaction: Model.Interaction) => {
			const tags: string[] = Interaction.tags || [];
			return tags.includes(tag.trim().toLowerCase());
		});
	}

	/**
	 * @param Enum.ConversationInteraction tag
	 * @return Model.Interaction[]
	 */
	public getByType(type: Enum.ConversationInteraction): Model.Interaction[] {
		return this.filter((Interaction: Model.Interaction) => {
			return Interaction.type === type;
		});
	}
}
