/* eslint-disable max-lines */

export default {
	data: [
		// region: Round 00 (Emoji)
		// ---------------------------------------------------------------------------

		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'happy',
						dragon: 'idle',
					},
					text: 'Pick the one that aligns with your calling.',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'trusty-rubber-duck',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'used-glow-stick',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'uno-reverse-card',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'cocoon-sleeping-bag',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'last-piece-of-gum',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'overripe-banana',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'dryer-lint',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'epic-magnet',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'fussy-toaster',
					},

					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'sprouting-potato',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'keychain-with-too-many-keys',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'treasure-map',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'extra-heat-hot-sauce',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'fuzzy-animal-slippers',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'origami-star',
					},
				],
			},
			responseType: 'emojiImage',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'wowed',
						},
						text: 'Curious.',
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'excited',
						},
						text: 'Interesting. Well then–',
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'excited-extra',
						},
						text: 'SUBLIME. Moving along–',
					},
				],
			],
			tags: ['emoji', 'image', 'round-00'],
			type: 'MultipleChoice',
		},

		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'idle',
						dragon: 'happy',
					},
					text: 'Which of these pleases you the most?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'trusty-rubber-duck',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'used-glow-stick',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'uno-reverse-card',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'cocoon-sleeping-bag',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'last-piece-of-gum',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'overripe-banana',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'dryer-lint',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'epic-magnet',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'fussy-toaster',
					},

					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'sprouting-potato',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'keychain-with-too-many-keys',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'treasure-map',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'extra-heat-hot-sauce',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'fuzzy-animal-slippers',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'origami-star',
					},
				],
			},
			responseType: 'emojiImage',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'excited',
							dragon: 'excited',
						},
						text: 'OMG Same! Okay okay–',
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'cheeky',
						},
						text: 'Delicious! So–',
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'happy-extra',
						},
						text: 'Divine! Onward–',
					},
				],
			],
			tags: ['emoji', 'image', 'round-00'],
			type: 'MultipleChoice',
		},

		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'happy',
						dragon: 'idle',
					},
					text: 'Select the one that most resonates with your path.',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'trusty-rubber-duck',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'used-glow-stick',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'uno-reverse-card',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'cocoon-sleeping-bag',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'last-piece-of-gum',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'overripe-banana',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'dryer-lint',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'epic-magnet',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'fussy-toaster',
					},

					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'sprouting-potato',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'keychain-with-too-many-keys',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'treasure-map',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'extra-heat-hot-sauce',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'fuzzy-animal-slippers',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'origami-star',
					},
				],
			},
			responseType: 'emojiImage',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'wowed',
						},
						text: 'Curious.',
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'excited',
						},
						text: 'Interesting. Well then–',
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'excited-extra',
						},
						text: 'SUBLIME. Moving along–',
					},
				],
			],
			tags: ['emoji', 'image', 'round-00'],
			type: 'MultipleChoice',
		},

		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'idle',
						dragon: 'happy',
					},
					text: 'Which of these brings the brightest spark to your eyes?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'trusty-rubber-duck',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'used-glow-stick',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'uno-reverse-card',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'cocoon-sleeping-bag',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'last-piece-of-gum',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'overripe-banana',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'dryer-lint',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'epic-magnet',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'fussy-toaster',
					},

					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'sprouting-potato',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'keychain-with-too-many-keys',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'treasure-map',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'extra-heat-hot-sauce',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'fuzzy-animal-slippers',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'origami-star',
					},
				],
			},
			responseType: 'emojiImage',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'excited',
							dragon: 'excited',
						},
						text: 'OMG Same! Okay okay–',
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'cheeky',
						},
						text: 'Delicious! So–',
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'happy-extra',
						},
						text: 'Divine! Onward–',
					},
				],
			],
			tags: ['emoji', 'image', 'round-00'],
			type: 'MultipleChoice',
		},

		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'happy',
						dragon: 'idle',
					},
					text: 'Which of these feels like destiny?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'trusty-rubber-duck',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'used-glow-stick',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'uno-reverse-card',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'cocoon-sleeping-bag',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'last-piece-of-gum',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'overripe-banana',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'dryer-lint',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'epic-magnet',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'fussy-toaster',
					},

					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'sprouting-potato',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'keychain-with-too-many-keys',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'treasure-map',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'extra-heat-hot-sauce',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'fuzzy-animal-slippers',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'origami-star',
					},
				],
			},
			responseType: 'emojiImage',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'wowed',
						},
						text: 'Curious.',
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'excited',
						},
						text: 'Interesting. Well then–',
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'excited-extra',
						},
						text: 'SUBLIME. Moving along–',
					},
				],
			],
			tags: ['emoji', 'image', 'round-00'],
			type: 'MultipleChoice',
		},

		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'idle',
						dragon: 'happy',
					},
					text: 'Which of these delights you the most?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'trusty-rubber-duck',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'used-glow-stick',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'uno-reverse-card',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'cocoon-sleeping-bag',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'last-piece-of-gum',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'overripe-banana',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'dryer-lint',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'epic-magnet',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'fussy-toaster',
					},

					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'sprouting-potato',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'keychain-with-too-many-keys',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'treasure-map',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'extra-heat-hot-sauce',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'fuzzy-animal-slippers',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'origami-star',
					},
				],
			},
			responseType: 'emojiImage',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'excited',
							dragon: 'excited',
						},
						text: 'OMG Same! Okay okay–',
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'cheeky',
						},
						text: 'Delicious! So–',
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'happy-extra',
						},
						text: 'Divine! Onward–',
					},
				],
			],
			tags: ['emoji', 'image', 'round-00'],
			type: 'MultipleChoice',
		},

		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'happy',
						dragon: 'idle',
					},
					text: 'Which one speaks to your purpose?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'trusty-rubber-duck',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'used-glow-stick',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'uno-reverse-card',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'cocoon-sleeping-bag',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'last-piece-of-gum',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'overripe-banana',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'dryer-lint',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'epic-magnet',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'fussy-toaster',
					},

					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'sprouting-potato',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'keychain-with-too-many-keys',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'treasure-map',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'extra-heat-hot-sauce',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'fuzzy-animal-slippers',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'origami-star',
					},
				],
			},
			responseType: 'emojiImage',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'wowed',
						},
						text: 'Curious.',
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'excited',
						},
						text: 'Interesting. Well then–',
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'excited-extra',
						},
						text: 'SUBLIME. Moving along–',
					},
				],
			],
			tags: ['emoji', 'image', 'round-00'],
			type: 'MultipleChoice',
		},

		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'idle',
						dragon: 'happy',
					},
					text: 'Among these treasures, which one would you hold closest?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'trusty-rubber-duck',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'used-glow-stick',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'uno-reverse-card',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'cocoon-sleeping-bag',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'last-piece-of-gum',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'overripe-banana',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'dryer-lint',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'epic-magnet',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'fussy-toaster',
					},

					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'sprouting-potato',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'keychain-with-too-many-keys',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'treasure-map',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'extra-heat-hot-sauce',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'fuzzy-animal-slippers',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'origami-star',
					},
				],
			},
			responseType: 'emojiImage',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'excited',
							dragon: 'excited',
						},
						text: 'OMG Same! Okay okay–',
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'cheeky',
						},
						text: 'Delicious! So–',
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'happy-extra',
						},
						text: 'Divine! Onward–',
					},
				],
			],
			tags: ['emoji', 'image', 'round-00'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'happy',
						dragon: 'idle',
					},
					text: 'Which of these do you need the most?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'trusty-rubber-duck',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'used-glow-stick',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'uno-reverse-card',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'cocoon-sleeping-bag',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'last-piece-of-gum',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'overripe-banana',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'dryer-lint',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'epic-magnet',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'fussy-toaster',
					},

					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'sprouting-potato',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'keychain-with-too-many-keys',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'treasure-map',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'extra-heat-hot-sauce',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'fuzzy-animal-slippers',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'origami-star',
					},
				],
			},
			responseType: 'emojiImage',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'wowed',
						},
						text: 'Curious.',
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'excited',
						},
						text: 'Interesting. Well then–',
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'excited-extra',
						},
						text: 'SUBLIME. Moving along–',
					},
				],
			],
			tags: ['emoji', 'image', 'round-00'],
			type: 'MultipleChoice',
		},

		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'idle',
						dragon: 'happy',
					},
					text: 'What would you choose for the pure joy of it?!',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'trusty-rubber-duck',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'used-glow-stick',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'uno-reverse-card',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'cocoon-sleeping-bag',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'last-piece-of-gum',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'overripe-banana',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'dryer-lint',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'epic-magnet',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'fussy-toaster',
					},

					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'sprouting-potato',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'keychain-with-too-many-keys',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'treasure-map',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'extra-heat-hot-sauce',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'fuzzy-animal-slippers',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'origami-star',
					},
				],
			},
			responseType: 'emojiImage',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'excited',
							dragon: 'excited',
						},
						text: 'OMG Same! Okay okay–',
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'cheeky',
						},
						text: 'Delicious! So–',
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'happy-extra',
						},
						text: 'Divine! Onward–',
					},
				],
			],
			tags: ['emoji', 'image', 'round-00'],
			type: 'MultipleChoice',
		},

		// endregion: Round 00

		// region: Round 01 (Text)
		// ---------------------------------------------------------------------------

		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'sad',
						dragon: 'irritated',
					},
					text: 'Imagine distressing sounds. How would you describe them?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: 'the fade of childhood joy',
						luckyBuddies: ['general-00'],
						shortText: 'Screeching mayhem',
					},
					{
						categories: ['general'],
						extendedText: `ebbing luminescence's soft despair`,
						luckyBuddies: ['general-01'],
						shortText: 'Sputtering sighs',
					},
					{
						categories: ['general'],
						extendedText: 'sudden change, unerving echo',
						luckyBuddies: ['general-02'],
						shortText: 'Sharp flip',
					},
					{
						categories: ['health'],
						extendedText: `solutude's disturbed slumber`,
						luckyBuddies: ['health-00'],
						shortText: 'Rustling echo',
					},
					{
						categories: ['health'],
						extendedText: 'sweetness fractured abruptly',
						luckyBuddies: ['health-01'],
						shortText: 'Sticky snaps',
					},
					{
						categories: ['health'],
						extendedText: 'soft thud of decay',
						luckyBuddies: ['health-02'],
						shortText: 'Rancid squawks',
					},
					{
						categories: ['wealth'],
						extendedText: 'quiet, unoticed agitation',
						luckyBuddies: ['wealth-00'],
						shortText: 'Muffled buzz',
					},
					{
						categories: ['wealth'],
						extendedText: 'forces colliding uncontrollably',
						luckyBuddies: ['wealth-01'],
						shortText: 'Metallic clangs',
					},
					{
						categories: ['wealth'],
						extendedText: `morning's peace evaporation`,
						luckyBuddies: ['wealth-02'],
						shortText: 'Scorching sizzle',
					},
					{
						categories: ['career'],
						extendedText: `growth's uneasy whispers`,
						luckyBuddies: ['career-00'],
						shortText: 'Crackling',
					},
					{
						categories: ['career'],
						extendedText: 'chaos in every step',
						luckyBuddies: ['career-01'],
						shortText: 'Jangling clatter',
					},
					{
						categories: ['career'],
						extendedText: `history's uneasy turning`,
						luckyBuddies: ['career-02'],
						shortText: 'Spooky creaks',
					},
					{
						categories: ['love'],
						extendedText: 'overwhelming, fiercy burst',
						luckyBuddies: ['love-00'],
						shortText: 'Flaming hiss',
					},
					{
						categories: ['love'],
						extendedText: `comfort's uncertain journey, softly faltering`,
						luckyBuddies: ['love-01'],
						shortText: 'Rumbles',
					},
					{
						categories: ['love'],
						extendedText: `hope's piercing echo, fading into night`,
						luckyBuddies: ['love-02'],
						shortText: 'Distant screams',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'awkward',
							dragon: 'surprised',
						},
						text: `...We'll unpack that later.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'devastated',
							dragon: 'shocked',
						},
						text: `Not my first choice, but it's your future...Anyway–`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'shocked',
							dragon: 'irritated',
						},
						text: '[shudders]',
					},
				],
			],
			tags: ['text', 'round-01'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'idle',
						dragon: 'idle',
					},
					text: 'Imagine calming sounds. How would you describe them?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: 'Echos of mirth',
						luckyBuddies: ['general-00'],
						shortText: 'Gentle chirps',
					},
					{
						categories: ['general'],
						extendedText: `night's hushed sparkle`,
						luckyBuddies: ['general-01'],
						shortText: 'Whispers',
					},
					{
						categories: ['general'],
						extendedText: 'Flipping moments of peace',
						luckyBuddies: ['general-02'],
						shortText: 'Quiet shuffle',
					},
					{
						categories: ['health'],
						extendedText: 'embracing in silent comfort',
						luckyBuddies: ['health-00'],
						shortText: 'Silent comfort',
					},
					{
						categories: ['health'],
						extendedText: 'rhythm of thoughtfulness',
						luckyBuddies: ['health-01'],
						shortText: 'Subtle pops',
					},
					{
						categories: ['health'],
						extendedText: `Nature's soft exhale`,
						luckyBuddies: ['health-02'],
						shortText: 'An exhale',
					},
					{
						categories: ['wealth'],
						extendedText: 'Weaving through the fabric of time',
						luckyBuddies: ['wealth-00'],
						shortText: 'Delicate pulse',
					},
					{
						categories: ['wealth'],
						extendedText: 'destiny subtle nodes',
						luckyBuddies: ['wealth-01'],
						shortText: 'Faint clicks',
					},
					{
						categories: ['wealth'],
						extendedText: `dawn's muted murmur`,
						luckyBuddies: ['wealth-02'],
						shortText: 'Soft whirls',
					},
					{
						categories: ['career'],
						extendedText: `earth's quiet awakening`,
						luckyBuddies: ['career-00'],
						shortText: 'Light crescendo',
					},
					{
						categories: ['career'],
						extendedText: 'Echoes of wanderlust',
						luckyBuddies: ['career-01'],
						shortText: 'Light jingling',
					},
					{
						categories: ['career'],
						extendedText: `secrets' soft unfolding`,
						luckyBuddies: ['career-02'],
						shortText: 'Old paper rustle',
					},
					{
						categories: ['love'],
						extendedText: 'whispers of zest',
						luckyBuddies: ['love-00'],
						shortText: 'Mild fizz',
					},
					{
						categories: ['love'],
						extendedText: `night's cozy wander`,
						luckyBuddies: ['love-01'],
						shortText: 'Fluffy steps',
					},
					{
						categories: ['love'],
						extendedText: `stars' gentle folding`,
						luckyBuddies: ['love-02'],
						shortText: 'Soft crease',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'sleezy',
						},
						text: `I'm partial to panpipes but that's cool too!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'excited',
							dragon: 'happy-extra',
						},
						text: `Wow! Okay then–`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'excited-extra',
						},
						text: 'Excellent choice!',
					},
				],
			],
			tags: ['text', 'round-01'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'sad',
						dragon: 'irritated',
					},
					text: 'Reflect on the clamor of a bad memory. What does it sound like?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'A sobbing mess',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'Fading whispers',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'Silence',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'Stifled echoes',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'Frantic chewing',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'Putrid plops',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'Abrasive rattles',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'Distant clattering',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'Disappointment',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'Mouldy crunches',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'A clash',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'Crinkling despair',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'Blistering roars',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'Fraying snags',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'Tension',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'awkward',
							dragon: 'surprised',
						},
						text: `...We'll unpack that later.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'devastated',
							dragon: 'shocked',
						},
						text: `Not my first choice, but it's your future...Anyway–`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'shocked',
							dragon: 'irritated',
						},
						text: '[shudders]',
					},
				],
			],
			tags: ['text', 'round-01'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'idle',
						dragon: 'idle',
					},
					text: 'Picture your happiest memory. What does it sound like?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'Violin',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'Flute',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'Harp',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'Cello',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'Clarinet',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'Keyboard',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'Drums',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'Piano',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'Trumpet',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'Xylophone',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'Guitar',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'Accordion',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'Saxophone',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'Bass',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'Chimes',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'sleezy',
						},
						text: `I'm partial to panpipes but that's cool too!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'excited',
							dragon: 'happy-extra',
						},
						text: `Wow! Okay then–`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'excited-extra',
						},
						text: 'Excellent choice!',
					},
				],
			],
			tags: ['text', 'round-01'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'tired',
						dragon: 'sad',
					},
					text: 'What is the sound of heartbreak?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'Exhale of solitude',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'Quietness',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'Utter silence',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'Sighs',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'A final goodbye',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'Gloom',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'Disappointment',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'Voice mail',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'Irreversable burns',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'Stifled potential',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'Loneliness',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'Crumpled hopes',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'Simmering tears',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'Crushing loss',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'Dull ache',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'sad-extra',
							dragon: 'sad-extra',
						},
						text: `...We'll unpack that later.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'awkward',
							dragon: 'shocked',
						},
						text: `Not my first choice, but it's your future...Anyway–`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'devastated',
							dragon: 'irritated',
						},
						text: '[shudders]',
					},
				],
			],
			tags: ['text', 'round-01'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'wowed',
						dragon: 'cheeky',
					},
					text: 'What is the sound of victory?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'Triumphant roar',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'Applause',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'Lots of bells',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'Ongoing cheers',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'Popping euphoria',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'Exclamations',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: '"Well done!"',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'Jubilant hums',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'An emotional toast',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'Tinkling glory',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'Cries of joy',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'An epiphany',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'Everlasting flame',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'Marching band',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'Utter satisfaction',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'idle',
							dragon: 'happy-extra',
						},
						text: `I'm partial to panpipes but that's cool too!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'excited-extra',
						},
						text: `Wow! Okay then–`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'wowed',
						},
						text: 'Excellent choice!',
					},
				],
			],
			tags: ['text', 'round-01'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'awkward',
						dragon: 'shocked',
					},
					text: `Recall an unsettling silence. What was it like?`,
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'Vacant echos',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'Fading glow',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'Suspended anticipation',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'Enclosed stillness',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'Being devoured',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'Soft decay',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'A complete void',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'Insanity',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'Charred hush',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'Eerie dampness',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'Debilitating stasis',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'A mystery',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'Hollowness',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'Crippling standstill',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'Immobility',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'tired',
							dragon: 'sad',
						},
						text: `...We'll unpack that later.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'irritated',
							dragon: 'irritated',
						},
						text: `Not my first choice, but it's your future...Anyway–`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'devastated',
							dragon: 'irritated',
						},
						text: '[shudders]',
					},
				],
			],
			tags: ['text', 'round-01'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'happy',
						dragon: 'idle',
					},
					text: 'Think about a starry night. What tunes does it play?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'Celestial vocals',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'Glimmers',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'Mystic chords',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: `Your dream song`,
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'Witchy spells',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'Soft enchantment',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: '777Hz frequency',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: '888Hz frequency',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: '999Hz frequency',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'Gentle song',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'Reverberant shimmer',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'Secrets',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'Echoes',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'Soft pitter-patter',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'Twinkles',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'cheeky',
						},
						text: `I'm partial to panpipes but that's cool too!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'excited-extra',
						},
						text: `Wow! Okay then–`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'happy',
						},
						text: 'Excellent choice!',
					},
				],
			],
			tags: ['text', 'round-01'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'tired',
						dragon: 'shocked',
					},
					text: 'What is the sound of impending doom?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'Voice of dread',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'Discordance',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'Distant thunder',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'Howls',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'Final gulp',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'Haunting wails',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'Shrieking winds',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'Grating metal',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'Ominus murmurs',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'Sirens',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'Clanking despair',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'Silence',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'Sizzling menace',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'Sinister footsteps',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'Eerie chimes',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'tired',
							dragon: 'sad',
						},
						text: `...We'll unpack that later.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'irritated',
							dragon: 'irritated',
						},
						text: `Not my first choice, but it's your future...Anyway–`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'devastated',
							dragon: 'irritated',
						},
						text: '[shudders]',
					},
				],
			],
			tags: ['text', 'round-01'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'excited',
						dragon: 'happy',
					},
					text: 'What is the sound of a perfect day?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'Birds chirping',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'Children playing',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'Song of the summer',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'Gentle breeze',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'Ice cream truck',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'An orchestra',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'Skateboard wheels',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'Pulse of the city',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'Bike bells',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'Crackling campfire',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'Street musicians',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'Cafe chatter',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'EDM',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'Meowing',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'Jazz clubs',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'cheeky',
						},
						text: `I'm partial to panpipes but that's cool too!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'excited-extra',
						},
						text: `Wow! Okay then–`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'happy',
						},
						text: 'Excellent choice!',
					},
				],
			],
			tags: ['text', 'round-01'],
			type: 'MultipleChoice',
		},

		// endregion: Round 01

		// region: Round 02 (Color)
		// ---------------------------------------------------------------------------

		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'sad-extra',
						dragon: 'sad',
					},
					text: 'Think of a bad smell. What color would you say it is?',
				},
			],
			options: {
				colors: [
					{
						categories: ['general'],
						color: '#FFD300',
						colorName: 'Mustard Yellow',
						luckyBuddies: ['trusty-rubber-duck'],
					},
					{
						categories: ['general'],
						color: '#C0FF00',
						colorName: 'Luminous Green',
						luckyBuddies: ['used-glow-stick'],
					},
					{
						categories: ['general'],
						color: '#FF4500',
						colorName: 'Intense Orange',
						luckyBuddies: ['uno-reverse-card'],
					},
					{
						categories: ['health'],
						color: '#7F6000',
						colorName: 'Dull Brown',
						luckyBuddies: ['cocoon-sleeping-bag'],
					},
					{
						categories: ['health'],
						color: '#B5651D',
						colorName: 'Caramel Brown',
						luckyBuddies: ['last-piece-of-gum'],
					},
					{
						categories: ['health'],
						color: '#E3CF57',
						colorName: 'Bruised Yellow',
						luckyBuddies: ['overripe-banana'],
					},
					{
						categories: ['wealth'],
						color: '#808080',
						colorName: 'Dusty Gray',
						luckyBuddies: ['dryer-lint'],
					},
					{
						categories: ['wealth'],
						color: '#4B0082',
						colorName: 'Deep Indigo',
						luckyBuddies: ['epic-magnet'],
					},
					{
						categories: ['wealth'],
						color: '#3D2B1F',
						colorName: 'Burnt Brown',
						luckyBuddies: ['fussy-toaster'],
					},
					{
						categories: ['career'],
						color: '#6B8E23',
						colorName: 'Moss Green',
						luckyBuddies: ['sprouting-potato'],
					},
					{
						categories: ['career'],
						color: '#696969',
						colorName: 'Dark Gray',
						luckyBuddies: ['keychain-with-too-many-keys'],
					},
					{
						categories: ['career'],
						color: '#8B4513',
						colorName: 'Saddle Brown',
						luckyBuddies: ['treasure-map'],
					},
					{
						categories: ['love'],
						color: '#B22222',
						colorName: 'Fiery Red',
						luckyBuddies: ['extra-heat-hot-sauce'],
					},
					{
						categories: ['love'],
						color: '#FF69B4',
						colorName: 'Bright Pink',
						luckyBuddies: ['fuzzy-animal-slippers'],
					},
					{
						categories: ['love'],
						color: '#FFD700',
						colorName: 'Gold',
						luckyBuddies: ['origami-star'],
					},
				],
			},
			responseType: 'color',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'tired',
							dragon: 'irritated',
						},
						text: `I feel ill just looking at it.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'shocked',
							dragon: 'irritated',
						},
						text: `The stomach turns.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'devastated',
							dragon: 'irritated',
						},
						text: `Yuck.`,
					},
				],
			],
			tags: ['color', 'round-02'],
			type: 'Color',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'excited',
						dragon: 'excited-extra',
					},
					text: 'What is the color of your favorite smell?',
				},
			],
			options: {
				colors: [
					{
						categories: ['general'],
						color: '#FDEE73',
						colorName: 'Sunny Yellow',
						luckyBuddies: ['trusty-rubber-duck'],
					},
					{
						categories: ['general'],
						color: '#8EF7B4',
						colorName: 'Neon Green',
						luckyBuddies: ['used-glow-stick'],
					},
					{
						categories: ['general'],
						color: '#4287F5',
						colorName: 'Vibrant Blue',
						luckyBuddies: ['uno-reverse-card'],
					},
					{
						categories: ['health'],
						color: '#FFB6C1',
						colorName: 'Soft Pink',
						luckyBuddies: ['cocoon-sleeping-bag'],
					},
					{
						categories: ['health'],
						color: '#C7FCEC',
						colorName: 'Mint Green',
						luckyBuddies: ['last-piece-of-gum'],
					},
					{
						categories: ['health'],
						color: '#FFF5BA',
						colorName: 'Mellow Yellow',
						luckyBuddies: ['overripe-banana'],
					},
					{
						categories: ['wealth'],
						color: '#E8E8E8',
						colorName: 'Light Grey',
						luckyBuddies: ['dryer-lint'],
					},
					{
						categories: ['wealth'],
						color: '#800080',
						colorName: 'Royal Purple',
						luckyBuddies: ['epic-magnet'],
					},
					{
						categories: ['wealth'],
						color: '#704214',
						colorName: 'Rich Mocha',
						luckyBuddies: ['fussy-toaster'],
					},
					{
						categories: ['career'],
						color: '#A2C93A',
						colorName: 'Fresh Green',
						luckyBuddies: ['sprouting-potato'],
					},
					{
						categories: ['career'],
						color: '#C0C0C0',
						colorName: 'Silver',
						luckyBuddies: ['keychain-with-too-many-keys'],
					},
					{
						categories: ['career'],
						color: '#D2B48C',
						colorName: 'Tan',
						luckyBuddies: ['treasure-map'],
					},
					{
						categories: ['love'],
						color: '#FF6347',
						colorName: 'Vibrant Red',
						luckyBuddies: ['extra-heat-hot-sauce'],
					},
					{
						categories: ['love'],
						color: '#FFD700',
						colorName: 'Gleaming Gold',
						luckyBuddies: ['fuzzy-animal-slippers'],
					},
					{
						categories: ['love'],
						color: '#FFFFE0',
						colorName: 'Light Lemon',
						luckyBuddies: ['origami-star'],
					},
				],
			},
			responseType: 'color',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'cheeky',
						},
						text: `Mmm...love it!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'cheeky',
						},
						text: `I totally relate.`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy',
							dragon: 'cheeky',
						},
						text: `Yay!`,
					},
				],
			],
			tags: ['color', 'round-02'],
			type: 'Color',
		},

		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'sad-extra',
						dragon: 'shocked',
					},
					text: 'Imagine overwhelming stress. What color does it cast?',
				},
			],
			options: {
				colors: [
					{
						categories: ['general'],
						color: '#FFD3BA',
						colorName: 'Pale Orange',
						luckyBuddies: ['trusty-rubber-duck'],
					},
					{
						categories: ['general'],
						color: '#E6E8F4',
						colorName: 'Dim Grey',
						luckyBuddies: ['used-glow-stick'],
					},
					{
						categories: ['general'],
						color: '#B9C4CA',
						colorName: 'Steel Blue',
						luckyBuddies: ['uno-reverse-card'],
					},
					{
						categories: ['health'],
						color: '#A6A9AD',
						colorName: 'Cool Grey',
						luckyBuddies: ['cocoon-sleeping-bag'],
					},
					{
						categories: ['health'],
						color: '#C7C9D3',
						colorName: 'Light Steel',
						luckyBuddies: ['last-piece-of-gum'],
					},
					{
						categories: ['health'],
						color: '#F5E5B8',
						colorName: 'Muted Yellow',
						luckyBuddies: ['overripe-banana'],
					},
					{
						categories: ['wealth'],
						color: '#D8D8D8',
						colorName: 'Light Grey',
						luckyBuddies: ['dryer-lint'],
					},
					{
						categories: ['wealth'],
						color: '#606470',
						colorName: 'Dark Slate',
						luckyBuddies: ['epic-magnet'],
					},
					{
						categories: ['wealth'],
						color: '#8A5044',
						colorName: 'Burnt Sienna',
						luckyBuddies: ['fussy-toaster'],
					},
					{
						categories: ['career'],
						color: '#A67F5B',
						colorName: 'Brown Sugar',
						luckyBuddies: ['sprouting-potato'],
					},
					{
						categories: ['career'],
						color: '#7E8F99',
						colorName: 'Shadow Blue',
						luckyBuddies: ['keychain-with-too-many-keys'],
					},
					{
						categories: ['career'],
						color: '#A2957D',
						colorName: 'Dusty Beige',
						luckyBuddies: ['treasure-map'],
					},
					{
						categories: ['love'],
						color: '#FF6347',
						colorName: 'Tomato Red',
						luckyBuddies: ['extra-heat-hot-sauce'],
					},
					{
						categories: ['love'],
						color: '#D3B8AE',
						colorName: 'Antique Pink',
						luckyBuddies: ['fuzzy-animal-slippers'],
					},
					{
						categories: ['love'],
						color: '#FFE4C4',
						colorName: 'Bisque',
						luckyBuddies: ['origami-star'],
					},
				],
			},
			responseType: 'color',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'tired',
							dragon: 'irritated',
						},
						text: `I feel ill just looking at it.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'shocked',
							dragon: 'irritated',
						},
						text: `The stomach turns.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'devastated',
							dragon: 'irritated',
						},
						text: `Yuck.`,
					},
				],
			],
			tags: ['color', 'round-02'],
			type: 'Color',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'excited',
						dragon: 'cheeky',
					},
					text: 'Visualize an exhilarating adventure. What color does it paint?',
				},
			],
			options: {
				colors: [
					{
						categories: ['general'],
						color: '#FDD835',
						colorName: 'Sunshine Yellow',
						luckyBuddies: ['trusty-rubber-duck'],
					},
					{
						categories: ['general'],
						color: '#2ECC71',
						colorName: 'Emerald Green',
						luckyBuddies: ['used-glow-stick'],
					},
					{
						categories: ['general'],
						color: '#3498DB',
						colorName: 'Vibrant Blue',
						luckyBuddies: ['uno-reverse-card'],
					},
					{
						categories: ['health'],
						color: '#9B59B6',
						colorName: 'Amethyst Purple',
						luckyBuddies: ['cocoon-sleeping-bag'],
					},
					{
						categories: ['health'],
						color: '#FF7F50',
						colorName: 'Coral',
						luckyBuddies: ['last-piece-of-gum'],
					},
					{
						categories: ['health'],
						color: '#FFEB3B',
						colorName: 'Bright Yellow',
						luckyBuddies: ['overripe-banana'],
					},
					{
						categories: ['wealth'],
						color: '#E0E0E0',
						colorName: 'Silver Cloud',
						luckyBuddies: ['dryer-lint'],
					},
					{
						categories: ['wealth'],
						color: '#E67E22',
						colorName: 'Flame Orange',
						luckyBuddies: ['epic-magnet'],
					},
					{
						categories: ['wealth'],
						color: '#6E2C00',
						colorName: 'Deep Brown',
						luckyBuddies: ['fussy-toaster'],
					},
					{
						categories: ['career'],
						color: '#7DCEA0',
						colorName: 'Sage Green',
						luckyBuddies: ['sprouting-potato'],
					},
					{
						categories: ['career'],
						color: '#5DADE2',
						colorName: 'Sky Blue',
						luckyBuddies: ['keychain-with-too-many-keys'],
					},
					{
						categories: ['career'],
						color: '#D4AC0D',
						colorName: 'Golden Sand',
						luckyBuddies: ['treasure-map'],
					},
					{
						categories: ['love'],
						color: '#C0392B',
						colorName: 'Red Pepper',
						luckyBuddies: ['extra-heat-hot-sauce'],
					},
					{
						categories: ['love'],
						color: '#FAD7A0',
						colorName: 'Peach Cream',
						luckyBuddies: ['fuzzy-animal-slippers'],
					},
					{
						categories: ['love'],
						color: '#F4D03F',
						colorName: 'Starlight Yellow',
						luckyBuddies: ['origami-star'],
					},
				],
			},
			responseType: 'color',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'happy-extra',
						},
						text: `Mmm...love it!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'idle',
						},
						text: `I totally relate.`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy',
							dragon: 'excited-extra',
						},
						text: `Yay!`,
					},
				],
			],
			tags: ['color', 'round-02'],
			type: 'Color',
		},

		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'tired',
						dragon: 'sad',
					},
					text: 'What is the color of existential dread?',
				},
			],
			options: {
				colors: [
					{
						categories: ['general'],
						color: '#B3B6B7',
						colorName: 'Dull Silver',
						luckyBuddies: ['trusty-rubber-duck'],
					},
					{
						categories: ['general'],
						color: '#798488',
						colorName: 'Dim Teal',
						luckyBuddies: ['used-glow-stick'],
					},
					{
						categories: ['general'],
						color: '#BDC3C7',
						colorName: 'Ashen Grey',
						luckyBuddies: ['uno-reverse-card'],
					},
					{
						categories: ['health'],
						color: '#95A5A6',
						colorName: 'Muted Cyan',
						luckyBuddies: ['cocoon-sleeping-bag'],
					},
					{
						categories: ['health'],
						color: '#AEB6BF',
						colorName: 'Faded Blue',
						luckyBuddies: ['last-piece-of-gum'],
					},
					{
						categories: ['health'],
						color: '#F0E68C',
						colorName: 'Khaki',
						luckyBuddies: ['overripe-banana'],
					},
					{
						categories: ['wealth'],
						color: '#D0D3D4',
						colorName: 'Pale Grey',
						luckyBuddies: ['dryer-lint'],
					},
					{
						categories: ['wealth'],
						color: '#7F8C8D',
						colorName: 'Steel Blue',
						luckyBuddies: ['epic-magnet'],
					},
					{
						categories: ['wealth'],
						color: '#5D6D7E',
						colorName: 'Stormy Sky',
						luckyBuddies: ['fussy-toaster'],
					},
					{
						categories: ['career'],
						color: '#A3A380',
						colorName: 'Dusty Olive',
						luckyBuddies: ['sprouting-potato'],
					},
					{
						categories: ['career'],
						color: '#ABB2B9',
						colorName: 'Shadow Silver',
						luckyBuddies: ['keychain-with-too-many-keys'],
					},
					{
						categories: ['career'],
						color: '#D5D8DC',
						colorName: 'Fog Grey',
						luckyBuddies: ['treasure-map'],
					},
					{
						categories: ['love'],
						color: '#CD6155',
						colorName: 'Dusty Red',
						luckyBuddies: ['extra-heat-hot-sauce'],
					},
					{
						categories: ['love'],
						color: '#D7DBDD',
						colorName: 'Light Steel',
						luckyBuddies: ['fuzzy-animal-slippers'],
					},
					{
						categories: ['love'],
						color: '#FADBD8',
						colorName: 'Pale Pink',
						luckyBuddies: ['origami-star'],
					},
				],
			},
			responseType: 'color',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'tired',
							dragon: 'irritated',
						},
						text: `I feel ill just looking at it.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'shocked',
							dragon: 'sad-extra',
						},
						text: `The stomach turns.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'devastated',
							dragon: 'shocked',
						},
						text: `Yuck.`,
					},
				],
			],
			tags: ['color', 'round-02'],
			type: 'Color',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'excited',
						dragon: 'happy',
					},
					text: 'What color does joy radiate?',
				},
			],
			options: {
				colors: [
					{
						categories: ['general'],
						color: '#FFEB3B',
						colorName: 'Vibrant Yellow',
						luckyBuddies: ['trusty-rubber-duck'],
					},
					{
						categories: ['general'],
						color: '#00FF00',
						colorName: 'Luminous Green',
						luckyBuddies: ['used-glow-stick'],
					},
					{
						categories: ['general'],
						color: '#FFC300',
						colorName: 'Sunshine Gold',
						luckyBuddies: ['uno-reverse-card'],
					},
					{
						categories: ['health'],
						color: '#9B59B6',
						colorName: 'Lavender Bliss',
						luckyBuddies: ['cocoon-sleeping-bag'],
					},
					{
						categories: ['health'],
						color: '#FF69B4',
						colorName: 'Playful Pink',
						luckyBuddies: ['last-piece-of-gum'],
					},
					{
						categories: ['health'],
						color: '#FFE135',
						colorName: 'Banana Yellow',
						luckyBuddies: ['overripe-banana'],
					},
					{
						categories: ['wealth'],
						color: '#E8E8E8',
						colorName: 'Lightest Silver',
						luckyBuddies: ['dryer-lint'],
					},
					{
						categories: ['wealth'],
						color: '#8E44AD',
						colorName: 'Purple Majesty',
						luckyBuddies: ['epic-magnet'],
					},
					{
						categories: ['wealth'],
						color: '#D35400',
						colorName: 'Warm Orange',
						luckyBuddies: ['fussy-toaster'],
					},
					{
						categories: ['career'],
						color: '#7DCEA0',
						colorName: 'Growth Green',
						luckyBuddies: ['sprouting-potato'],
					},
					{
						categories: ['career'],
						color: '#3498DB',
						colorName: 'Sky Blue',
						luckyBuddies: ['keychain-with-too-many-keys'],
					},
					{
						categories: ['career'],
						color: '#F39C12',
						colorName: 'Golden Amber',
						luckyBuddies: ['treasure-map'],
					},
					{
						categories: ['love'],
						color: '#E74C3C',
						colorName: 'Vibrant Red',
						luckyBuddies: ['extra-heat-hot-sauce'],
					},
					{
						categories: ['love'],
						color: '#F5B7B1',
						colorName: 'Soft Coral',
						luckyBuddies: ['fuzzy-animal-slippers'],
					},
					{
						categories: ['love'],
						color: '#F4D03F',
						colorName: 'Starlight Gold',
						luckyBuddies: ['origami-star'],
					},
				],
			},
			responseType: 'color',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'cheeky',
						},
						text: `Mmm...love it!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'happy-extra',
						},
						text: `I totally relate.`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'excited-extra',
						},
						text: `Yay!`,
					},
				],
			],
			tags: ['color', 'round-02'],
			type: 'Color',
		},

		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'awkward',
						dragon: 'sad',
					},
					text: 'If awkward silence were a color, what would it be?',
				},
			],
			options: {
				colors: [
					{
						categories: ['general'],
						color: '#E1F7D5',
						colorName: 'Muted Mint',
						luckyBuddies: ['trusty-rubber-duck'],
					},
					{
						categories: ['general'],
						color: '#AED6F1',
						colorName: 'Faded Sky',
						luckyBuddies: ['used-glow-stick'],
					},
					{
						categories: ['general'],
						color: '#F7DC6F',
						colorName: 'Awkward Yellow',
						luckyBuddies: ['uno-reverse-card'],
					},
					{
						categories: ['health'],
						color: '#D6DBDF',
						colorName: 'Subdued Blue',
						luckyBuddies: ['cocoon-sleeping-bag'],
					},
					{
						categories: ['health'],
						color: '#FADBD8',
						colorName: 'Pale Pink',
						luckyBuddies: ['last-piece-of-gum'],
					},
					{
						categories: ['health'],
						color: '#F9E79F',
						colorName: 'Bland Banana',
						luckyBuddies: ['overripe-banana'],
					},
					{
						categories: ['wealth'],
						color: '#D5DBDB',
						colorName: 'Silent Gray',
						luckyBuddies: ['dryer-lint'],
					},
					{
						categories: ['wealth'],
						color: '#ABB2B9',
						colorName: 'Steel Quiet',
						luckyBuddies: ['epic-magnet'],
					},
					{
						categories: ['wealth'],
						color: '#A04000',
						colorName: 'Smoky Brown',
						luckyBuddies: ['fussy-toaster'],
					},
					{
						categories: ['career'],
						color: '#A9CCE3',
						colorName: 'Soft Blue',
						luckyBuddies: ['sprouting-potato'],
					},
					{
						categories: ['career'],
						color: '#85C1E9',
						colorName: 'Muffled Cerulean',
						luckyBuddies: ['keychain-with-too-many-keys'],
					},
					{
						categories: ['career'],
						color: '#D4EFDF',
						colorName: 'Faint Green',
						luckyBuddies: ['treasure-map'],
					},
					{
						categories: ['love'],
						color: '#E59866',
						colorName: 'Muted Orange',
						luckyBuddies: ['extra-heat-hot-sauce'],
					},
					{
						categories: ['love'],
						color: '#F2D7D5',
						colorName: 'Whisper Pink',
						luckyBuddies: ['fuzzy-animal-slippers'],
					},
					{
						categories: ['love'],
						color: '#FDEBD0',
						colorName: 'Soft Cream',
						luckyBuddies: ['origami-star'],
					},
				],
			},
			responseType: 'color',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'tired',
							dragon: 'irritated',
						},
						text: `I feel ill just looking at it.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'shocked',
							dragon: 'sad-extra',
						},
						text: `The stomach turns.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'devastated',
							dragon: 'shocked',
						},
						text: `Yuck.`,
					},
				],
			],
			tags: ['color', 'round-02'],
			type: 'Color',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'happy-extra',
						dragon: 'happy',
					},
					text: 'What is the color of your comfort food?',
				},
			],
			options: {
				colors: [
					{
						categories: ['general'],
						color: '#FFD54F',
						colorName: 'Warm Honey',
						luckyBuddies: ['trusty-rubber-duck'],
					},
					{
						categories: ['general'],
						color: '#FFAB91',
						colorName: 'Sweet Salmon',
						luckyBuddies: ['used-glow-stick'],
					},
					{
						categories: ['general'],
						color: '#D7CCC8',
						colorName: 'Toasted Almond',
						luckyBuddies: ['uno-reverse-card'],
					},
					{
						categories: ['health'],
						color: '#BCAAA4',
						colorName: 'Cozy Cocoa',
						luckyBuddies: ['cocoon-sleeping-bag'],
					},
					{
						categories: ['health'],
						color: '#F48FB1',
						colorName: 'Bubblegum Pink',
						luckyBuddies: ['last-piece-of-gum'],
					},
					{
						categories: ['health'],
						color: '#FFE082',
						colorName: 'Banana Pudding',
						luckyBuddies: ['overripe-banana'],
					},
					{
						categories: ['wealth'],
						color: '#ECEFF1',
						colorName: 'Soothing Vanilla',
						luckyBuddies: ['dryer-lint'],
					},
					{
						categories: ['wealth'],
						color: '#90A4AE',
						colorName: 'Comforting Steel',
						luckyBuddies: ['epic-magnet'],
					},
					{
						categories: ['wealth'],
						color: '#8D6E63',
						colorName: 'Caramelized Brown',
						luckyBuddies: ['fussy-toaster'],
					},
					{
						categories: ['career'],
						color: '#A5D6A7',
						colorName: 'Herbal Green',
						luckyBuddies: ['sprouting-potato'],
					},
					{
						categories: ['career'],
						color: '#B0BEC5',
						colorName: 'Blueberry Muffin',
						luckyBuddies: ['keychain-with-too-many-keys'],
					},
					{
						categories: ['career'],
						color: '#D7B8AB',
						colorName: 'Butterscotch Bliss',
						luckyBuddies: ['treasure-map'],
					},
					{
						categories: ['love'],
						color: '#EF5350',
						colorName: 'Spicy Tomato',
						luckyBuddies: ['extra-heat-hot-sauce'],
					},
					{
						categories: ['love'],
						color: '#FFCDD2',
						colorName: 'Strawberry Milk',
						luckyBuddies: ['fuzzy-animal-slippers'],
					},
					{
						categories: ['love'],
						color: '#FFF59D',
						colorName: 'Lemon Custard',
						luckyBuddies: ['origami-star'],
					},
				],
			},
			responseType: 'color',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy',
							dragon: 'cheeky',
						},
						text: `Mmm...love it!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'happy-extra',
						},
						text: `I totally relate.`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'excited-extra',
						},
						text: `Yay!`,
					},
				],
			],
			tags: ['color', 'round-02'],
			type: 'Color',
		},

		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'sad-extra',
						dragon: 'sad',
					},
					text: 'What color would you assign to the feeling of a burnout?',
				},
			],
			options: {
				colors: [
					{
						categories: ['general'],
						color: '#EADAA2',
						colorName: 'Muted Yellow Comfort',
						luckyBuddies: ['trusty-rubber-duck'],
					},
					{
						categories: ['general'],
						color: '#78A0A8',
						colorName: 'Dimmed Neon Glow',
						luckyBuddies: ['used-glow-stick'],
					},
					{
						categories: ['general'],
						color: '#3A587E',
						colorName: 'Resilient Blue Flip',
						luckyBuddies: ['uno-reverse-card'],
					},
					{
						categories: ['health'],
						color: '#9DA3A6',
						colorName: 'Restful Grey Sanctuary',
						luckyBuddies: ['cocoon-sleeping-bag'],
					},
					{
						categories: ['health'],
						color: '#F2C6DE',
						colorName: 'Subtle Sweetness',
						luckyBuddies: ['last-piece-of-gum'],
					},
					{
						categories: ['health'],
						color: '#F2C057',
						colorName: 'Ripened Resolve',
						luckyBuddies: ['overripe-banana'],
					},
					{
						categories: ['wealth'],
						color: '#E8E8E8',
						colorName: 'Faded Accumulation',
						luckyBuddies: ['dryer-lint'],
					},
					{
						categories: ['wealth'],
						color: '#E84855',
						colorName: 'Magnetic Attraction',
						luckyBuddies: ['epic-magnet'],
					},
					{
						categories: ['wealth'],
						color: '#D98256',
						colorName: 'Charred Persistence',
						luckyBuddies: ['fussy-toaster'],
					},
					{
						categories: ['career'],
						color: '#A3CFA8',
						colorName: 'Emerging Growth',
						luckyBuddies: ['sprouting-potato'],
					},
					{
						categories: ['career'],
						color: '#F2B705',
						colorName: 'Diverse Opportunities',
						luckyBuddies: ['keychain-with-too-many-keys'],
					},
					{
						categories: ['career'],
						color: '#D2B48C',
						colorName: 'Adventurous Beige',
						luckyBuddies: ['treasure-map'],
					},
					{
						categories: ['love'],
						color: '#C94034',
						colorName: 'Spicy Vigor',
						luckyBuddies: ['extra-heat-hot-sauce'],
					},
					{
						categories: ['love'],
						color: '#9867C5',
						colorName: 'Cozy Warmth',
						luckyBuddies: ['fuzzy-animal-slippers'],
					},
					{
						categories: ['love'],
						color: '#FCD667',
						colorName: 'Wishful Illumination',
						luckyBuddies: ['origami-star'],
					},
				],
			},
			responseType: 'color',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'sad',
							dragon: 'sad-extra',
						},
						text: `I feel ill just looking at it.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'shocked',
							dragon: 'shocked',
						},
						text: `The stomach turns.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'devastated',
							dragon: 'irritated',
						},
						text: `Yuck.`,
					},
				],
			],
			tags: ['color', 'round-02'],
			type: 'Color',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'surprised',
						dragon: 'excited',
					},
					text: 'In what triumphant color does success bask?',
				},
			],
			options: {
				colors: [
					{
						categories: ['general'],
						color: '#FFD700',
						colorName: 'Gleaming Gold',
						luckyBuddies: ['trusty-rubber-duck'],
					},
					{
						categories: ['general'],
						color: '#C0C0C0',
						colorName: 'Victorious Silver',
						luckyBuddies: ['used-glow-stick'],
					},
					{
						categories: ['general'],
						color: '#00FF00',
						colorName: 'Winning Green',
						luckyBuddies: ['uno-reverse-card'],
					},
					{
						categories: ['health'],
						color: '#7FFFD4',
						colorName: 'Restful Aquamarine',
						luckyBuddies: ['cocoon-sleeping-bag'],
					},
					{
						categories: ['health'],
						color: '#FF69B4',
						colorName: 'Hopeful Pink',
						luckyBuddies: ['last-piece-of-gum'],
					},
					{
						categories: ['health'],
						color: '#FFEB3B',
						colorName: 'Golden Yellow',
						luckyBuddies: ['overripe-banana'],
					},
					{
						categories: ['wealth'],
						color: '#B0C4DE',
						colorName: 'Light Steel Blue',
						luckyBuddies: ['dryer-lint'],
					},
					{
						categories: ['wealth'],
						color: '#8A2BE2',
						colorName: 'Royal Blue',
						luckyBuddies: ['epic-magnet'],
					},
					{
						categories: ['wealth'],
						color: '#B22222',
						colorName: 'Firebrick',
						luckyBuddies: ['fussy-toaster'],
					},
					{
						categories: ['career'],
						color: '#32CD32',
						colorName: 'Lime Green',
						luckyBuddies: ['sprouting-potato'],
					},
					{
						categories: ['career'],
						color: '#FF4500',
						colorName: 'Orange Red',
						luckyBuddies: ['keychain-with-too-many-keys'],
					},
					{
						categories: ['career'],
						color: '#DAA520',
						colorName: 'Goldenrod',
						luckyBuddies: ['treasure-map'],
					},
					{
						categories: ['love'],
						color: '#CD5C5C',
						colorName: 'Indian Red',
						luckyBuddies: ['extra-heat-hot-sauce'],
					},
					{
						categories: ['love'],
						color: '#DEB887',
						colorName: 'Burly Wood',
						luckyBuddies: ['fuzzy-animal-slippers'],
					},
					{
						categories: ['love'],
						color: '#FFFF00',
						colorName: 'Star Yellow',
						luckyBuddies: ['origami-star'],
					},
				],
			},
			responseType: 'color',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'happy',
						},
						text: `Mmm...love it!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'wowed',
						},
						text: `I totally relate.`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'excited-extra',
						},
						text: `Yay!`,
					},
				],
			],
			tags: ['color', 'round-02'],
			type: 'Color',
		},

		// endregion: Round 02

		// region: Round 03 (Text)
		// ---------------------------------------------------------------------------

		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'awkward',
						dragon: 'shocked',
					},
					text: 'What time are you most afraid of?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '(When baths often start)',
						luckyBuddies: ['general-00'],
						shortText: 'All times',
					},
					{
						categories: ['general'],
						extendedText: '(Deep, dark night)',
						luckyBuddies: ['general-01'],
						shortText: 'The witching hour',
					},
					{
						categories: ['general'],
						extendedText: '(The sudden switch to afternoon)',
						luckyBuddies: ['general-02'],
						shortText: 'Sudden switch to afternoon',
					},
					{
						categories: ['health'],
						extendedText: '(early morning eeriness)',
						luckyBuddies: ['health-00'],
						shortText: 'Pre-coffee',
					},
					{
						categories: ['health'],
						extendedText: '(post-lunch slump)',
						luckyBuddies: ['health-01'],
						shortText: 'Post-lunch',
					},
					{
						categories: ['health'],
						extendedText: '(late afternoon decay)',
						luckyBuddies: ['health-02'],
						shortText: 'Late afternoon decay',
					},
					{
						categories: ['wealth'],
						extendedText: '(evening shadows)',
						luckyBuddies: ['wealth-00'],
						shortText: 'Time to hit the hay',
					},
					{
						categories: ['wealth'],
						extendedText: `(night's magnetic pull)`,
						luckyBuddies: ['wealth-01'],
						shortText: 'Tax day',
					},
					{
						categories: ['wealth'],
						extendedText: '(rushed, chaotic morning)',
						luckyBuddies: ['wealth-02'],
						shortText: 'Daily commute',
					},
					{
						categories: ['career'],
						extendedText: '(when the night grows deeper)',
						luckyBuddies: ['career-00'],
						shortText: 'Happy hour',
					},
					{
						categories: ['career'],
						extendedText: '(wishful hours)',
						luckyBuddies: ['career-01'],
						shortText: 'Night before launch',
					},
					{
						categories: ['career'],
						extendedText: '(mightnight, when secrets emerge)',
						luckyBuddies: ['career-02'],
						shortText: 'Annual performance review',
					},
					{
						categories: ['love'],
						extendedText: '(intense, haunting hour) ',
						luckyBuddies: ['love-00'],
						shortText: 'Midnight',
					},
					{
						categories: ['love'],
						extendedText: `(late evening's uncertainty)`,
						luckyBuddies: ['love-01'],
						shortText: '10:00PM',
					},
					{
						categories: ['love'],
						extendedText: '(quiet, still nigth hour)',
						luckyBuddies: ['love-02'],
						shortText: '7:00AM',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'shocked',
							dragon: 'irritated',
						},
						text: `You've just reminded me...I'm late for an appointment.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'sleezy',
						},
						text: `Odd, but consistent. Last question...`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'excited',
							dragon: 'idle',
						},
						text: `Only one question left...`,
					},
				],
			],
			tags: ['text', 'round-03'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'excited',
						dragon: 'happy',
					},
					text: 'What time represents happiness for you?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: 'joyful morning bath time',
						luckyBuddies: ['general-00'],
						shortText: 'Golden hour',
					},
					{
						categories: ['general'],
						extendedText: 'evening fun begins',
						luckyBuddies: ['general-01'],
						shortText: 'Karaoke night',
					},
					{
						categories: ['general'],
						extendedText: 'work ends, leisure begins',
						luckyBuddies: ['general-02'],
						shortText: 'Game night',
					},
					{
						categories: ['health'],
						extendedText: 'snug bedtime comfort',
						luckyBuddies: ['health-00'],
						shortText: '7AM coffee',
					},
					{
						categories: ['health'],
						extendedText: 'afternoon pick-me up',
						luckyBuddies: ['health-01'],
						shortText: '3PM snack',
					},
					{
						categories: ['health'],
						extendedText: 'early peaceful breakfast',
						luckyBuddies: ['health-02'],
						shortText: '6AM workout',
					},
					{
						categories: ['wealth'],
						extendedText: 'satisfaction of completed chores',
						luckyBuddies: ['wealth-00'],
						shortText: 'New episode release',
					},
					{
						categories: ['wealth'],
						extendedText: 'homely time',
						luckyBuddies: ['wealth-01'],
						shortText: 'Home time',
					},
					{
						categories: ['wealth'],
						extendedText: 'rush of a new day',
						luckyBuddies: ['wealth-02'],
						shortText: 'Dinner time',
					},
					{
						categories: ['career'],
						extendedText: 'late afternoon relaxation',
						luckyBuddies: ['career-00'],
						shortText: '9AM Monday',
					},
					{
						categories: ['career'],
						extendedText: 'lunch break delight',
						luckyBuddies: ['career-01'],
						shortText: '4PM Friday',
					},
					{
						categories: ['career'],
						extendedText: 'mid-morning adventure',
						luckyBuddies: ['career-02'],
						shortText: 'First day of vacation',
					},
					{
						categories: ['love'],
						extendedText: 'dinnertime excitement',
						luckyBuddies: ['love-00'],
						shortText: '12:12',
					},
					{
						categories: ['love'],
						extendedText: 'cozy evening unwind',
						luckyBuddies: ['love-01'],
						shortText: '3:33',
					},
					{
						categories: ['love'],
						extendedText: 'late-night creative inspiration',
						luckyBuddies: ['love-02'],
						shortText: '11:11',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'happy-extra',
						},
						text: `What even *is* time, am I right?`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'surprised',
						},
						text: `I've never actually used a clock before!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'wowed',
						},
						text: `Ah, time – the donut of the heart. Okay last up–`,
					},
				],
			],
			tags: ['text', 'round-03'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'happy',
						dragon: 'excited-extra',
					},
					text: `What's your ideal time for sunset?`,
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: 'when bathtime mingles with twilight',
						luckyBuddies: ['general-00'],
						shortText: 'All the time?',
					},
					{
						categories: ['general'],
						extendedText: `as darkness embraces the night's magic`,
						luckyBuddies: ['general-01'],
						shortText: 'NEVER',
					},
					{
						categories: ['general'],
						extendedText: 'when day flips to a starry night',
						luckyBuddies: ['general-02'],
						shortText: 'IDK',
					},
					{
						categories: ['health'],
						extendedText: 'as the world prepares for nighttime slumber',
						luckyBuddies: ['health-00'],
						shortText: '2:00PM',
					},
					{
						categories: ['health'],
						extendedText: 'the final burst of daylight sweetness',
						luckyBuddies: ['health-01'],
						shortText: '6:30PM',
					},
					{
						categories: ['health'],
						extendedText: 'when the day softens into evening',
						luckyBuddies: ['health-02'],
						shortText: '7:30PM',
					},
					{
						categories: ['wealth'],
						extendedText: 'as the day fades into cozy night',
						luckyBuddies: ['wealth-00'],
						shortText: '8:00PM',
					},
					{
						categories: ['wealth'],
						extendedText: 'drawing in the stars and moonlight',
						luckyBuddies: ['wealth-01'],
						shortText: '9:30PM',
					},
					{
						categories: ['wealth'],
						extendedText: `as the day's hustle simmers down`,
						luckyBuddies: ['wealth-02'],
						shortText: '6:00PM',
					},
					{
						categories: ['career'],
						extendedText: 'growth under the veil of dusk',
						luckyBuddies: ['career-00'],
						shortText: '4.59PM',
					},
					{
						categories: ['career'],
						extendedText: 'locking the day as evening unfolds',
						luckyBuddies: ['career-01'],
						shortText: '7:23PM',
					},
					{
						categories: ['career'],
						extendedText: `unveiling night's mysteries under a dim sky`,
						luckyBuddies: ['career-02'],
						shortText: '5:48PM',
					},
					{
						categories: ['love'],
						extendedText: `the night's fiery embrace begins`,
						luckyBuddies: ['love-00'],
						shortText: '9:09PM',
					},
					{
						categories: ['love'],
						extendedText: `settling into the evening's comfort`,
						luckyBuddies: ['love-01'],
						shortText: '7:07PM',
					},
					{
						categories: ['love'],
						extendedText: 'folding night around sparkling stars',
						luckyBuddies: ['love-02'],
						shortText: '10:10PM',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'shocked',
							dragon: 'irritated',
						},
						text: `You've just reminded me...I'm late for an appointment.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'sleezy',
						},
						text: `Odd, but consistent. Last question...`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'excited',
							dragon: 'idle',
						},
						text: `Only one question left...`,
					},
				],
			],
			tags: ['text', 'round-03'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'happy-extra',
						dragon: 'idle',
					},
					text: `What's your ideal time for the sun to rise?`,
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: `as the day's first giggles ripple`,
						luckyBuddies: ['general-00'],
						shortText: '6:30AM',
					},
					{
						categories: ['general'],
						extendedText: `faint light yielding to dawn's embrace`,
						luckyBuddies: ['general-01'],
						shortText: '3:00AM',
					},
					{
						categories: ['general'],
						extendedText: 'flipping night into a new beginning',
						luckyBuddies: ['general-02'],
						shortText: '5:00AM',
					},
					{
						categories: ['health'],
						extendedText: `awakening from dreams to daylight's warmth`,
						luckyBuddies: ['health-00'],
						shortText: 'No.',
					},
					{
						categories: ['health'],
						extendedText: 'fresh brusts of early morning flavor',
						luckyBuddies: ['health-01'],
						shortText: 'Before I wake up',
					},
					{
						categories: ['health'],
						extendedText: 'gentle light ripening the day',
						luckyBuddies: ['health-02'],
						shortText: 'As early as possible',
					},
					{
						categories: ['wealth'],
						extendedText: 'a soft, hazy start to the day',
						luckyBuddies: ['wealth-00'],
						shortText: '7:00AM',
					},
					{
						categories: ['wealth'],
						extendedText: `dawn's allure pulling in the new day`,
						luckyBuddies: ['wealth-01'],
						shortText: '6:55AM',
					},
					{
						categories: ['wealth'],
						extendedText: 'crisp start to a bustling day',
						luckyBuddies: ['wealth-02'],
						shortText: '12:00PM',
					},
					{
						categories: ['career'],
						extendedText: 'early growth under the first sunbeams',
						luckyBuddies: ['career-00'],
						shortText: '4:30AM',
					},
					{
						categories: ['career'],
						extendedText: 'unlocking the day with possibilities',
						luckyBuddies: ['career-01'],
						shortText: '6:00AM',
					},
					{
						categories: ['career'],
						extendedText: `revealing the day's hidden adventures`,
						luckyBuddies: ['career-02'],
						shortText: '9.00AM',
					},
					{
						categories: ['love'],
						extendedText: 'spicy sunrise igniting the horizon',
						luckyBuddies: ['love-00'],
						shortText: '6:06AM',
					},
					{
						categories: ['love'],
						extendedText: `cozy beginnings in morning's light`,
						luckyBuddies: ['love-01'],
						shortText: '5:55AM',
					},
					{
						categories: ['love'],
						extendedText: `folding night into the day's first light`,
						luckyBuddies: ['love-02'],
						shortText: '7:07AM',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'happy-extra',
						},
						text: `What even *is* time, am I right?`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'surprised',
						},
						text: `I've never actually used a clock before!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'wowed',
						},
						text: `Ah, time – the donut of the heart. Okay last up–`,
					},
				],
			],
			tags: ['text', 'round-03'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'tired',
						dragon: 'shocked',
					},
					text: 'Pick a specific time you dread.',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'Bath time',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'End of a party',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'Waiting in line',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'Still awake',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'After-lunch lull',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'Being too late',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'Bed time',
					},
					{
						categories: ['wealth'],
						extendedText: ``,
						luckyBuddies: ['wealth-01'],
						shortText: 'Tax time',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'Bus time',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: `Cheers o'clock`,
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'Office hours',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'HR meetings',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'The stroke of twelve',
					},
					{
						categories: ['love'],
						extendedText: ``,
						luckyBuddies: ['love-01'],
						shortText: 'Deep night',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'Early morning',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'shocked',
							dragon: 'irritated',
						},
						text: `You've just reminded me...I'm late for an appointment.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'sleezy',
						},
						text: `Odd, but consistent. Last question...`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'excited',
							dragon: 'idle',
						},
						text: `Only one question left...`,
					},
				],
			],
			tags: ['text', 'round-03'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'idle',
						dragon: 'idle',
					},
					text: 'What time do you wish it was?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'Sunset',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'Start of a party',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'D&D time',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: '11AM matcha',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: '4PM donut',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: '8PM pilates',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'Sanctuary hour',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'FaceTime',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'Time to retire',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'Me time',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'Time to go home',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'Time away',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: '8:08',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: '2:22',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: '12:34',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'happy-extra',
						},
						text: `What even *is* time, am I right?`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'surprised',
						},
						text: `I've never actually used a clock before!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'wowed',
						},
						text: `Ah, time – the donut of the heart. Okay last up–`,
					},
				],
			],
			tags: ['text', 'round-03'],
			type: 'MultipleChoice',
		},

		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'tired',
						dragon: 'surprised',
					},
					text: 'If you had to set an alarm, what time would you set it for?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'When my delivery arrives',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'When September ends',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'When my Doordash comes',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'What is...alarm?',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'Mid-afternoon break',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'Morning buffet time',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'When the tea spills',
					},
					{
						categories: ['wealth'],
						extendedText: ``,
						luckyBuddies: ['wealth-01'],
						shortText: '1hr before call time',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: `My cat's dinner time`,
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'When plant needs watering',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'Time for my dream job',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'Air time',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'Midnight',
					},
					{
						categories: ['love'],
						extendedText: ``,
						luckyBuddies: ['love-01'],
						shortText: 'Morning of vacation',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'My birthday',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'shocked',
							dragon: 'irritated',
						},
						text: `You've just reminded me...I'm late for an appointment.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'sleezy',
						},
						text: `Odd, but consistent. Last question...`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'excited',
							dragon: 'idle',
						},
						text: `Only one question left...`,
					},
				],
			],
			tags: ['text', 'round-03'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'happy-extra',
						dragon: 'happy',
					},
					text: 'What is your ideal bedtime?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: '10:00 PM',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'Midnight',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: '12:01',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'After skin scare',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'After doomscrolling',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'After kitchen raid',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: '9:00 PM',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: '11:00 PM',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: '1:00 AM',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'When others wake',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'When the inbox is cleared',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'When the phone hits 1%',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'Following the final swipe',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'After saying "goodnight"',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'When the stars come out',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy',
							dragon: 'happy-extra',
						},
						text: `What even *is* time, am I right?`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'surprised',
						},
						text: `I've never actually used a clock before!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'wowed',
						},
						text: `Ah, time – the donut of the heart. Okay last up–`,
					},
				],
			],
			tags: ['text', 'round-03'],
			type: 'MultipleChoice',
		},

		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'awkward',
						dragon: 'irritated',
					},
					text: 'What time marks the beginning of your most restless thoughts?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: '2:00 AM',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: '1:00 AM',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'Midnight',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: 'Starting a playlist',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: 'TikTok timeout',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: 'Youtube loop',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'Ending with a cliffhanger',
					},
					{
						categories: ['wealth'],
						extendedText: ``,
						luckyBuddies: ['wealth-01'],
						shortText: 'Lottery announcement',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'The chat is ablaze',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'Morning alarm',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: '100+ unread emails',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'Low battery',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'A new notification',
					},
					{
						categories: ['love'],
						extendedText: ``,
						luckyBuddies: ['love-01'],
						shortText: '"Read"',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'Forgetting to wash the rice',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'shocked',
							dragon: 'sad',
						},
						text: `You've just reminded me...I'm late for an appointment.`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'sleezy',
						},
						text: `Odd, but consistent. Last question...`,
					},
				],
				[
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'excited',
							dragon: 'idle',
						},
						text: `Only one question left...`,
					},
				],
			],
			tags: ['text', 'round-03'],
			type: 'MultipleChoice',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'excited',
						dragon: 'excited',
					},
					text: 'During what part of the day does your spirit soar brighest?',
				},
			],
			options: {
				choices: [
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-00'],
						shortText: 'In the morning',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-01'],
						shortText: 'At night',
					},
					{
						categories: ['general'],
						extendedText: '',
						luckyBuddies: ['general-02'],
						shortText: 'Maybe afternoon',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-00'],
						shortText: '8AM coffee',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-01'],
						shortText: '10PM binge',
					},
					{
						categories: ['health'],
						extendedText: '',
						luckyBuddies: ['health-02'],
						shortText: '6PM dinner',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-00'],
						shortText: 'Pre-alarm',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-01'],
						shortText: 'Bath time',
					},
					{
						categories: ['wealth'],
						extendedText: '',
						luckyBuddies: ['wealth-02'],
						shortText: 'Short morning commute',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-00'],
						shortText: 'Start of day',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-01'],
						shortText: 'Last email sent',
					},
					{
						categories: ['career'],
						extendedText: '',
						luckyBuddies: ['career-02'],
						shortText: 'End of day',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-00'],
						shortText: 'Late night likes',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-01'],
						shortText: 'When they text back',
					},
					{
						categories: ['love'],
						extendedText: '',
						luckyBuddies: ['love-02'],
						shortText: 'When feeling loved',
					},
				],
			},
			responseType: 'text',
			responses: [
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy-extra',
							dragon: 'happy-extra',
						},
						text: `What even *is* time, am I right?`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'surprised',
							dragon: 'surprised',
						},
						text: `I've never actually used a clock before!`,
					},
				],
				[
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'wowed',
						},
						text: `Ah, time – the donut of the heart. Okay last up–`,
					},
				],
			],
			tags: ['text', 'round-03'],
			type: 'MultipleChoice',
		},

		// endregion: Round 03

		// region: Round 04 (Shape)
		// ---------------------------------------------------------------------------
		// HERE!!
		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'excited',
						dragon: 'excited-extra',
					},
					text: 'Show us the size of your ambitions',
				},
			],
			options: {
				shape: {
					endType: 'star00',
					startType: 'triangle00',
				},
			},
			responseType: 'number',
			responses: [[]],
			tags: ['shape', 'round-04'],
			type: 'Grower',
		},
		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'happy',
						dragon: 'happy',
					},
					text: 'How big should this shape be to feel like enough?',
				},
			],
			options: {
				shape: {
					endType: 'heart00',
					startType: 'star01',
				},
			},
			responseType: 'number',
			responses: [[]],
			tags: ['shape', 'round-04'],
			type: 'Grower',
		},
		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'awkward',
						dragon: 'irritated',
					},
					text: 'How big is your general anxiety?',
				},
			],
			options: {
				shape: {
					endType: 'dispersed00',
					startType: 'cluster00',
				},
			},
			responseType: 'number',
			responses: [[]],
			tags: ['shape', 'round-04'],
			type: 'Grower',
		},
		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'irritated',
						dragon: 'irritated',
					},
					text: 'You are fated to carry the burden of a friend. How much do you take?',
				},
			],
			options: {
				shape: {
					endType: 'dispersed00',
					startType: 'cluster00',
				},
			},
			responseType: 'number',
			responses: [[]],
			tags: ['shape', 'round-04'],
			type: 'Grower',
		},
		{
			dialogue: [
				{
					character: 'bunny',
					characterPoses: {
						bunny: 'surprised',
						dragon: 'happy-extra',
					},
					text: 'Imagine a dragon stands before you (not that one over there). How big is it?',
				},
			],
			options: {
				shape: {
					endType: 'dragon01',
					startType: 'dragon00',
				},
			},
			responseType: 'number',
			responses: [[]],
			tags: ['shape', 'round-04'],
			type: 'Grower',
		},

		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'wowed',
						dragon: 'excited',
					},
					text: 'How big are your dreams?',
				},
			],
			options: {
				shape: {
					endType: 'star00',
					startType: 'triangle00',
				},
			},
			responseType: 'number',
			responses: [[]],
			tags: ['shape', 'round-04'],
			type: 'Grower',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'happy-extra',
						dragon: 'happy',
					},
					text: 'How big should this shape be to make you happy?',
				},
			],
			options: {
				shape: {
					endType: 'heart00',
					startType: 'star01',
				},
			},
			responseType: 'number',
			responses: [[]],
			tags: ['shape', 'round-04'],
			type: 'Grower',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'awkward',
						dragon: 'surprised',
					},
					text: 'How big are your feelings right now?',
				},
			],
			options: {
				shape: {
					endType: 'dispersed00',
					startType: 'cluster00',
				},
			},
			responseType: 'number',
			responses: [[]],
			tags: ['shape', 'round-04'],
			type: 'Grower',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'idle',
						dragon: 'wowed',
					},
					text: 'How expansive is your sense of wonder and curiosity about the world?',
				},
			],
			options: {
				shape: {
					endType: 'dispersed00',
					startType: 'cluster00',
				},
			},
			responseType: 'number',
			responses: [[]],
			tags: ['shape', 'round-04'],
			type: 'Grower',
		},
		{
			dialogue: [
				{
					character: 'dragon',
					characterPoses: {
						bunny: 'happy-extra',
						dragon: 'cheeky',
					},
					text: 'Imagine a bunny spirit guide. How big is it?',
				},
			],
			options: {
				shape: {
					endType: 'bunny01',
					startType: 'bunny00',
				},
			},
			responseType: 'number',
			responses: [[]],
			tags: ['shape', 'round-04'],
			type: 'Grower',
		},

		// endregion: Round 04
	],
};
