
	import * as Config from '@/Config';
	import * as dat from 'dat.gui';
	import ViewBase from '@/View/Base.vue';
	import { Component } from 'vue-property-decorator';
	import { Event } from 'buck-ts';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Modal
	 * @project BunnyDragon
	 */
	@Component
	export default class ViewDebug extends ViewBase {
		/**
		 * @type dat.GUI
		 */
		private gui!: dat.GUI;

		/**
		 * @return void
		 */
		@mounted
		protected setup(): void {
			this.gui = new dat.GUI({
				autoPlace: false,
				width: 300,
			});

			this.gui.add(this.$store.state, 'starfieldScrollRatio', 0, 1).listen();
			this.gui.add(this.$store.state, 'deviceOrientation').listen();
			this.gui.add(this.$store.state, 'entrypointLuckyBuddy').listen();
			this.gui.add(this.$store.state, 'entrypointZodiac').listen();
			this.gui.add(this.$store.state, 'languageCode').listen();
			this.gui.add(this.$store.state, 'timesCompleted').listen();
			this.gui.add(this.$store.state, 'timesPlayedPerVisit').listen();
			this.gui.add(this.$store.state, 'timesVisited').listen();
			this.gui.add(this.$store.state, 'userZodiac').listen();
			this.gui.add(this.$store.state, 'userZodiacElement').listen();
			this.gui.add(this.$store.state, 'userZodiacSign').listen();
			this.gui.add(this.$store.state, 'winningCategory').listen();
			this.gui.add(this.$store.state, 'winningLuckyBuddy').listen();
			this.gui.add(this.$store.state, 'answerModifier').listen();

			this.$el.appendChild(this.gui.domElement);

			// Start closed
			this.gui.close();
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		protected destroy(): void {
			if (this.gui) {
				this.gui.destroy();
			}
		}
	}
