/* eslint-disable */

// French translation
export default {
	copy_birthdate_confirmday: 'Confirmer le jour',
	copy_birthdate_confirmmonth: 'Confirmer le mois',
	copy_birthdate_confirmyear: "Confirmer l'année",
	copy_birthdate_dragtoselect: 'Faites glisser pour sélectionner la date',
	copy_confirmation_birthdate_header: 'Avec une date de naissance en',
	copy_confirmation_youarea: 'Vous êtes un',
	copy_general_day: 'Jour',
	copy_general_month: 'Mois',
	copy_general_year: 'Année',
	copy_landscape_tiltphone: 'Veuillez incliner votre téléphone.',
	copy_welcome_enterbirthday: 'Date de naissance',
	copy_welcome_touchtobegin: `Pour commencer, <strong>appuyez</strong> sur le cosmos`,

	month_january: 'Janvier',
	month_february: 'Février',
	month_march: 'Mars',
	month_april: 'Avril',
	month_may: 'Mai',
	month_june: 'Juin',
	month_july: 'Juillet',
	month_august: 'Août',
	month_september: 'Septembre',
	month_october: 'Octobre',
	month_november: 'Novembre',
	month_december: 'Décembre',

	zodiac_element_wood: 'Bois',
	zodiac_element_fire: 'Feu',
	zodiac_element_earth: 'Terre',
	zodiac_element_metal: 'Métal',
	zodiac_element_water: 'Eau',

	zodiac_sign_rat: 'Rat',
	zodiac_sign_ox: 'Bœuf',
	zodiac_sign_tiger: 'Tigre',
	zodiac_sign_rabbit: 'Lapin',
	zodiac_sign_dragon: 'Dragon',
	zodiac_sign_snake: 'Serpent',
	zodiac_sign_horse: 'Cheval',
	zodiac_sign_goat: 'Chèvre',
	zodiac_sign_monkey: 'Singe',
	zodiac_sign_rooster: 'Coq',
	zodiac_sign_dog: 'Chien',
	zodiac_sign_pig: 'Cochon',

	ordinal_first: 'er',
	ordinal_second: 'nd',
	ordinal_third: 'ème',
	ordinal_suffix: 'ème',
};
