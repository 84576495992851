import * as Core from '@/Core';
import * as Enum from '@/Enum';
import * as Model from '@/Model';

/**
 * @class Dialogue
 * @package Collection
 * @project BunnyDragon
 */
export class Dialogue extends Core.Collection<Model.Dialogue> {
	/**
	 * @type Model.Dialogue
	 */
	public model: Model.Dialogue = new Model.Dialogue();

	/**
	 * @return Model.Dialogue[]
	 */
	public getByBunny(): Model.Dialogue[] {
		return this.filter((dialogueModel: Model.Dialogue) => {
			return dialogueModel.character === Enum.Character.BUNNY;
		});
	}

	/**
	 * @return Model.Dialogue[]
	 */
	public getByDragon(): Model.Dialogue[] {
		return this.filter((dialogueModel: Model.Dialogue) => {
			return dialogueModel.character === Enum.Character.DRAGON;
		});
	}

	/**
	 * @return Model.Dialogue
	 */
	public getQuestion(): Model.Dialogue {
		return this.last();
	}
}
