
	import { beforeDestroy, mounted } from '@/Utility/Decorators';
	import { Component, Prop } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Global
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewGlobalStarfieldBasic extends ViewBase {
		/**
		 * @type number
		 */
		protected maxHeight: number = 2980 - window.innerHeight; // arbitrary right now
	}
