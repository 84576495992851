import * as BUCK from 'buck-ts';
import * as Config from '@/Config';
import { Model } from './Model';

/**
 * @class Collection
 * @package Core
 * @project BunnyDragon
 */
export abstract class Collection<T extends Model> extends BUCK.Collection.Base<T> {
	/**
	 * @type string
	 */
	public baseUrl: string = Config.App.SERVER_URL;
}
