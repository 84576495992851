
	import ViewCharacterBase from '@/View/Character/Base';
	import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Character
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewCharacterBunny extends ViewCharacterBase {
		/**
		 * @type HTMLElement
		 */
		@Ref('character')
		protected domElement!: HTMLElement;

		/**
		 * @type Record<string, string>
		 */
		protected activeState: Record<string, string> = {
			accessory: 'idle-00',
			blink: 'none',
			body: 'idle-00',
			eye: 'idle-00',
			eyelid: 'idle-00',
			face: 'idle-00',
			pupil: 'idle-00',
		};

		/**
		 * @type Record<string, string[]>
		 */
		protected availableState: Record<string, string[]> = {
			blink: ['none', 'idle-00', 'happy-00', 'sad-00'],
			body: ['idle-00', 'down-00'],
			eye: ['idle-00', 'happy-00', 'sad-00', 'sad-01', 'surprised-00'],
			eyelid: ['none', 'idle-00', 'sad-00', 'sad-01'],
			face: ['idle-00', 'happy-00', 'happy-01', 'sad-00', 'sad-01', 'sad-02', 'surprised-00', 'surprised-01'],
			pupil: ['none', 'idle-00', 'surprised-00'],
		};

		/**
		 * @type Record<string, Record<string, string>>
		 */
		protected poses: Record<string, Record<string, string>> = {
			'awkward': {
				blink: 'none',
				body: 'idle-00',
				eye: 'surprised-00',
				eyelid: 'idle-00',
				face: 'happy-01',
				pupil: 'surprised-00',
			},
			'devastated': {
				blink: 'none',
				body: 'down-00',
				eye: 'surprised-00',
				eyelid: 'none',
				face: 'surprised-01',
				pupil: 'surprised-00',
			},
			'excited': {
				blink: 'none',
				body: 'idle-00',
				eye: 'idle-00',
				eyelid: 'none',
				face: 'happy-01',
				pupil: 'idle-00',
			},
			'happy': {
				blink: 'idle-00',
				body: 'idle-00',
				eye: 'idle-00',
				eyelid: 'none',
				face: 'happy-00',
				pupil: 'none',
			},
			'happy-extra': {
				blink: 'none',
				body: 'idle-00',
				eye: 'happy-00',
				eyelid: 'none',
				face: 'happy-01',
				pupil: 'none',
			},
			'idle': {
				blink: 'none',
				body: 'idle-00',
				eye: 'idle-00',
				eyelid: 'none',
				face: 'idle-00',
				pupil: 'idle-00',
			},
			'irritated': {
				blink: 'none',
				body: 'down-00',
				eye: 'sad-01',
				eyelid: 'sad-01',
				face: 'sad-01',
				pupil: 'idle-00',
			},
			'sad': {
				blink: 'sad-00',
				body: 'idle-00',
				eye: 'sad-00',
				eyelid: 'none',
				face: 'sad-00',
				pupil: 'idle-00',
			},
			'sad-extra': {
				blink: 'idle-00',
				body: 'down-00',
				eye: 'sad-00',
				eyelid: 'none',
				face: 'sad-02',
				pupil: 'none',
			},
			'shocked': {
				blink: 'none',
				body: 'idle-00',
				eye: 'sad-01',
				eyelid: 'sad-01',
				face: 'surprised-01',
				pupil: 'surprised-00',
			},
			'surprised': {
				blink: 'none',
				body: 'down-00',
				eye: 'idle-00',
				eyelid: 'none',
				face: 'surprised-00',
				pupil: 'idle-00',
			},
			'tired': {
				blink: 'idle-00',
				body: 'down-00',
				eye: 'sad-01',
				eyelid: 'none',
				face: 'sad-01',
				pupil: 'surprised-00',
			},
			'wowed': {
				blink: 'none',
				body: 'idle-00',
				eye: 'idle-00',
				eyelid: 'idle-00',
				face: 'surprised-00',
				pupil: 'idle-00',
			},
		};

		/**
		 * @return void
		 */
		@mounted
		protected setInitialPose(): void {
			this.setPose(this.currentPose);
		}

		/**
		 * @return void
		 */
		@Watch('currentPose')
		protected poseChange(): void {
			this.setPose(this.currentPose);
		}
	}
