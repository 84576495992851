import ViewBase from '@/View/Base.vue';
import { Component, Prop } from 'vue-property-decorator';

/**
 * @author Matt Kenefick <matt.kenefick@buck.co>
 * @package View/Character
 * @project ct-innovation-bunnydragon
 */
export default class ViewCharacterBase extends ViewBase {
	/**
	 * @type string
	 */
	@Prop({ default: 'idle' })
	public currentPose!: string;

	/**
	 * @type Record<string, string>
	 */
	protected activeState: Record<string, string> = {};

	/**
	 * @type Record<string, string[]>
	 */
	protected availableState: Record<string, string[]> = {};

	/**
	 * @type HTMLElement
	 */
	protected domElement!: HTMLElement;

	/**
	 * @type Record<string, Record<string, string>>
	 */
	protected poses: Record<string, Record<string, string>> = {};

	/**
	 * @return void
	 */
	public randomize(): void {
		for (const part in this.availableState) {
			const randomIndex = Math.floor(Math.random() * this.availableState[part].length);
			this.setState(part, this.availableState[part][randomIndex]);
		}
	}

	/**
	 * @param HTMLElement domElement
	 * @return void
	 */
	public setElement(domElement: HTMLElement, type: string = 'dragon'): void {
		this.domElement = domElement || document.createElement('div');
		this.domElement.classList.add('character');
		this.domElement.classList.add(type);
	}

	/**
	 * @param string poseName
	 * @return void
	 */
	public setPose(poseName: string): void {
		const pose = this.poses[poseName];

		for (const part in pose) {
			this.setState(part, pose[part]);
		}
	}

	/**
	 * @param string part
	 * @param string state
	 * @return boolean
	 */
	public setState(part: string, state: string): boolean {
		if (this.availableState[part].includes(state)) {
			this.activeState[part] = state;
			this.update();
			return true;
		}

		return false;
	}

	/**
	 * @return void
	 */
	public update(): void {
		for (const part in this.activeState) {
			this.domElement && this.domElement.setAttribute(part, this.activeState[part]);
		}
	}
}
