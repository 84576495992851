import * as Core from '@/Core';

/**
 * @class Shape
 * @package Model
 * @project BunnyDragon
 */
export class Shape extends Core.Model {
	/**
	 * @return string
	 */
	public get endType(): string {
		return this.attributes.endType as string;
	}

	/**
	 * @return string
	 */
	public get startType(): string {
		return this.attributes.startType as string;
	}
}
