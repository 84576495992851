import * as Core from '@/Core';
import * as Enum from '@/Enum';
import * as Model from '@/Model';

/**
 * @class MultipleChoice
 * @package Collection
 * @project BunnyDragon
 */
export class MultipleChoice extends Core.Collection<Model.MultipleChoice> {
	/**
	 * @type Model.MultipleChoice
	 */
	public model: Model.MultipleChoice = new Model.MultipleChoice();

	/**
	 * @param string category
	 * @return Model.MultipleChoice[]
	 */
	public getByCategory(category: string): Model.MultipleChoice[] {
		return this.filter((MultipleChoice: Model.MultipleChoice) => {
			const categories: string[] = MultipleChoice.categories || [];
			return categories.includes(category.trim().toLowerCase());
		});
	}

	/**
	 * @param boolean shuffle
	 * @return Model.MultipleChoice[]
	 */
	public getOneOfEachCategory(shuffle: boolean = true): Model.MultipleChoice[] {
		const categories: string[] = [];
		let collection = this.clone();

		// Shuffle models in this collection
		if (shuffle) {
			collection.models = collection.models.sort(() => {
				return Math.random() - 0.5;
			});
		}

		// Get one of each item
		return collection.filter((model: Model.MultipleChoice) => {
			if (categories.includes(model.categories[0])) {
				return false;
			}

			categories.push(model.categories[0]);

			return true;
		});
	}
}
