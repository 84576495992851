
	import * as Model from '@/Model';
	import ViewBase from '@/View/Base.vue';
	import gsap from 'gsap';
	import { Component, Prop } from 'vue-property-decorator';
	import { sleep } from '@/Utility/Helpers';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Conversation/Interaction
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewConversationInteractionMultipleChoice extends ViewBase {
		/**
		 * Filter, randomize, and limit the choices
		 *
		 * @return Model.MultipleChoice[]
		 */
		public get choices(): Model.MultipleChoice[] {
			return this.interactionModel.option.choices.getOneOfEachCategory();
		}

		/**
		 * @type Model.Interaction
		 */
		@Prop()
		public interactionModel!: Model.Interaction;

		/**
		 * @return string
		 */
		private getComponentName(): string {
			switch (this.interactionModel.responseType) {
				case 'emoji':
					return 'ViewConversationAnswerEmoji';

				case 'emojiImage':
					return 'ViewConversationAnswerEmojiImage';

				case 'text':
					return 'ViewConversationAnswerText';

				default:
					return '';
			}
		}

		/**
		 * @return Promise<void>
		 */
		public async animateOut(): Promise<void> {
			this.className.push('animate-out');
			await sleep(2000);
		}

		/**
		 * @param Model.MultipleChoice multipleChoiceModel
		 * @return Promise<void>
		 */
		protected async Handle_OnSelect(multipleChoiceModel: Model.MultipleChoice): Promise<void> {
			this.$emit('answer', {
				luckyBuddies: multipleChoiceModel.luckyBuddies,
			});
		}
	}
