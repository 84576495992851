
	import * as Model from '@/Model';
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Conversation/Interaction
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewConversationInteractionGrower extends ViewBase {
		/**
		 * @type Model.Interaction
		 */
		@Prop()
		public interactionModel!: Model.Interaction;

		/**
		 * @type boolean
		 */
		protected hasChanged: boolean = false;

		/**
		 * @type number
		 */
		protected ratio: number = 0;

		/**
		 * @type number
		 */
		protected value: number = 0;

		/**
		 * @todo These numbers aren't right. They lean far too heavily to 100%
		 *
		 *		0% = +5
		 *	1-25% = +10
		 *	26-50% = +50
		 *	51-99% = +100
		 *	100% = Quantum
		 *
		 * @param number ratio
		 * @return number
		 */
		protected parseRatio(ratio: number): number {
			let output: number = 0;

			if (ratio == 0) {
				output = 5;
			} else if (ratio > 0 && ratio <= 0.25) {
				output = 10;
			} else if (ratio > 0.25 && ratio <= 0.5) {
				output = 50;
			} else if (ratio > 0.5 && ratio <= 0.99) {
				output = 100;
			} else {
				output = 101;
			}

			return output;
		}

		/**
		 * @param number ratio
		 * @return Promise<void>
		 */
		protected async Handle_OnAnswer(ratio: number): Promise<void> {
			this.ratio = ratio;
			this.value = this.parseRatio(ratio);
			this.hasChanged = true;
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnSubmit(): Promise<void> {
			this.$emit('answerModifier', this.value);
		}
	}
