
	import * as Analytics from '@/Library/Analytics';
	import * as Collection from '@/Collection';
	import * as Config from '@/Config';
	import * as Enum from '@/Enum';
	import * as Manager from '@/Manager';
	import * as Model from '@/Model';
	import PageLandscapeUnavailable from '@/Page/LandscapeUnavailable.vue';
	import { Component, Vue } from 'vue-property-decorator';
	import { Utility } from 'buck-ts';
	import { beforeDestroy, created, mounted } from '@/Utility/Decorators';
	import { adjustViewportHeight, checkDeviceOrientation, checkGyroscopeAccess, disableSafariPinchZoom } from '@/Utility/Helpers';

	@Component({
		components: {
			PageLandscapeUnavailable,
		},
	})
	export default class App extends Vue {
		/**
		 * @type Record<string, string>
		 */
		public config: any = Config;

		/**
		 * @return void
		 */
		@created
		protected defaultBirthdate(): void {
			this.$store.dispatch('resetBirthdate');
		}

		/**
		 * mk: Temporarily disabled because analytics don't appear to be coming
		 * through the app at the moment.
		 *
		 * @return void
		 */
		@mounted
		protected setupAnalytics(): void {
			// if (Config.App.GOOGLE_ANALYTICS_ID) {
			// 	Analytics.embedGoogleAnalytics(Config.App.GOOGLE_ANALYTICS_ID);
			// }
		}

		/**
		 * @return void
		 */
		@mounted
		protected disableSafariPinchZoom(): void {
			disableSafariPinchZoom();
		}

		/**
		 * Determine if we have gyroscope access
		 *
		 * @return void
		 */
		@mounted
		protected setupGyro(): void {
			checkGyroscopeAccess((value: boolean) => (this.$store.state.hasGyroscope = value));
		}

		/**
		 * Mobile devices are really finicky about how they handle touch
		 * events to start audio, which is why I'm putting this here.
		 *
		 * @return void
		 */
		// @mounted
		// protected setupSound(): void {
		// 	document.body.addEventListener('click', () => {});
		// }

		/**
		 * @return void
		 */
		@mounted
		protected setupOrientation(): void {
			window.addEventListener('resize', () => {
				this.$store.state.deviceOrientation = checkDeviceOrientation();
				window.scrollTo(0, 0);
			});

			window.dispatchEvent(new Event('resize'));
		}

		/**
		 * This is a fix because of Safari & iOS.
		 *
		 * @return void
		 */
		@created
		protected setupViewportHeight(): void {
			window.addEventListener('load', adjustViewportHeight);
			window.addEventListener('resize', adjustViewportHeight);
			window.addEventListener('orientationchange', adjustViewportHeight);
			adjustViewportHeight();
		}

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickApp(e: PointerEvent): Promise<void> {
			e.preventDefault();

			if (!this.$store.state.hasAudioPlayed) {
				this.$store.dispatch('toggleAudio');
			}
		}
	}
