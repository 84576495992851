
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { Utility } from 'buck-ts';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewRibbon extends ViewBase {
		/**
		 * Q50,-50 is the locatio of the control point between to path points
		 * @see https://codepen.io/SitePoint/pen/dypyZxy
		 * @see https://jsfiddle.net/vq2c0ndg/
		 *
		 * M0,50 Q50,-50 100,50 T200,50 T300,50 T400,50
		 *
		 * @return string
		 */
		public get svgPath(): string {
			switch (this.version) {
				case 1:
					return 'M-20,50 Q100,0 200,50  T350,50  T450,50';

				case 2:
					return 'M0,30 Q50,0 133,80 T266,50 T400,50';

				case 3:
					return 'M0,50 Q50,0 100,20 T200,70 T300,20 T400,50';
			}

			return 'M0,50 Q50,-50 100,50 T200,50 T300,50 T400,50';
		}

		/**
		 * Timing offset in seconds
		 *
		 * @type number
		 */
		@Prop({ default: 0 })
		public offset!: number;

		/**
		 * @type number
		 */
		@Prop({ default: 0 })
		public version!: number;

		/**
		 * @type string
		 */
		protected path: string = '';
	}
