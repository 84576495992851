
	import * as Model from '@/Model';
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * Emits an event `select` with the multipleChoiceModel we selected
	 *
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewConversationAnswerText extends ViewBase {
		/**
		 * @return string
		 */
		public get categoriesClass(): string {
			return this.multipleChoiceModel.categories.map((category: string) => `category-${category}`).join(' ');
		}

		/**
		 * @return string
		 */
		public get cloudType(): string {
			return `cloud_0${this.index % this.maxClouds}`;
		}

		/**
		 * @return number
		 */
		@Prop({ default: 0 })
		public index!: number;

		/**
		 * @return string
		 */
		@Prop()
		public multipleChoiceModel!: Model.MultipleChoice;

		/**
		 * This is how many clouds we have in image/answer/multiple-choice
		 *
		 * @type number
		 */
		private maxClouds: number = 6;

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClick(e: PointerEvent): Promise<void> {
			e.preventDefault();

			this.$emit('select', this.multipleChoiceModel);
		}
	}
