import * as Config from '@/Config';

/**
  ┌────────────────────────────────────────────────────────────────────────────┐
  │                                                                            │
  │ Store/Persist                                                              │
  │                                                                            │
  │ Custom storage adapter that mixes with Vuex and Vuex-Persist. Ordinarily   │
  │ those would combine with LocalStorage, but this plugin will prioritize     │
  │ chrome.storage.local if it's available.                                    │
  │                                                                            │
  │ Example:                                                                   │
  │                                                                            │
  │     import Persist from '~/store/persist';                                 │
  │                                                                            │
  │     new Vuex.Store({                                                       │
  │         ...                                                                │
  │         plugins: [                                                         │
  │             new VuexPersist(Persist.options),                              │
  │         ]                                                                  │
  │     });                                                                    │
  │                                                                            │
  │ @see https://github.com/championswimmer/vuex-persist                       │
  │                                                                            │
  └────────────────────────────────────────────────────────────────────────────┘
*/

// Options
// ------------------------------------------------------------------------

const options: any = {
	asyncStorage: false,
	key: Config.App.STORAGE_KEY,
	storage: window.localStorage,
};

//
export default { options };
