import * as Collection from '@/Collection';
import * as Model from '@/Model';
import InteractionsJson from '@/Asset/json/interactions';

/**
 * @class ManagerSocket
 * @package Manager
 * @project BunnyDragon
 */
export class Interaction {
	/**
	 * @type Collection.Interaction
	 */
	public collection: Collection.Interaction = Collection.Interaction.hydrate(InteractionsJson.data);

	/**
	 * @param string tag
	 * @return Model.Interaction[]
	 */
	public getByTag(tag: string): Model.Interaction[] {
		return this.collection.getByTag(tag);
	}
}

// Default export
export default new Interaction();
