/**
 * Background volume
 *
 * @type string
 */
export const AUDIO_BACKGROUND_VOLUME = parseFloat(process.env.VUE_APP_AUDIO_BACKGROUND_VOLUME || '0.10');

/**
 * Effects volume
 *
 * @type string
 */
export const AUDIO_EFFECTS_VOLUME = parseFloat(process.env.VUE_APP_AUDIO_EFFECTS_VOLUME || '0.5');

/**
 * How long each birthdate demo swipe should take
 *
 * @type string
 */
export const BIRTHDATE_DEMO_DURATION = parseFloat(process.env.VUE_APP_BIRTHDATE_DEMO_DURATION || '2');

/**
 * Remote URL for the LuckyBuddy cards
 *
 * @type string
 */
export const CARD_URL_BASE = process.env.VUE_APP_CARD_URL_BASE || 'https://zoodicharchar.s3.us-west-2.amazonaws.com/output';

/**
 * Default month for our birthday
 *
 * @type string
 */
export const DEFAULT_BIRTHDATE_MONTH = parseInt(process.env.VUE_APP_DEFAULT_BIRTHDATE_MONTH || '0');

/**
 * Default day for our birthday
 *
 * @type string
 */
export const DEFAULT_BIRTHDATE_DAY = parseInt(process.env.VUE_APP_DEFAULT_BIRTHDATE_DAY || '0');

/**
 * Default year for our birthday
 *
 * @type string
 */
export const DEFAULT_BIRTHDATE_YEAR = parseInt(process.env.VUE_APP_DEFAULT_BIRTHDATE_YEAR || '1985');

/**
 * Default language code for our experience
 *
 * @type string
 */
export const DEFAULT_LANGUAGE_CODE = process.env.VUE_APP_DEFAULT_LANGUAGE_CODE || 'en';

/**
 * If we allow the language selector
 *
 * @type string
 */
export const ENABLE_LANGUAGE = process.env.VUE_APP_ENABLE_LANGUAGE || false;

/**
 * If we should use sound for this experience (mostly for dev)
 *
 * @type string
 */
export const ENABLE_SOUND = process.env.VUE_APP_ENABLE_SOUND || false;

/**
 * Enable tilting cards
 *
 * @type boolean
 */
export const ENABLE_TILT = !!(process.env.VUE_APP_ENABLE_TILT || false);

/**
 * ID for Google Analytics GA4
 *
 * @type string
 */
export const GOOGLE_ANALYTICS_ID = process.env.VUE_APP_GOOGLE_ANALYTICS_ID || '';

/**
 * Default number of rounds
 *
 * @type string
 */
export const MAX_QUESTIONS = parseInt(process.env.VUE_APP_MAX_QUESTIONS || '5');

/**
 * Where our API server lives
 *
 * @type string
 */
export const SERVER_URL = process.env.VUE_APP_SERVER_URL || 'http://localhost:8072';

/**
 * If we should show the coming soon page
 *
 * @type boolean
 */
export const SHOW_COMINGSOON = process.env.VUE_APP_SHOW_COMINGSOON || false;

/**
 * How long to transition from one area of starfield to another
 *
 * @type string
 */
export const STARFIELD_MOTION_DURATION = parseFloat(process.env.VUE_APP_STARFIELD_MOTION_DURATION || '5');

/**
 * @type string
 */
export const STORAGE_KEY = 'ct-innovation-bunnydragon';
