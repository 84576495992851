
	import ViewBase from '@/View/Base.vue';
	import { Component } from 'vue-property-decorator';
	import { sleep } from '@/Utility/Helpers';

	/**
	 * Displays the intro logo and CTA on the splash screen.
	 *
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewWelcome extends ViewBase {
		/**
		 * @return Promise<void>
		 */
		public async animateOut(): Promise<void> {
			await sleep(3000);
		}
	}
