import * as Manager from '@/Manager';
import { CalendarChinese } from 'date-chinese';

/**
 * @type type
 */
export type TZodiacElement = 'Fire' | 'Earth' | 'Metal' | 'Water' | 'Wood';

/**
 * @type type
 */
export type TZodiacSign = 'Rat' | 'Ox' | 'Tiger' | 'Rabbit' | 'Dragon' | 'Snake' | 'Horse' | 'Goat' | 'Monkey' | 'Rooster' | 'Dog' | 'Pig';

/**
 * @type interface
 */
export interface IZodiac {
	element: string;
	sign: string;
}

/**
 * The year provided should be converted fromGregorian on the Chinese calendar.
 *
 * @param number chineseYear
 * @returns IZodiac
 */
function getChineseZodiacAndElement(chineseYear: number): IZodiac {
	const elements: TZodiacElement[] = [
		Manager.Language.get('zodiac_element_wood') as TZodiacElement,
		Manager.Language.get('zodiac_element_wood') as TZodiacElement,
		Manager.Language.get('zodiac_element_fire') as TZodiacElement,
		Manager.Language.get('zodiac_element_fire') as TZodiacElement,
		Manager.Language.get('zodiac_element_earth') as TZodiacElement,
		Manager.Language.get('zodiac_element_earth') as TZodiacElement,
		Manager.Language.get('zodiac_element_metal') as TZodiacElement,
		Manager.Language.get('zodiac_element_metal') as TZodiacElement,
		Manager.Language.get('zodiac_element_water') as TZodiacElement,
		Manager.Language.get('zodiac_element_water') as TZodiacElement,
	];
	const signs: TZodiacSign[] = [
		Manager.Language.get('zodiac_sign_rat') as TZodiacSign,
		Manager.Language.get('zodiac_sign_ox') as TZodiacSign,
		Manager.Language.get('zodiac_sign_tiger') as TZodiacSign,
		Manager.Language.get('zodiac_sign_rabbit') as TZodiacSign,
		Manager.Language.get('zodiac_sign_dragon') as TZodiacSign,
		Manager.Language.get('zodiac_sign_snake') as TZodiacSign,
		Manager.Language.get('zodiac_sign_horse') as TZodiacSign,
		Manager.Language.get('zodiac_sign_goat') as TZodiacSign,
		Manager.Language.get('zodiac_sign_monkey') as TZodiacSign,
		Manager.Language.get('zodiac_sign_rooster') as TZodiacSign,
		Manager.Language.get('zodiac_sign_dog') as TZodiacSign,
		Manager.Language.get('zodiac_sign_pig') as TZodiacSign,
	];

	// The cycle starts from Rat and Metal, which corresponds to the year 0 in the Chinese calendar
	const element = elements[(chineseYear - 1) % 10];
	const sign = signs[(chineseYear - 1) % 12];

	return {
		element,
		sign,
	};
}

/**
 * @param IBirthdate userBirthdate
 * @return string
 */
export function getDateAsWords(userBirthdate: IBirthdate): string {
	const day = userBirthdate.day || 1;
	const month = userBirthdate.month || 0;
	const year = userBirthdate.year || 1970;

	return `${getMonthName(month)} ${day}, ${year}`;
}

/**
 * @param number month
 * @return string
 */
export function getMonthName(month: number): string {
	const months: string[] = [
		Manager.Language.get('month_january'),
		Manager.Language.get('month_february'),
		Manager.Language.get('month_march'),
		Manager.Language.get('month_april'),
		Manager.Language.get('month_may'),
		Manager.Language.get('month_june'),
		Manager.Language.get('month_july'),
		Manager.Language.get('month_august'),
		Manager.Language.get('month_september'),
		Manager.Language.get('month_october'),
		Manager.Language.get('month_november'),
		Manager.Language.get('month_december'),
	];

	return month >= 0 ? months[month] : '';
}

/**
 * @return string[]
 */
export function getAllMonthNames(): string[] {
	return [
		Manager.Language.get('month_january'),
		Manager.Language.get('month_february'),
		Manager.Language.get('month_march'),
		Manager.Language.get('month_april'),
		Manager.Language.get('month_may'),
		Manager.Language.get('month_june'),
		Manager.Language.get('month_july'),
		Manager.Language.get('month_august'),
		Manager.Language.get('month_september'),
		Manager.Language.get('month_october'),
		Manager.Language.get('month_november'),
		Manager.Language.get('month_december'),
	];
}

/**
 * @param number year
 * @return boolean
 */
export function isLeapYear(year: number): boolean {
	return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

/**
 * Returns the Chinese zodiac and element for a given date.
 *
 * @param number year (e.g. 2020)
 * @param number month (0-11)
 * @param number day (1-31)
 * @returns IZodiac
 */
export function getZodiacElementFromDate(year: number, month: number, day: number): IZodiac {
	const cal = new CalendarChinese();
	cal.fromGregorian(year, month, day);

	// @ts-ignore
	return getChineseZodiacAndElement(cal.year);
}
