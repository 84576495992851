
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewGlobalAbout extends ViewBase {
		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickClose(e: PointerEvent): Promise<void> {
			e.preventDefault();

			this.$store.state.showGlobalAbout = false;
		}
	}
