
	import ViewCharacterBase from '@/View/Character/Base';
	import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Character
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewCharacterDragon extends ViewCharacterBase {
		/**
		 * @type HTMLElement
		 */
		@Ref('character')
		protected domElement!: HTMLElement;

		/**
		 * @type Record<string, string>
		 */
		protected activeState: Record<string, string> = {
			accessory: 'idle-00',
			beard: 'idle-00',
			body: 'idle-00',
			eyelid: 'idle-00',
			mouth: 'idle-00',
			pupil: 'idle-00',
		};

		/**
		 * @type Record<string, string[]>
		 */
		protected availableState: Record<string, string[]> = {
			accessory: ['idle-00', 'tear-00'],
			beard: ['idle-00'],
			body: ['idle-00', 'shocked-00'],
			eyelid: ['idle-00', 'blink-00', 'cry-00', 'half-00', 'sad-00', 'winkleft-00', 'winkright-00'],
			mouth: ['happy-00', 'happy-01', 'happy-02', 'happy-03', 'happy-04', 'happy-05', 'oh-00', 'oh-01', 'sad-00', 'sad-01', 'shocked-00'],
			pupil: ['idle-00', 'shocked-00', 'sparkle-00', 'sparkle-01'],
		};

		/**
		 * @type Record<string, Record<string, string>>
		 */
		protected poses: Record<string, Record<string, string>> = {
			'cheeky': {
				accessory: 'idle-00',
				body: 'idle-00',
				eyelid: 'winkleft-00',
				mouth: 'happy-03',
				pupil: 'sparkle-01',
			},
			'excited': {
				accessory: 'idle-00',
				body: 'idle-00',
				eyelid: 'idle-00',
				mouth: 'happy-03',
				pupil: 'sparkle-01',
			},
			'excited-extra': {
				accessory: 'idle-00',
				body: 'shocked-00',
				eyelid: 'idle-00',
				mouth: 'happy-05',
				pupil: 'sparkle-01',
			},
			'happy': {
				accessory: 'idle-00',
				body: 'idle-00',
				eyelid: 'idle-00',
				mouth: 'happy-00',
				pupil: 'idle-00',
			},
			'happy-extra': {
				accessory: 'idle-00',
				body: 'idle-00',
				eyelid: 'blink-00',
				mouth: 'happy-02',
				pupil: 'idle-00',
			},
			'idle': {
				accessory: 'idle-00',
				body: 'idle-00',
				eyelid: 'idle-00',
				mouth: 'idle-00',
				pupil: 'idle-00',
			},
			'irritated': {
				accessory: 'idle-00',
				body: 'idle-00',
				eyelid: 'blink-00',
				mouth: 'oh-00',
				pupil: 'shocked-00',
			},
			'sad': {
				accessory: 'idle-00',
				body: 'idle-00',
				eyelid: 'sad-00',
				mouth: 'sad-01',
				pupil: 'idle-00',
			},
			'sad-extra': {
				accessory: 'tear-00',
				body: 'idle-00',
				eyelid: 'sad-00',
				mouth: 'sad-00',
				pupil: 'sparkle-00',
			},
			'shocked': {
				accessory: 'idle-00',
				body: 'shocked-00',
				eyelid: 'idle-00',
				mouth: 'sad-00',
				pupil: 'shocked-00',
			},
			'sleezy': {
				accessory: 'idle-00',
				body: 'idle-00',
				eyelid: 'half-00',
				mouth: 'happy-04',
				pupil: 'idle-00',
			},
			'surprised': {
				accessory: 'idle-00',
				body: 'idle-00',
				eyelid: 'half-00',
				mouth: 'oh-00',
				pupil: 'idle-00',
			},
			'wowed': {
				accessory: 'idle-00',
				body: 'shocked-00',
				eyelid: 'idle-00',
				mouth: 'oh-01',
				pupil: 'sparkle-00',
			},
		};

		/**
		 * @return void
		 */
		@mounted
		protected setInitialPose(): void {
			this.setPose(this.currentPose);
		}

		/**
		 * @return void
		 */
		@Watch('currentPose')
		protected poseChange(): void {
			this.setPose(this.currentPose);
		}
	}
