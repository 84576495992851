import './setup';
import './Theme';
import App from './App.vue';
import Router from './Router';
import Store from './Store';
import Vue from 'vue';

(async function () {
	new Vue({
		render: (h) => h(App),
		router: Router,
		store: Store,
	}).$mount('#app');
})();
