/* eslint-disable */

// Chinese translation
export default {
	copy_birthdate_confirmday: '确认日期',
	copy_birthdate_confirmmonth: '确认月份',
	copy_birthdate_confirmyear: '确认年份',
	copy_birthdate_dragtoselect: '拖动以选择日期',
	copy_confirmation_birthdate_header: '您的生日是',
	copy_confirmation_youarea: '您是',
	copy_general_day: '日',
	copy_general_month: '月',
	copy_general_year: '年',
	copy_landscape_tiltphone: '请将您的手机倾斜。',
	copy_welcome_enterbirthday: '请输入您的生日',
	copy_welcome_touchtobegin: `点击开始`,

	month_january: '一月',
	month_february: '二月',
	month_march: '三月',
	month_april: '四月',
	month_may: '五月',
	month_june: '六月',
	month_july: '七月',
	month_august: '八月',
	month_september: '九月',
	month_october: '十月',
	month_november: '十一月',
	month_december: '十二月',

	zodiac_element_wood: '木',
	zodiac_element_fire: '火',
	zodiac_element_earth: '土',
	zodiac_element_metal: '金',
	zodiac_element_water: '水',

	zodiac_sign_rat: '鼠',
	zodiac_sign_ox: '牛',
	zodiac_sign_tiger: '虎',
	zodiac_sign_rabbit: '兔',
	zodiac_sign_dragon: '龙',
	zodiac_sign_snake: '蛇',
	zodiac_sign_horse: '马',
	zodiac_sign_goat: '羊',
	zodiac_sign_monkey: '猴',
	zodiac_sign_rooster: '鸡',
	zodiac_sign_dog: '狗',
	zodiac_sign_pig: '猪',

	ordinal_first: '第一',
	ordinal_second: '第二',
	ordinal_third: '第三',
	ordinal_suffix: '第',
};
