import * as Core from '@/Core';
import * as Enum from '@/Enum';

/**
 * @class Dialogue
 * @package Model
 * @project BunnyDragon
 */
export class Dialogue extends Core.Model {
	/**
	 * @return string
	 */
	public get pose(): string {
		return this.attributes.pose as string;
	}

	/**
	 * @return Enum.Character
	 */
	public get character(): Enum.Character {
		return this.attributes.character as Enum.Character;
	}

	/**
	 * @return Record<string, string>
	 */
	public get characterPoses(): Record<string, string> {
		return this.attributes.characterPoses as Record<string, string>;
	}

	/**
	 * @return string
	 */
	public get text(): string {
		return this.attributes.text as string;
	}
}
