/* eslint-disable */

/**
 * @todo remove "copy_" it's redundant
 */
export default {
	about_body_00: 'An experiment in divination to celebrate Lunar New Year.',
	about_body_01:
		'<strong>Made by</strong><span>Helena Dong, Julie Alter, Joy Tien, Matt Kenefick, Pablo Martinez-Arastey, Paola Chen Li, Shelby Chan, Tinghe Yang, Yinan Liang</span>',
	about_body_02:
		'<strong>With</strong><span>Alyzeh Ashraff, Ashley Domondon, Eric Chang, Leah Grier, Michelle Higa Fox, Ryan McGrath, Talia Lamdan</span>',
	about_body_03:
		'Conversation and Fortunes were created through a mix of custom GPTs and human revision.<br /><br />❤️ from <a href="https://buck.co" target="_blank">BUCK</a>.',

	button_confirmbirthday: `Great!`,
	button_confirmday: 'Confirm Day',
	button_confirmmonth: 'Confirm Month',
	button_confirmyear: 'Confirm Year',
	button_download: 'Download',
	button_enterbirthday: 'Identify my Zodiac',
	button_receivefortune: 'Continue',
	button_redo: 'Redo',
	button_restart: 'Restart',
	button_save: 'Save',
	button_selectcolor: 'This color!',
	button_selectshape: 'Looks about right',
	button_share: 'Share',
	button_tryagain: 'One More Time',

	copy_birthdate_dragtoselect: 'Drag to select date',
	copy_birthdate_scrolltoselect: 'Scroll to select date',
	copy_confirmation_birthdate_header: 'With a birthdate on',
	copy_confirmation_youarea: 'You are a...',
	copy_confirmation_youarean: 'You are an...',
	copy_welcome_00: 'The time has come for the passage from one year to the next.',
	copy_welcome_01:
		'Brave Rabbit has endured much, and now must teach young Dragon the gift of <strong>foresight</strong> and <strong>benevolence</strong>.',
	copy_welcome_02: 'But first, dear traveler...<br />tell us when your journey began.',

	// copy_welcome_footnote: `Rest assured, no personal data is collected or shared. Your cosmic identity is solely used to personalize the experience.`,
	copy_welcome_footnote: `No personal data is collected or shared. Your cosmic identity personalizes your experience.`,
	copy_general_day: 'Day',
	copy_general_month: 'Month',
	copy_general_year: 'Year',
	copy_landscape_rotatephone: 'Please rotate your phone.',
	copy_notfound_body: 'You are lost',
	copy_welcome_tagline: 'An experiment in divination to celebrate Lunar New Year.',
	copy_welcome_enterbirthday: 'Enter your birthday',
	copy_welcome_touchtobegin: `To begin, <strong>touch</strong> the cosmos...`,

	copy_outro_00: 'Bunny hops down and cedes the plinth to Dragon. The strands of destiny braid into...',
	copy_outro_01:
		'<strong>Your Fortune</strong> – for the Year of the Wood Dragon ahead, and a <strong>Lucky Buddy</strong>, to protect you against troubles you may face.',
	copy_outro_02: 'A distant whoosh draws closer...',

	month_january: 'January',
	month_february: 'February',
	month_march: 'March',
	month_april: 'April',
	month_may: 'May',
	month_june: 'June',
	month_july: 'July',
	month_august: 'August',
	month_september: 'September',
	month_october: 'October',
	month_november: 'November',
	month_december: 'December',

	shareable_description: 'An experiment in divination for Lunar New Year. Made with 🤖, 🧠, ☕, and ❤️ from BUCK.',
	shareable_title: '𝒵𝑜𝑜𝒹𝒾𝒞𝒽𝒶𝓇𝒞𝒽𝒶𝓇 | Behold Your Fortune in 2024',

	zodiac_element_wood: 'Wood',
	zodiac_element_fire: 'Fire',
	zodiac_element_earth: 'Earth',
	zodiac_element_metal: 'Metal',
	zodiac_element_water: 'Water',

	zodiac_sign_rat: 'Rat',
	zodiac_sign_ox: 'Ox',
	zodiac_sign_tiger: 'Tiger',
	zodiac_sign_rabbit: 'Rabbit',
	zodiac_sign_dragon: 'Dragon',
	zodiac_sign_snake: 'Snake',
	zodiac_sign_horse: 'Horse',
	zodiac_sign_goat: 'Goat',
	zodiac_sign_monkey: 'Monkey',
	zodiac_sign_rooster: 'Rooster',
	zodiac_sign_dog: 'Dog',
	zodiac_sign_pig: 'Pig',

	ordinal_first: 'st',
	ordinal_second: 'nd',
	ordinal_third: 'rd',
	ordinal_suffix: 'th',
};
