
	import * as Config from '@/Config';
	import * as UtilityDate from '@/Utility/Date';
	import ViewBase from '@/View/Base.vue';
	import ViewBirthdateSelector from '@/View/Birthdate/Selector.vue';
	import { Component, Prop, Ref } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Birthdate
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewBirthdateSelectMonth extends ViewBase {
		/**
		 * @type ViewBirthdateSelector
		 */
		@Ref('birthdaySelector')
		private birthdaySelector!: ViewBirthdateSelector;

		/**
		 * @return number
		 */
		public get month(): string {
			return this.$store.state.userBirthdate.month;
		}

		/**
		 * @type string
		 */
		public set month(value: string) {
			this.$store.commit('birthdateMonth', parseInt(value) - 1);
		}

		/**
		 * @return string[]
		 */
		protected get months(): string[] {
			return UtilityDate.getAllMonthNames();
		}

		/**
		 * @type boolean
		 */
		protected enableSelector: boolean = true;

		/**
		 * @return void
		 */
		@mounted
		protected async animateSelectorIn(): Promise<void> {
			if (this.birthdaySelector) {
				await this.birthdaySelector.scrollToApproximateIndex(0, 1, Config.App.BIRTHDATE_DEMO_DURATION);
			}
		}

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickConfirm(e: PointerEvent): Promise<void> {
			// Disable scroll
			this.enableSelector = false;

			// Animate this view out
			this.animateOut();
		}
	}
