import * as Config from '@/Config';

/**
 * @type interface
 */
export interface IStoreState {
	answerModifier: number;
	answers: IConversationInteractionAnswer[];
	characterPoseBunny: string;
	characterPoseDragon: string;
	currentMotionScrollRatio: number;
	deviceOrientation: string;
	entrypointLuckyBuddy: string;
	entrypointZodiac: string;
	foo: any;
	hasAudioPlayed: boolean;
	hasGyroscope: boolean;
	isIos: boolean;
	isMuted: boolean;
	languageCode: string;
	optimizePerformance: boolean;
	showGlobalAbout: boolean;
	showGlobalFooter: boolean;
	showGlobalHeader: boolean;
	showLanguageSelection: boolean;
	sphereColor: string;
	starfieldAnimateToPosition: boolean;
	starfieldScrollRatio: number;
	timesCompleted: number;
	timesPlayedPerVisit: number;
	timesVisited: number;
	userBirthdate: IBirthdate;
	userBirthdateRedo: boolean;
	userZodiac: string;
	userZodiacElement: string;
	userZodiacSign: string;
	winningCategory: string;
	winningLuckyBuddy: string;
}

/**
 * @class State
 * @package Store
 * @project BunnyDragon
 */
const state: IStoreState = {
	answerModifier: 0,
	answers: [],
	characterPoseBunny: 'idle',
	characterPoseDragon: 'idle',
	currentMotionScrollRatio: -1,
	deviceOrientation: 'landscape',
	entrypointLuckyBuddy: '',
	entrypointZodiac: '',
	foo: false,
	hasAudioPlayed: false,
	hasGyroscope: false,
	isIos: ['iPad', 'iPhone', 'iPod'].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document),
	isMuted: true,
	languageCode: Config.App.DEFAULT_LANGUAGE_CODE,
	optimizePerformance: false,
	showGlobalAbout: false,
	showGlobalFooter: true,
	showGlobalHeader: true,
	showLanguageSelection: false,
	sphereColor: '',
	starfieldAnimateToPosition: false,
	starfieldScrollRatio: 0.0,
	timesCompleted: 0,
	timesPlayedPerVisit: 0,
	timesVisited: 0,
	userBirthdate: {
		day: Config.App.DEFAULT_BIRTHDATE_DAY,
		month: Config.App.DEFAULT_BIRTHDATE_MONTH,
		year: Config.App.DEFAULT_BIRTHDATE_YEAR,
	},
	userBirthdateRedo: false,
	userZodiac: '',
	userZodiacElement: '',
	userZodiacSign: '',
	winningCategory: '',
	winningLuckyBuddy: '',
};

export default state;
