import * as Config from '@/Config';
import Store from '@/Store';
import { Sound } from 'buck-ts';
import { assetRef } from '@/Utility/Helpers';

/**
 * @class Audio
 * @package Manager
 * @project BunnyDragon
 */
export class Audio {
	/**
	 * @type Sound.Sound
	 */
	protected backgroundSound!: Sound.Sound;

	/**
	 * @type Record<string, string>
	 */
	protected effects: Record<string, string> = {
		'background-loop': assetRef('sound/background-loop.mp3'),
		'background-music': assetRef('sound/background-music.mp3'),
		'background-opening': assetRef('sound/background-opening.mp3'),
		'birthdate-button': assetRef('sound/character-speech-b.mp3'),
		'birthdate-confirmation': assetRef('sound/envelope-a.mp3'),
		'birthdate-reject': assetRef('sound/character-speech-a.mp3'),
		'character-speech-a': assetRef('sound/character-speech-a.mp3'),
		'character-speech-b': assetRef('sound/character-speech-b.mp3'),
		'click-a': assetRef('sound/click-a.mp3'),
		'click-b': assetRef('sound/click-b.mp3'),
		'click-c': assetRef('sound/click-c.mp3'),
		'envelope-a': assetRef('sound/envelope-a.mp3'),
		'envelope-b': assetRef('sound/envelope-b.mp3'),
		'flipcard-a': assetRef('sound/flipcard-a.mp3'),
	};

	/**
	 * @constructor
	 */
	constructor() {
		console.log('Sound effects', this.effects);
	}

	/**
	 * @type number
	 */
	protected fadeDuration: number = 1000;

	/**
	 * @return void
	 */
	public isBackgroundMusicPlaying(): boolean {
		return this.backgroundSound && this.backgroundSound.audioElement.currentTime > 0;
	}

	/**
	 * @return void
	 */
	public playBackgroundMusic(): Sound.Sound {
		const url: string = assetRef('sound/background-music.mp3');

		// Volume
		console.log('Playing audio at', Config.App.AUDIO_BACKGROUND_VOLUME);

		// Create new instance if not exists
		if (!this.backgroundSound) {
			this.backgroundSound = new Sound.Sound(url, {
				loop: true,
				volume: Config.App.AUDIO_BACKGROUND_VOLUME,
			});
		}

		// Play fade in
		this.backgroundSound.fadeIn(this.fadeDuration, Config.App.AUDIO_BACKGROUND_VOLUME);

		return this.backgroundSound;
	}

	/**
	 * @param soundKey
	 * @return Sound.Sound | undefined
	 */
	public playEffect(soundKey: string, volume: number = 0): Sound.Sound | undefined {
		const url: string = this.effects[soundKey];

		// Experience is muted
		if (Store.getters.isMuted) {
			console.warn('Store is muted', soundKey);
			return;
		}

		// We haven't started the audio globally yet
		if (!Store.state.hasAudioPlayed) {
			console.warn('Assuming this may be coming through another event, canceling audio effect.');
			return;
		}

		// Nothing to play
		if (!url) {
			console.warn('Sound effect not found', soundKey);
			return;
		}

		// Play audio
		console.log('Playing sound effect', soundKey, volume, url);

		// Create new instance if not exists
		const sound: Sound.Sound = new Sound.Sound(url, {
			volume: volume || Config.App.AUDIO_EFFECTS_VOLUME,
		});

		// Play sound
		sound.play();

		return sound;
	}

	/**
	 * @return void
	 */
	public stopBackgroundMusic(): void {
		// Fade out and stop sound
		this.backgroundSound.pause();
	}

	// Specific
	// ---------------------------------------------------------------------------

	/**
	 * @return Sound.Sound | undefined
	 */
	public playBirthdateButton(): Sound.Sound | undefined {
		return this.playEffect('birthdate-button', 1.0);
	}

	/**
	 * @return Sound.Sound | undefined
	 */
	public playFlipCard(): Sound.Sound | undefined {
		return this.playEffect('flipcard-a');
	}

	/**
	 * @return Sound.Sound | undefined
	 */
	public playEnvelope(): Sound.Sound | undefined {
		return this.playEffect('envelope-a');
	}

	/**
	 * @return Sound.Sound | undefined
	 */
	public playClick(): Sound.Sound | undefined {
		return this.playEffect('click-a');
	}

	/**
	 * @return Sound.Sound | undefined
	 */
	public playCharacterSpeech(): Sound.Sound | undefined {
		return this.playEffect('character-speech-a');
	}

	/**
	 * @return Sound.Sound | undefined
	 */
	public playBackgroundOpening(): Sound.Sound | undefined {
		return this.playEffect('background-opening');
	}
}

export default new Audio();
