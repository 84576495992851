
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { Input } from 'buck-ts';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Graphic
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewGraphicScrollGesture extends ViewBase {
		// Not implemented
	}
