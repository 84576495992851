
	import * as Config from '@/Config';
	import * as Manager from '@/Manager';
	import { beforeDestroy, created, mounted } from '@/Utility/Decorators';
	import { Component, Vue } from 'vue-property-decorator';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project BunnyDragon
	 */
	@Component
	export default class ViewBase extends Vue {
		/**
		 * @type string
		 */
		public cid: string = Math.random().toString(32).substr(2, 7);

		/**
		 * Class names we can apply to the element
		 *
		 * @type string[]
		 */
		public className: string[] = [];

		/**
		 * @type Record<string, string>
		 */
		public config: any = Config;

		/**
		 * @type Record<string, string>
		 */
		public language: Record<string, string> = Manager.Language.all();

		/**
		 * @type number
		 */
		public mounted: boolean = false;

		/**
		 * Unique symbol for every class to reference
		 *
		 * @type symbol
		 */
		public symbol: symbol = Symbol();

		/**
		 * Automatically bind functions to scope
		 *
		 * @type string[]
		 */
		protected bindings: string[] = [];

		/**
		 * General delta for increasing values
		 *
		 * @type number
		 */
		protected delta: number = 0;

		/**
		 * Bind associated methods with class
		 *
		 * @type string[]
		 */
		@created
		public setupBindings(): void {
			let binding: string;

			for (binding of this.bindings) {
				// @ts-ignore
				this[binding] = this[binding].bind(this);
			}
		}

		/**
		 * @param string key
		 * @return string
		 */
		public getQueryParameter(key: string): string {
			return this.$route?.query[key] as string;
		}

		/**
		 * @param string key
		 * @param string value
		 * @return void
		 */
		public setQueryParameter(key: string, value: string): void {
			const oScrollY: number = window.scrollY;

			// Navigate to tab
			this.$router.push({
				query: Object.assign({ ...this.$route.query }, { [key]: value }),
			});

			// ScrollY
			window.scrollTo(0, oScrollY);
		}

		/**
		 * @param string methodName
		 * @return void
		 */
		protected bind(methodName: string): void {
			// @ts-ignore
			this[methodName] = this[methodName].bind(this);
		}

		/**
		 * Add CID to DOM element for easy access
		 *
		 * @return void
		 */
		@mounted
		private attachCid(): void {
			this.$el.setAttribute('data-cid', this.cid);
		}

		/**
		 * @return void
		 */
		@mounted
		private mounting(): void {
			this.mounted = true;
			this.className.push('mounted');
		}

		/**
		 * @return void
		 */
		// @beforeDestroy
		// private unmounting(): void {
		// 	this.mounted = false;
		// 	this.$el.classList.remove('mounted');
		// }

		/**
		 * @return Promise<void>
		 */
		public async animateIn(): Promise<void> {
			this.className.push('animate-in');
			this.className = this.className.filter((className: string) => className !== 'animate-out');
		}

		/**
		 * @return Promise<void>
		 */
		public async animateOut(): Promise<void> {
			this.className.push('animate-out');
			this.className = this.className.filter((className: string) => className !== 'animate-in');
		}
	}
