import * as Core from '@/Core';
import * as Model from '@/Model';

/**
 * @class Color
 * @package Collection
 * @project BunnyDragon
 */
export class Color extends Core.Collection<Model.Color> {
	/**
	 * @type Model.Color
	 */
	public model: Model.Color = new Model.Color();

	/**
	 * @return Model.Color[]
	 */
	public getHexColors(): string[] {
		return this.map((color: Model.Color) => {
			return color.color;
		});
	}
}
