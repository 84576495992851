
	import ViewBase from '@/View/Base.vue';
	import { Component } from 'vue-property-decorator';
	import { mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewOutro extends ViewBase {
		/**
		 * @type boolean
		 */
		protected skipAhead: boolean = false;

		/**
		 * @return void
		 */
		@mounted
		protected automaticallySkipAhead(): void {
			setTimeout(() => (this.skipAhead = true), 1000 * 7);
		}

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClick(e: PointerEvent): Promise<void> {
			e.preventDefault();

			if (this.skipAhead) {
				this.$router.push({ name: 'PageResult' });
			} else {
				this.skipAhead = true;
			}
		}
	}
