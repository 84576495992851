import * as Lang from '@/Asset/lang';
import Store from '@/Store';

/**
 * @class ManagerSocket
 * @package Manager
 * @project BunnyDragon
 */
export class ManagerLanguage {
	/**
	 * @type string
	 */
	public defaultCode: string = 'en';

	/**
	 * @return Record<string, string>
	 */
	public all(): Record<string, string> {
		const code = Store.state.languageCode || this.defaultCode;
		return (Lang as any)[code] || {};
	}

	/**
	 * @param string key
	 * @return string
	 */
	public get(key: string): string {
		return this.all()[key] || '';
	}
}

export default new ManagerLanguage();
