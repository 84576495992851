import Store from '@/Store';
import { NavigationGuardNext, Route } from 'vue-router';

/**
 * Count the amount of times we've seen the results page
 *
 * @return void
 */
export function CompletionCounter(to: Route, from: Route, next: NavigationGuardNext): void {
	// Only if we're going to results
	if (to.name === 'PageResult') {
		Store.dispatch('incrementCompleted');
	}

	// Only if we're going to results
	if (to.name === 'PageInterlude') {
		Store.dispatch('incrementPlayedPerVisit');
	}

	// Continue to next middleware
	next();
}
