import * as Collection from '@/Collection';
import * as Core from '@/Core';
import * as Factory from '@/Factory';
import * as Model from '@/Model';

/**
 * @class Color
 * @package Model
 * @project BunnyDragon
 */
export class Color extends Core.Model {
	/**
	 * @return string[]
	 */
	public get categories(): string[] {
		return this.attributes.categories as string[];
	}

	/**
	 * @return string
	 */
	public get color(): string {
		return this.attributes.color as string;
	}

	/**
	 * @return string
	 */
	public get colorName(): string {
		return this.attributes.colorName as string;
	}

	/**
	 * @return string
	 */
	public get slug(): string {
		return this.attributes.slug as string;
	}

	// region: Relationships
	// ---------------------------------------------------------------------------

	/**
	 * NOTE: This is a special relationship. Ordinarily we handle relationships
	 * through data attributes, but in this case we're modifying a data
	 * element to create a custom relationship.
	 *
	 * NOTE: This uses SLUG rather than ID as per MultipleChoice
	 *
	 * @return Model.LuckyBuddy[]
	 */
	public get luckyBuddies(): Model.LuckyBuddy[] {
		const slugs = this.attributes.luckyBuddies as string[];
		const collection: Collection.LuckyBuddy = Factory.LuckyBuddy.collection();

		return collection.filter((buddyModel: Model.LuckyBuddy) => (slugs || []).includes(buddyModel.slug));
	}

	// endregion: Relationships
}
