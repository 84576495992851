/* eslint-disable */

// Japanese translation
export default {
	copy_birthdate_confirmday: '日を確認',
	copy_birthdate_confirmmonth: '月を確認',
	copy_birthdate_confirmyear: '年を確認',
	copy_birthdate_dragtoselect: '日付を選択するにはドラッグしてください',
	copy_confirmation_birthdate_header: '誕生日が',
	copy_confirmation_youarea: 'あなたは',
	copy_general_day: '日',
	copy_general_month: '月',
	copy_general_year: '年',
	copy_landscape_tiltphone: 'お手持ちの端末を傾けてください。',
	copy_welcome_enterbirthday: '生年月日をご入力ください',
	copy_welcome_touchtobegin: `開始するには、<strong>宇宙に触れて</strong>ください`,

	month_january: '1月',
	month_february: '2月',
	month_march: '3月',
	month_april: '4月',
	month_may: '5月',
	month_june: '6月',
	month_july: '7月',
	month_august: '8月',
	month_september: '9月',
	month_october: '10月',
	month_november: '11月',
	month_december: '12月',

	zodiac_element_wood: '木',
	zodiac_element_fire: '火',
	zodiac_element_earth: '土',
	zodiac_element_metal: '金',
	zodiac_element_water: '水',

	zodiac_sign_rat: '鼠',
	zodiac_sign_ox: '牛',
	zodiac_sign_tiger: '虎',
	zodiac_sign_rabbit: '兎',
	zodiac_sign_dragon: '龍',
	zodiac_sign_snake: '蛇',
	zodiac_sign_horse: '馬',
	zodiac_sign_goat: '羊',
	zodiac_sign_monkey: '猿',
	zodiac_sign_rooster: '鶏',
	zodiac_sign_dog: '犬',
	zodiac_sign_pig: '猪',

	ordinal_first: '番目',
	ordinal_second: '番目',
	ordinal_third: '番目',
	ordinal_suffix: '番目',
};
