
	import * as Enum from '@/Enum';
	import * as Model from '@/Model';
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop, Ref } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewConversationInteraction extends ViewBase {
		/**
		 * @type Ref
		 */
		@Ref('interactionView')
		public interactionView!: any;

		/**
		 * @type Model.Interaction
		 */
		@Prop()
		public interactionModel!: Model.Interaction;

		/**
		 * @return Promise<void>
		 */
		@mounted
		public async animateIn(): Promise<void> {
			if (this.interactionView.animateIn) {
				setTimeout(() => this.interactionView.animateIn(), 1);
			}
		}

		/**
		 * @return Promise<void>
		 */
		public async animateOut(): Promise<void> {
			if (this.interactionView.animateOut) {
				await this.interactionView.animateOut();
			}
		}

		/**
		 * @param IConversationInteractionAnswer e
		 * @return Promise<void>
		 */
		protected async Handle_OnAnswer(e: IConversationInteractionAnswer): Promise<void> {
			this.$emit('answer', e);
		}

		/**
		 * @param number value
		 * @return Promise<void>
		 */
		protected async Handle_OnAnswerModifier(value: number): Promise<void> {
			this.$emit('answerModifier', value);
		}

		/**
		 * @param IConversationInteractionError e
		 * @return Promise<void>
		 */
		protected async Handle_OnError(e: IConversationInteractionError): Promise<void> {
			console.warn('Interaction error');
		}
	}
