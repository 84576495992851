import Store from '@/Store';
import AiChatter from '@/Asset/json/ai-chatter.json';

/**
 * @class Dialogue
 * @package Manager
 * @project BunnyDragon
 */
export class Dialogue {
	/**
	 * @return string
	 */
	public get introChatterForBunny(): string {
		const zodiacKey: string = Store.getters.userZodiacSlug;
		const output: string = (AiChatter as any)[zodiacKey].bunny;
		return output;
	}

	/**
	 * @return string
	 */
	public get introChatterForDragon(): string {
		const zodiacKey: string = Store.getters.userZodiacSlug;
		const output: string = (AiChatter as any)[zodiacKey].dragon;
		return output;
	}
}

export default new Dialogue();
