
	import * as Model from '@/Model';
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';
	import { sleep } from '@/Utility/Helpers';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Conversation/Interaction
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewConversationInteractionColor extends ViewBase {
		/**
		 * @type Model.Interaction
		 */
		@Prop()
		public interactionModel!: Model.Interaction;

		/**
		 * @type string
		 */
		protected backgroundColor: string = '#ccc';

		/**
		 * @type Model.Color
		 */
		protected colorModel!: Model.Color;

		/**
		 * @return Promise<void>
		 */
		public async animateOut(): Promise<void> {
			this.className.push('animate-out');
			await sleep(4500);
		}

		/**
		 * @param Model.Color colorModel
		 * @return Promise<void>
		 */
		protected async Handle_OnSelectColor(color: string): Promise<void> {
			this.backgroundColor = color;
		}

		/**
		 * @param Model.Color colorModel
		 * @return Promise<void>
		 */
		protected async Handle_OnColorModel(colorModel: Model.Color): Promise<void> {
			this.colorModel = colorModel;
		}

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnSubmit(e: PointerEvent): Promise<void> {
			this.$store.state.sphereColor = this.backgroundColor;

			this.$emit('answer', {
				luckyBuddies: this.colorModel.luckyBuddies,
			});
		}
	}
