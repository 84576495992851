
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { Input, Utility } from 'buck-ts';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewCardStack extends ViewBase {
		/**
		 * @type boolean
		 */
		protected isSwapped: boolean = false;

		/**
		 * @return void
		 */
		public swap(): void {
			// Swap cards
			this.isSwapped = !this.isSwapped;

			// Emit which is above
			this.$emit('swap', this.isSwapped ? 'prospect' : 'buddy');
		}

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClick(e: PointerEvent | TouchEvent): Promise<void> {
			this.swap();
		}
	}
