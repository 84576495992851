
	import * as Model from '@/Model';
	import ShapePairs from '@/Asset/json/cloud/pairs';
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { Input, Utility } from 'buck-ts';
	import { assetRef } from '@/Utility/Helpers';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Conversation/Answer
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewConversationAnswerShape extends ViewBase {
		/**
		 * Returns a pair tuple like ['triangle-00', 'star-00']
		 *
		 * @return string[]
		 */
		public get pairNames(): string[] {
			return this.shapePairs.pairs[4];
		}

		/**
		 * Returns a pair tuple like [{ shape... }, { shape.. }]
		 *
		 * @return Record<string, ICloudShape>[]
		 */
		public get pairs(): Record<string, ICloudShape>[] {
			const firstPairName: string = this.interactionModel.option.shape.startType; // this.pairNames[0];
			const secondPairName: string = this.interactionModel.option.shape.endType; // this.pairNames[1];

			console.log(this.interactionModel.option.shape.endType);

			const firstPair: Record<string, ICloudShape> = this.shapePairs.patterns[firstPairName];
			const secondPair: Record<string, ICloudShape> = this.shapePairs.patterns[secondPairName];

			return [firstPair, secondPair];
		}

		/**
		 * @return any
		 */
		private get shapePairs(): any {
			return ShapePairs as any;
		}

		/**
		 * @type Record<string, ICloudShape>
		 */
		public get positionsAfter(): Record<string, ICloudShape> {
			return JSON.parse(JSON.stringify(this.pairs[1]));
		}

		/**
		 * @type string[]
		 */
		public get positionsAfterKeys(): string[] {
			return Object.keys(this.positionsAfter);
		}

		/**
		 * @type Record<string, ICloudShape>
		 */
		public get positionsBefore(): Record<string, ICloudShape> {
			return JSON.parse(JSON.stringify(this.pairs[0]));
		}

		/**
		 * @type string[]
		 */
		public get positionsBeforeKeys(): string[] {
			return Object.keys(this.positionsBefore);
		}

		/**
		 * @type Model.Interaction
		 */
		@Prop()
		public interactionModel!: Model.Interaction;

		/**
		 * @type number
		 */
		@Prop({ default: 0 })
		public ratio!: number;

		/**
		 * @type Record<string, ICloudShape>
		 */
		protected currentPosition!: Record<string, ICloudShape>;

		/**
		 * @type string[]
		 */
		protected bindings: string[] = ['Handle_OnDrag'];

		/**
		 * @type number
		 */
		protected currentRatio: number = 0;

		/**
		 * @type number
		 */
		protected rateOfChange: number = 0.05;

		/**
		 * @return void
		 */
		private interval: any;

		/**
		 * @type Input.Pointer
		 */
		private pointer: Input.Pointer = new Input.Pointer('pointer', true);

		/**
		 * @return void
		 */
		constructor() {
			super();

			this.currentPosition = JSON.parse(JSON.stringify(this.positionsBefore));
		}

		/**
		 * @return void
		 */
		@mounted
		public attachEvents(): void {
			Utility.Interval.add(() => this.Handle_OnInterval(), 1000 / 60, 'grower-update');
			this.pointer.on('drag', this.Handle_OnDrag);
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		public dettachEvents(): void {
			Utility.Interval.remove('grower-update');
			this.pointer.off('drag', this.Handle_OnDrag);
		}

		/**
		 * @param string key
		 * @return string
		 */
		protected getCloudFilename(key: string): string {
			const filename: string = key.replace(/-\d\d$/, '') + '.png';
			const output: string = assetRef(`image/cloud/${filename}`);

			return output;
		}

		/**
		 * @param number ratio
		 * @return ICloudShape
		 */
		protected getInterpolatePositions(ratio: number): Record<string, ICloudShape> {
			const interpolatedPositions: any = {};

			for (const key in this.positionsBefore) {
				const before = this.positionsBefore[key];
				const after = this.positionsAfter[key];
				const rotation = before.rotation + (after.rotation - before.rotation) * ratio;

				interpolatedPositions[key] = {
					height: before.height + (after.height - before.height) * ratio,
					rotation: rotation,
					width: before.width + (after.width - before.width) * ratio,
					x: before.x + (after.x - before.x) * ratio,
					y: before.y + (after.y - before.y) * ratio,
				};
			}

			return interpolatedPositions as Record<string, ICloudShape>;
		}

		/**
		 * @return void
		 */
		protected update(): void {
			for (const key in this.currentPosition) {
				const position = this.currentPosition[key];
				const element = document.querySelector(`.${key}`) as HTMLElement;

				element.style.width = `${position.width}px`;
				element.style.height = `${position.height}px`;
				element.style.transform = `translate(${position.x}px, ${position.y}px) rotate(${position.rotation.toFixed(2)}deg)`;
			}
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnDrag(): Promise<void> {
			const direction = this.pointer.vx > 0 ? 1 : -1;

			// Save current ratio
			this.currentRatio = Math.max(0, Math.min(1, this.currentRatio + 0.01 * direction));

			// Emit answer
			this.$emit('answer', this.currentRatio);
		}

		/**
		 * @return void
		 */
		protected Handle_OnInterval(): void {
			const interpolatedPositions: Record<string, ICloudShape> = this.getInterpolatePositions(this.currentRatio);

			for (const key in this.currentPosition) {
				const position = this.currentPosition[key];
				const interpolatedPosition = interpolatedPositions[key];

				this.currentPosition[key].height += (interpolatedPosition.height - position.height) * this.rateOfChange;
				this.currentPosition[key].rotation += (interpolatedPosition.rotation - position.rotation) * this.rateOfChange;
				this.currentPosition[key].width += (interpolatedPosition.width - position.width) * this.rateOfChange;
				this.currentPosition[key].x += (interpolatedPosition.x - position.x) * this.rateOfChange;
				this.currentPosition[key].y += (interpolatedPosition.y - position.y) * this.rateOfChange;
			}

			// Scale clouds wrapper
			// cloudScaleTarget = 1 + currentRatio;
			// cloudScale += (cloudScaleTarget - cloudScale) * this.rateOfChange;
			// cloudsElement.style.transform = `scale(${cloudScale}) translate(-50%, -50%)`;

			// Update
			this.update();
		}
	}
