import * as Manager from '@/Manager';
import Store from '@/Store';
import { Event } from 'buck-ts';
import { NavigationGuardNext, Route } from 'vue-router';
import { sleep } from '@/Utility/Helpers';

/**
 * Move the global starfield based on the navigation
 *
 * @return void
 */
export async function StarfieldMotion(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
	// Animate to position if didn't just enter the site
	Store.state.starfieldAnimateToPosition = from.name !== null;

	// Events
	Event.Bus.dispatch('motion:start', {
		from: from.name,
		to: to.name,
	});

	// Determine how much to animate
	switch (to.name) {
		case 'PageHome':
			Store.commit('starfieldScrollTo', 0.0);
			break;

		case 'PagePrologue':
			Store.commit('starfieldScrollTo', 0.2);

			if (from.name === 'PageHome') {
				await sleep(1000 * 4);
			}
			break;

		case 'PageBirthdateYear':
			Store.commit('starfieldScrollTo', 0.35);

			if (from.name === 'PagePrologue') {
				await sleep(1000 * 4);
			}

			if (from.name === 'PageBirthdateConfirmation') {
				await sleep(1000 * 3);
			}
			break;

		case 'PageBirthdateMonth':
			Store.commit('starfieldScrollTo', 0.45);

			if (from.name === 'PageBirthdateYear') {
				await sleep(1000 * 2);
			}
			break;

		case 'PageBirthdateDay':
			Store.commit('starfieldScrollTo', 0.55);

			if (from.name === 'PageBirthdateMonth') {
				await sleep(1000 * 2);
			}
			break;

		case 'PageBirthdateConfirmation':
			Store.commit('starfieldScrollTo', 0.55);
			break;

		case 'PageInterlude':
			Store.commit('starfieldScrollTo', 0.7);
			break;

		case 'PageConversation':
		case 'PageConversationItem':
		case 'PageBridge':
			Store.commit('starfieldScrollTo', 0.7);

			if (from.name === 'PageResult') {
				await sleep(1000 * 3.5);
			}
			break;

		case 'PageEpilogue':
		case 'PageResult':
			Store.commit('starfieldScrollTo', 0.0);

			if (from.name === 'PageBridge') {
				await sleep(1000 * 4);
			}
			break;
	}

	// Events
	Event.Bus.dispatch('motion:complete', {
		from: from.name,
		to: to.name,
	});

	// Continue to next middleware
	next();
}
