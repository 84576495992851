import * as Config from '@/Config';
import * as UtilityDate from '@/Utility/Date';
import State from './State';
import gsap from 'gsap';
import { Event } from 'buck-ts';

/**
 * Mutations are a synchronous way of modifying state. They are generally
 * called through Actions. It's best to think of them as setters.
 *
 * @see https://vuex.vuejs.org/guide/mutations.html
 *
 * @class Mutations
 * @package Store
 * @project BunnyDragon
 */
const mutations = {
	/**
	 * @param State state
	 * @param IBirthdate value
	 * @return void
	 */
	birthdate(state: typeof State, value: IBirthdate): void {
		mutations.birthdateDay(state, value.day || 0);
		mutations.birthdateMonth(state, value.month || 0);
		mutations.birthdateYear(state, value.year || 0);
	},

	/**
	 * @param State state
	 * @param number value
	 * @return void
	 */
	birthdateDay(state: typeof State, value: number): void {
		typeof value !== 'number' && (value = parseInt(value));
		state.userBirthdate.day = value;
		mutations.zodiac(state);
	},

	/**
	 * These are stored starting at ZERO.
	 *
	 * @param State state
	 * @param number value
	 * @return void
	 */
	birthdateMonth(state: typeof State, value: number): void {
		typeof value !== 'number' && (value = parseInt(value));
		state.userBirthdate.month = value;
		mutations.zodiac(state);
	},

	/**
	 * @param State state
	 * @param number value
	 * @return void
	 */
	birthdateYear(state: typeof State, value: number): void {
		typeof value !== 'number' && (value = parseInt(value));
		state.userBirthdate.year = value;
		mutations.zodiac(state);
	},

	/**
	 * @param State state
	 * @param ICharacterPose value
	 * @return void
	 */
	characterPose(state: typeof State, value: ICharacterPose): void {
		switch (value.character) {
			case 'bunny':
				state.characterPoseBunny = value.pose;
				break;

			case 'dragon':
				state.characterPoseDragon = value.pose;
				break;
		}
	},

	/**
	 * @param State state
	 * @param string value
	 * @return void
	 */
	entrypointLuckyBuddy(state: typeof State, value: string): void {
		state.entrypointLuckyBuddy = value;
	},

	/**
	 * @param State state
	 * @param string value
	 * @return void
	 */
	entrypointZodiac(state: typeof State, value: string): void {
		state.entrypointZodiac = value;
	},

	/**
	 * @param State state
	 * @param any value
	 * @return void
	 */
	foo(state: typeof State, value: any): void {
		// Not implemented
	},

	/**
	 * @param State state
	 * @param number value
	 * @return void
	 */
	incrementCompleted(state: typeof State, value: number): void {
		state.timesCompleted += value;
	},

	/**
	 * @param State state
	 * @param number value
	 * @return void
	 */
	incrementPlayedPerVisit(state: typeof State, value: number): void {
		state.timesPlayedPerVisit += value;
	},

	/**
	 * @param State state
	 * @param number value
	 * @return void
	 */
	incrementVisit(state: typeof State, value: number): void {
		state.timesVisited += value;
	},

	/**
	 * @param State state
	 * @param IConversationInteractionAnswer answer
	 * @return void
	 */
	interactionAnswer(state: typeof State, answer: IConversationInteractionAnswer): void {
		state.answers.push(answer);
	},

	/**
	 * @param State state
	 * @param number value
	 * @return void
	 */
	interactionAnswerModifier(state: typeof State, value: number): void {
		state.answerModifier = value;
	},

	/**
	 * @param State state
	 * @param boolean value
	 * @return void
	 */
	isMuted(state: typeof State, value: boolean): void {
		state.isMuted = value;
	},

	/**
	 * @param State state
	 * @param string value
	 * @return void
	 */
	languageCode(state: typeof State, value: string): void {
		state.languageCode = value;
	},

	/**
	 * @param State state
	 * @return void
	 */
	resetAnswers(state: typeof State): void {
		state.answers = [];
	},

	/**
	 * @param State state
	 * @param number value
	 * @return void
	 */
	starfieldScrollTo(state: typeof State, value: number): void {
		const startValue = state.starfieldScrollRatio;
		const targetValue = value;

		if (state.starfieldAnimateToPosition) {
			gsap.to(state, {
				duration: Config.App.STARFIELD_MOTION_DURATION,
				ease: 'power2.inOut',
				onUpdate: () => {
					const ratio = (state.starfieldScrollRatio - startValue) / (targetValue - startValue);
					state.currentMotionScrollRatio = ratio;
					Event.Bus.dispatch('motion:scroll', {
						ratio,
					});
				},
				starfieldScrollRatio: value,
			});
		} else {
			state.starfieldScrollRatio = value;
		}
	},

	/**
	 * @param State state
	 * @param string category
	 * @return void
	 */
	winningCategory(state: typeof State, category: string): void {
		state.winningCategory = category;
	},

	/**
	 * @param State state
	 * @param string luckyBuddySlug
	 * @return void
	 */
	winningLuckyBuddy(state: typeof State, luckyBuddySlug: string): void {
		state.winningLuckyBuddy = luckyBuddySlug;
	},

	/**
	 * @param State state
	 * @return void
	 */
	zodiac(state: typeof State): void {
		const output = UtilityDate.getZodiacElementFromDate(
			state.userBirthdate.year || Config.App.DEFAULT_BIRTHDATE_YEAR,
			(state.userBirthdate.month || 0) + 1,
			state.userBirthdate.day || 1,
		);

		state.userZodiacElement = output.element;
		state.userZodiacSign = output.sign;
		state.userZodiac = `${output.element} ${output.sign}`;
	},
};

export default mutations;
