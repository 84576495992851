import Store from '@/Store';
import { NavigationGuardNext, Route } from 'vue-router';

/**
 * @return void
 */
export function Birthdate(to: Route, from: Route, next: NavigationGuardNext): void {
	// Only if we're going to results
	if (to.name === 'PageBirthdateConfirmation') {
		Store.state.userBirthdateRedo = true;
	}

	// Reset date on year. Hacky way of waiting for animation out
	if (to.name === 'PageBirthdateYear') {
		setTimeout(() => {
			Store.dispatch('resetBirthdate');
		}, 1000 * 2);
	}

	// Continue to next middleware
	next();
}
