
	import * as Model from '@/Model';
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewSpeechBubble extends ViewBase {
		/**
		 * @return string
		 */
		public get text(): string {
			return this.dialogueModel.text;
		}

		/**
		 * @return string
		 */
		public get type(): string {
			return (this.dialogueModel.character || 'bunny') as string;
		}

		/**
		 * @type Model.Dialogue
		 */
		@Prop()
		public dialogueModel!: Model.Dialogue;

		/**
		 * @type number
		 */
		@Prop({ default: 50 })
		protected speed!: number;

		/**
		 * @type string
		 */
		public displayText: string = '';

		/**
		 *
		 *
		 * @return void
		 */
		@mounted
		protected animateTypewriter(): void {
			// mk: Disabled for now until we come up with a new animation
			// this.typewriter(this.speed);

			// Default text into place
			this.displayText = this.text;

			// Animate out test
			// setTimeout(() => {
			// 	this.$el.classList.add('animate-out');
			// }, 3000);
		}

		/**
		 * @param string value
		 */
		protected get size(): string {
			const length = this.text.length;

			if (length < 20) {
				return 'tiny';
			} else if (length < 40) {
				return 'small';
			} else if (length < 55) {
				return 'mid';
			} else {
				return 'large';
			}
		}

		/**
		 * @param number speed
		 * @return Promise<void>
		 */
		protected typewriter(speed: number = 100): Promise<void> {
			this.displayText = '';

			return new Promise((resolve) => {
				let i = 0;

				const interval = setInterval(() => {
					if (i < this.text.length) {
						this.displayText += this.text.charAt(i);
						i++;
					} else {
						clearInterval(interval);
						resolve();
					}
				}, speed);
			});
		}
	}
