
	import * as Collection from '@/Collection';
	import * as Model from '@/Model';
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import { Event, Input } from 'buck-ts';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';
	import { sleep } from '@/Utility/Helpers';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Conversation
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewConversationDialogue extends ViewBase {
		/**
		 * @return Collection.Dialogue
		 */
		public get dialogueCollection(): Collection.Dialogue {
			return this.useResponses ? this.interactionModel.randomResponse() : this.interactionModel.dialogue;
		}

		/**
		 * @type boolean
		 */
		@Prop({ default: false })
		public autoplay!: boolean;

		/**
		 * @type boolean
		 */
		@Prop({ default: false })
		public clamp!: boolean;

		/**
		 * @type number
		 */
		@Prop({ default: 2500 })
		public duration!: number;

		/**
		 * @type Model.Interaction
		 */
		@Prop()
		public interactionModel!: Model.Interaction;

		/**
		 * @type boolean
		 */
		@Prop({ default: false })
		public useResponses!: boolean;

		/**
		 * @type string[]
		 */
		protected bindings: string[] = ['Handle_OnClick'];

		/**
		 * @type number
		 */
		protected currentIndex: number = 0;

		/**
		 * @type boolean
		 */
		protected isComplete: boolean = false;

		/**
		 * @type Input.Pointer
		 */
		protected pointer: Input.Pointer = new Input.Pointer('pointer');

		/**
		 * @return void
		 */
		@mounted
		public attachEvents(): void {
			this.pointer.on('down', this.Handle_OnClick);
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		public detachEvents(): void {
			this.pointer.off('down', this.Handle_OnClick);
		}

		/**
		 * @return void
		 */
		@mounted
		public async syncFirst(): Promise<void> {
			this.sync();
		}

		/**
		 * @return Promise<void>
		 */
		@mounted
		public async startAutoplay(): Promise<void> {
			// Don't autoplay
			if (!this.autoplay) {
				return;
			}

			// Do next
			await sleep(this.duration);

			// Iterate next
			this.advance();

			// Loop through all
			if (this.hasNext()) {
				this.startAutoplay();
			} else {
				this.complete();
			}
		}

		/**
		 * @return void
		 */
		public next(): void {
			this.currentIndex = this.clamp ? Math.min(this.currentIndex + 1, this.dialogueCollection.length - 1) : this.currentIndex + 1;
		}

		/**
		 * Show the next one. If there's no more, wait a tick and trigger event
		 *
		 * @return void
		 */
		protected async advance(): Promise<void> {
			// Trigger event
			if (!this.hasNext()) {
				this.complete();
			}

			// Always increment so the last dialogue will exit
			this.next();

			// Sync
			this.sync();
		}

		/**
		 * @return void
		 */
		protected complete(): void {
			// Don't do more
			if (this.isComplete) {
				return;
			}

			// Detach
			this.detachEvents();

			// Tell everyone we're done
			this.$emit('complete');

			// Don't run this twice by accident
			this.isComplete = true;
		}

		/**
		 * @return boolean
		 */
		protected hasNext(): boolean {
			return this.currentIndex < this.dialogueCollection.length - 1;
		}

		/**
		 * @return void
		 */
		protected sync(): void {
			// Trigger action about this model
			if (this.dialogueCollection.length > this.currentIndex) {
				this.$store.dispatch('dialogue', this.dialogueCollection.at(this.currentIndex));
				this.$emit('dialogue', this.dialogueCollection.at(this.currentIndex));
			}
		}

		/**
		 * @param any e
		 * @return Promise<void>
		 */
		protected async Handle_OnClick(e: any): Promise<void> {
			if (this.isComplete) {
				return;
			}

			// Move onward
			this.advance();
		}

		/**
		 * @return Model.Interaction
		 */
		@Watch('$route', {
			deep: true,
			immediate: true,
		})
		protected async Handle_OnRouteChange(to: any, from: any): Promise<void> {
			this.sync();
		}

		/**
		 * @return Model.Interaction
		 */
		@Watch('useResponses')
		protected async Handle_OnResponsesChange(to: any, from: any): Promise<void> {
			this.sync();
		}
	}
