import Store from '@/Store';
import { NavigationGuardNext, Route } from 'vue-router';

/**
 * Catches the entrypoint zodiac and stores it in the Vuex store
 *
 * @return void
 */
export function EntrypointZodiac(to: Route, from: Route, next: NavigationGuardNext): void {
	const entrypointLuckyBuddy = to.query['lucky-buddy']?.toString() || '';
	const entrypointZodiac = to.query.zodiac?.toString() || '';

	// If we came from somewhere else, mark it down
	if (entrypointZodiac || entrypointLuckyBuddy) {
		Store.dispatch('setEntrypointLuckyBuddy', entrypointLuckyBuddy);
		Store.dispatch('setEntrypointZodiac', entrypointZodiac);

		// Remove query string from url
		const newUrl = to.path;
		next(newUrl);
		return;
	}

	// Continue to next middleware
	next();
}
