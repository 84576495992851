/* eslint-disable */

// German translation
export default {
	copy_birthdate_confirmday: 'Tag bestätigen',
	copy_birthdate_confirmmonth: 'Monat bestätigen',
	copy_birthdate_confirmyear: 'Jahr bestätigen',
	copy_birthdate_dragtoselect: 'Zum Auswählen Datum ziehen',
	copy_confirmation_birthdate_header: 'Mit einem Geburtsdatum am',
	copy_confirmation_youarea: 'Du bist ein',
	copy_general_day: 'Tag',
	copy_general_month: 'Monat',
	copy_general_year: 'Jahr',
	copy_landscape_tiltphone: 'Bitte neigen Sie Ihr Telefon.',
	copy_welcome_enterbirthday: 'Bitte geburtsdatum eingben',
	copy_welcome_touchtobegin: `Um zu beginnen, <strong>berühren</strong> Sie das Universum`,

	month_january: 'Januar',
	month_february: 'Februar',
	month_march: 'März',
	month_april: 'April',
	month_may: 'Mai',
	month_june: 'Juni',
	month_july: 'Juli',
	month_august: 'August',
	month_september: 'September',
	month_october: 'Oktober',
	month_november: 'November',
	month_december: 'Dezember',

	zodiac_element_wood: 'Holz',
	zodiac_element_fire: 'Feuer',
	zodiac_element_earth: 'Erde',
	zodiac_element_metal: 'Metall',
	zodiac_element_water: 'Wasser',

	zodiac_sign_rat: 'Ratte',
	zodiac_sign_ox: 'Ochse',
	zodiac_sign_tiger: 'Tiger',
	zodiac_sign_rabbit: 'Hase',
	zodiac_sign_dragon: 'Drache',
	zodiac_sign_snake: 'Schlange',
	zodiac_sign_horse: 'Pferd',
	zodiac_sign_goat: 'Ziege',
	zodiac_sign_monkey: 'Affe',
	zodiac_sign_rooster: 'Hahn',
	zodiac_sign_dog: 'Hund',
	zodiac_sign_pig: 'Schwein',

	ordinal_first: 'er',
	ordinal_second: 'er',
	ordinal_third: 'er',
	ordinal_suffix: 'er',
};
