import * as Collection from '@/Collection';
import * as Config from '@/Config';
import * as Core from '@/Core';
import * as Model from '@/Model';

/**
 * @class LuckyBuddy
 * @package Model
 * @project BunnyDragon
 */
export class LuckyBuddy extends Core.Model {
	/**
	 * @return string[]
	 */
	public get categories(): string[] {
		return this.attributes.categories as string[];
	}

	/**
	 * @return string[]
	 */
	public get details(): string[] {
		return this.attributes.details as string[];
	}

	/**
	 * @return string
	 */
	public get name(): string {
		return this.attr('name') as string;
	}

	/**
	 * @return string
	 */
	public get slug(): string {
		return this.attr('slug') as string;
	}

	/**
	 * @return string[]
	 */
	public get tags(): string[] {
		return this.attributes.tags as string[];
	}
}
