var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"view-birthdate-selector",class:_vm.className,attrs:{"type":_vm.type,"value":_vm.getValueNearestPercentage(_vm.nearestPercentage)},on:{"scroll":_vm.Handle_OnScroll}},[_c('section',{staticClass:"motionpath"},[_c('div',{staticClass:"dates"},_vm._l((_vm.endValue - _vm.startValue + 1),function(diff,index){return _c('div',{key:diff,staticClass:"dot",class:{
					'state-active': _vm.value === _vm.startValue + diff - 1,
				},style:({
					'offset-distance': _vm.calculateItemPosition(index) + '%',
					'opacity': _vm.calculateItemOpacity(index),
					'filter': 'blur(' + _vm.calculateItemBlur(index) + 'px)',
				})},[_vm._v(" "+_vm._s(_vm.getCaptionByValue(_vm.startValue + diff - 1))+" ")])}),0)]),_c('div',{staticClass:"scroller",style:({ height: _vm.maxScroll + 'px' })})])
}
var staticRenderFns = []

export { render, staticRenderFns }