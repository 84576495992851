import * as Collection from '@/Collection';
import * as Model from '@/Model';
import InteractionsJson from '@/Asset/json/interactions';

/**
 * @return Model.Interaction
 */
export function fromDialogue(dialogueItems: any): Model.Interaction {
	const interaction = new Model.Interaction({
		dialogue: dialogueItems,
	});

	return interaction;
}

/**
 * @param string tag
 * @return Model.Interaction[]
 */
export function getByTag(tag: string): Model.Interaction[] {
	const collection = Collection.Interaction.hydrate(InteractionsJson.data);
	return collection.getByTag(tag);
}

/**
 * @param string tag
 * @return Model.Interaction[]
 */
export function getResponse(tag: string): Model.Interaction[] {
	const collection = Collection.Interaction.hydrate(InteractionsJson.data);
	return [collection.at(3)];
}
