import * as Collection from '@/Collection';
import * as Core from '@/Core';
import * as Factory from '@/Factory';
import * as Model from '@/Model';

/**
 * @class Dialogue
 * @package Model
 * @project BunnyDragon
 */
export class MultipleChoice extends Core.Model {
	/**
	 * @return string[]
	 */
	public get categories(): string[] {
		return this.attributes.categories as string[];
	}

	/**
	 * @return string
	 */
	public get extendedText(): string {
		return this.attributes.extendedText as string;
	}

	/**
	 * @return string
	 */
	public get shortText(): string {
		return this.attributes.shortText as string;
	}

	// region: Relationships
	// ---------------------------------------------------------------------------

	/**
	 * NOTE: This is a special relationship. Ordinarily we handle relationships
	 * through data attributes, but in this case we're modifying a data
	 * element to create a custom relationship.
	 *
	 * @return Model.LuckyBuddy[]
	 */
	public get luckyBuddies(): Model.LuckyBuddy[] {
		const ids = this.attributes.luckyBuddies as string[];
		const collection: Collection.LuckyBuddy = Factory.LuckyBuddy.collection();

		return collection.filter((buddyModel: Model.LuckyBuddy) => (ids || []).includes(buddyModel.id));
	}

	// endregion: Relationships
}
