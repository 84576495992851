var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{key:_vm.$store.state.languageCode,class:[
		_vm.$store.state.showGlobalAbout ? 'state-show-about' : '',
		_vm.$store.state.showGlobalFooter ? 'state-show-footer' : '',
		_vm.$store.state.showGlobalHeader ? 'state-show-header' : '',
		_vm.$store.state.userBirthdate.year % 2 == 0 ? 'year-even' : 'year-odd',
		_vm.$store.state.userBirthdate.year % 2 == 0 ? 'yang' : 'yin',
		_vm.$store.state.hasGyroscope ? 'gyro' : 'no-gyro',
		_vm.$store.state.optimizePerformance ? 'mode-performance' : 'mode-quality',
		_vm.$store.state.isIos ? 'os-ios' : 'os-other',
		'from-' + _vm.$store.state.entrypointLuckyBuddy,
		'from-' + _vm.$store.state.entrypointZodiac,
		'orientation-' + _vm.$store.state.deviceOrientation,
		'zodiac-element-' + _vm.$store.getters.userZodiacElementSlug,
		'zodiac-sign-' + _vm.$store.getters.userZodiacSignSlug,
	],attrs:{"id":"app"},on:{"click":_vm.Handle_OnClickApp}},[_c('section',{staticClass:"page-container-secondary"},[_c('PageLandscapeUnavailable')],1),_c('section',{staticClass:"page-container-primary"},[_c('ViewGlobalHeader'),_c('transition',{attrs:{"name":"fade"}},[_c('router-view')],1),_c('ViewGlobalFooter'),_c('ViewGlobalAbout'),_c('ViewGlobalStarfield'),(_vm.$store.state.showLanguageSelection)?_c('ViewLanguageSelection'):_vm._e()],1),(_vm.config.Environment.IS_LOCAL)?_c('section',{staticClass:"debug"},[_c('ViewDebug')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }