import * as BUCK from 'buck-ts';
import * as Config from '@/Config';

/**
 * @class Model
 * @package Core
 * @project BunnyDragon
 */
export abstract class Model extends BUCK.Model.Base {
	/**
	 * @type string
	 */
	public baseUrl: string = Config.App.SERVER_URL;

	/**
	 * @return Date | null
	 */
	public get createdAt(): Date | null {
		const dateString: string = this.attr('created_at') as string;
		return dateString ? new Date(dateString) : null;
	}

	/**
	 * @return Date | null
	 */
	public get updatedAt(): Date | null {
		const dateString: string = this.attr('updated_at') as string;
		return dateString ? new Date(dateString) : null;
	}
}
