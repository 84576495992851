
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop, Ref } from 'vue-property-decorator';
	import { Utility } from 'buck-ts';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Global
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewGlobalStarfield extends ViewBase {
		/**
		 * @type number
		 */
		// protected scrollHeight: number = window.innerHeight * 4;
		protected scrollHeight: number = window.innerHeight * 8;
	}
