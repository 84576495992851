import * as Factory from '@/Factory';
import * as Model from '@/Model';

/**
 * mk: this could use some work
 *
 * @author Matt Kenefick <matt.kenefick@buck.co>
 * @package Utility
 * @project BunnyDragon
 */
export default class AnswersResolver {
	/**
	 * @param IConversationInteractionAnswer[] answers
	 * @return IAnswerResolverResult
	 */
	public static resolve(answers: IConversationInteractionAnswer[]): IAnswerResolverResult {
		// const categories = this.categorizeAnswers(answers);
		// const bestCategory = this.findBestCategory(categories);
		const buddies = this.categorizeBuddies(answers);
		const bestBuddy = this.findBestBuddy(buddies);
		const luckyBuddy = this.findBestBuddyObject(answers, bestBuddy);
		const bestCategory = luckyBuddy.categories[0];

		// Get buddy by bestBuddy slug
		console.log('bestBuddy', bestBuddy);
		console.log('luckyBuddy', luckyBuddy);

		return {
			bestBuddy,
			bestCategory,
		};
	}

	// /**
	//  * @param IConversationInteractionAnswer[] answers
	//  * @return Record<string, number>
	//  */
	// private static categorizeAnswers(answers: IConversationInteractionAnswer[]): Record<string, number> {
	// 	const categories: Record<string, number> = {};

	// 	answers.forEach((answer) => {
	// 		answer.luckyBuddies.forEach((buddyModel: Model.LuckyBuddy) => {
	// 			buddyModel.categories.forEach((category) => {
	// 				categories[category] = (categories[category] || 0) + 1;
	// 			});
	// 		});
	// 	});

	// 	return categories;
	// }

	// /**
	//  * @param Record<string, number> categories
	//  * @return string
	//  */
	// private static findBestCategory(categories: Record<string, number>): string {
	// 	const keys = Object.keys(categories);

	// 	return keys ? keys.reduce((a, b) => (categories[a] > categories[b] ? a : b)) : '';
	// }

	/**
	 * @param IConversationInteractionAnswer[] answers
	 * @return Record<string, number>
	 */
	private static categorizeBuddies(answers: IConversationInteractionAnswer[]): Record<string, number> {
		const buddies: Record<string, number> = {
			'last-stick-of-gum': 0,
		};

		answers.forEach((answer) => {
			answer.luckyBuddies.forEach((buddyModel: Model.LuckyBuddy) => {
				buddies[buddyModel.slug] = (buddies[buddyModel.slug] || 0) + 1;
			});
		});

		return buddies;
	}

	/**
	 * @param Record<string, number> buddies
	 * @return string
	 */
	private static findBestBuddy(buddies: Record<string, number>): string {
		const keys = Object.keys(buddies);

		return keys ? keys.reduce((a, b) => (buddies[a] > buddies[b] ? a : b)) : '';
	}

	/**
	 * @param IConversationInteractionAnswer[] answers
	 * @param string slug
	 * @return Model.LuckyBuddy
	 */
	private static findBestBuddyObject(answers: IConversationInteractionAnswer[], slug: string): Model.LuckyBuddy {
		if (!answers || !answers.length) {
			return Factory.LuckyBuddy.collection().at(0);
		}

		for (const answer of answers) {
			for (const buddy of answer.luckyBuddies) {
				if (buddy.slug === slug) {
					return buddy;
				}
			}
		}

		return answers[0]?.luckyBuddies[0];
	}
}
