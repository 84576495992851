
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { Input } from 'buck-ts';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Graphic
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewGraphicTapGesture extends ViewBase {
		/**
		 * @type number
		 */
		@Prop({ default: 4 })
		public delay!: number;

		/**
		 * @type Input.Pointer
		 */
		protected pointer: Input.Pointer = new Input.Pointer('pointer', true);

		/**
		 * @type boolean
		 */
		protected wasTapped: boolean = false;

		/**
		 * @return Promise<void>
		 */
		@mounted
		public async waitForInteraction(): Promise<void> {
			setTimeout(() => {
				if (this.wasTapped) {
					return;
				}

				this.$el.classList.add('animate-in', 'will-animate-out');
			}, 1000 * this.delay);
		}

		/**
		 * @return void
		 */
		@mounted
		protected attachEvents(): void {
			this.pointer.on('down', () => (this.wasTapped = true));
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		protected detachEvents(): void {
			this.pointer.off('down');
		}
	}
