import * as Config from '@/Config';
import * as Manager from '@/Manager';
import * as Model from '@/Model';
import AnswerResolver from '@/Utility/AnswerResolver';
import { ActionContext } from 'vuex';
import { IStoreState } from './State';

/**
 * @type ActionContext<S, R>
 */
type Context = ActionContext<IStoreState, IStoreState>;

/**
 * Actions are generally meant to be the entrypoint for modifying state.
 *
 * They are asynchronous calls that can perform other tasks and then
 * trigger a mutation (synchronous).
 *
 * @see https://vuex.vuejs.org/guide/actions.html
 *
 * @class Actions
 * @package Store
 * @project BunnyDragon
 */
const actions = {
	/**
	 * @todo work on this
	 *
	 * @param Context context
	 * @return void
	 */
	calculateAnswers(context: Context): void {
		const { bestCategory, bestBuddy } = AnswerResolver.resolve(context.state.answers);

		// Save to state
		context.commit('winningCategory', bestCategory);
		context.commit('winningLuckyBuddy', bestBuddy);

		// Debug
		console.log('❖ Calculate answers', context.state.answers, bestCategory, bestBuddy);
	},

	/**
	 * @param Context context
	 * @param Model.Dialogue dialogueModel
	 * @return void
	 */
	dialogue(context: Context, dialogueModel: Model.Dialogue): void {
		// Set the pose to state
		if (dialogueModel.pose && dialogueModel.character) {
			context.commit('characterPose', {
				character: dialogueModel.character,
				pose: dialogueModel.pose,
			});
		}

		// Check if we have characterPoses
		if (dialogueModel.characterPoses) {
			for (const characterType in dialogueModel.characterPoses) {
				context.commit('characterPose', {
					character: characterType,
					pose: dialogueModel.characterPoses[characterType],
				});
			}
		}
	},

	/**
	 * @param Context context
	 * @param boolean value
	 * @return void
	 */
	foo(context: Context, value: boolean): void {
		context.commit('foo', value);
	},

	/**
	 * @param Context context
	 * @param boolean value
	 * @return void
	 */
	incrementCompleted(context: Context): void {
		context.commit('incrementCompleted', 1);
	},

	/**
	 * @param Context context
	 * @param boolean value
	 * @return void
	 */
	incrementPlayedPerVisit(context: Context): void {
		context.commit('incrementPlayedPerVisit', 1);
	},

	/**
	 * @param Context context
	 * @param boolean value
	 * @return void
	 */
	incrementVisit(context: Context): void {
		context.commit('incrementVisit', 1);
	},

	/**
	 * @param Context context
	 * @param IConversationInteractionAnswer answer
	 * @return void
	 */
	interactionAnswer(context: Context, answer: IConversationInteractionAnswer): void {
		context.commit('interactionAnswer', answer);

		console.log('🔹 Saving answer', answer);
	},

	/**
	 * @param Context context
	 * @param number value
	 * @return void
	 */
	interactionAnswerModifier(context: Context, value: number): void {
		context.commit('interactionAnswerModifier', value);

		console.log('🔹 Saving answer modifier', value);
	},

	/**
	 * @param Context context
	 * @param IConversationInteractionAnswer answer
	 * @return void
	 */
	resetAnswers(context: Context): void {
		context.commit('resetAnswers');

		console.log('🔸 Resetting answers');
	},

	/**
	 * @param Context context
	 * @return void
	 */
	resetBirthdate(context: Context): void {
		context.commit('birthdate', {
			day: Config.App.DEFAULT_BIRTHDATE_DAY,
			month: Config.App.DEFAULT_BIRTHDATE_MONTH,
			year: Config.App.DEFAULT_BIRTHDATE_YEAR,
		});
	},

	/**
	 * @param Context context
	 * @param IBirthdate birthdate
	 * @return void
	 */
	setBirthdate(context: Context, birthdate: IBirthdate): void {
		context.commit('birthdate', birthdate);
	},

	/**
	 * @param Context context
	 * @param string luckyBuddy
	 * @return void
	 */
	setEntrypointLuckyBuddy(context: Context, luckyBuddy: string): void {
		context.commit('entrypointLuckyBuddy', luckyBuddy);
	},

	/**
	 * @param Context context
	 * @param string zodiac
	 * @return void
	 */
	setEntrypointZodiac(context: Context, zodiac: string): void {
		context.commit('entrypointZodiac', zodiac);
	},

	/**
	 * @param Context context
	 * @param string languageCode
	 * @return void
	 */
	setLanguage(context: Context, languageCode: string): void {
		context.commit('languageCode', languageCode);

		console.log('language', languageCode);
	},

	/**
	 * @param Context context
	 */
	toggleAudio(context: Context): void {
		if (Config.App.ENABLE_SOUND === false) {
			console.warn('🔇 Sound is disabled');
			return;
		}

		// Stop if playing
		if (!context.state.isMuted) {
			Manager.Audio.stopBackgroundMusic();
		} else {
			Manager.Audio.playBackgroundMusic();
			context.state.hasAudioPlayed = true;
		}

		// Toggle state
		context.commit('isMuted', !context.state.isMuted);
	},
};

export default actions;
