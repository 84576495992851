import * as Collection from '@/Collection';
import * as Config from '@/Config';
import * as Core from '@/Core';
import * as Model from '@/Model';
import * as Factory from '@/Factory';

/**
 * @class Interaction
 * @package Model
 * @project BunnyDragon
 */
export class Interaction extends Core.Model {
	/**
	 * e.g. 'text', 'emoji'
	 *
	 * @return string
	 */
	public get responseType(): string {
		return this.attributes.responseType as string;
	}

	/**
	 * e.g. ['text', 'round-01']
	 *
	 * @return string[]
	 */
	public get tags(): string[] {
		return this.attributes.tags as string[];
	}

	/**
	 * e.g. MultipleChoice
	 *
	 * @return string
	 */
	public get type(): string {
		return this.attr('type') as string;
	}

	/**
	 * @return Collection.Dialogue
	 */
	public randomResponse(): Collection.Dialogue {
		const responses: any = this.attr('responses') || [];
		const at: number = Math.floor(Math.random() * responses.length);

		return Collection.Dialogue.hydrate(responses[at]);
	}

	// region: Relationships
	// ---------------------------------------------------------------------------

	/**
	 * @return Collection.Dialogue
	 */
	public get dialogue(): Collection.Dialogue {
		return this.hasMany<Collection.Dialogue>('dialogue', Collection.Dialogue);
	}

	/**
	 * Calling this "option" because of a conflict with "options" in super class
	 *
	 * e.g. option.choices
	 *
	 * @return Model.InteractionOptions
	 */
	public get option(): Model.InteractionOptions {
		return this.hasOne<Model.InteractionOptions>('options', Model.InteractionOptions);
	}

	/**
	 * @return Collection.Dialogue
	 */
	public response(at: number): Collection.Dialogue {
		const responses: any = this.attr('responses') || [];
		return Collection.Dialogue.hydrate(responses[at]);
	}

	// endregion: Relationships
}
