/**
 * @type enum
 */
export enum CharmCategory {
	GENERAL = 'General',
	HEALTH = 'Health',
	WEALTH = 'Wealth',
	CAREER = 'Career',
	LOVE = 'Love',
}
