
	/* eslint-disable */
	// @ts-nocheck

	import ViewBase from '@/View/Base.vue';
	import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @return void
	 */
	@Component
	export default class ViewBirthdateParticlesAlt extends ViewBase {
		@Ref('particleCanvas') readonly particleCanvas!: HTMLCanvasElement;

		private particles: any[] = [];
		private particleCount: number = 3000;
		private P0!: { x: number; y: number };
		private P1!: { x: number; y: number };
		private P2!: { x: number; y: number };
		private ctx!: CanvasRenderingContext2D | null;

		/**
		 * @return void
		 */
		@mounted
		private setup(): void {
			this.ctx = this.particleCanvas.getContext('2d');
			if (!this.ctx) return;

			this.particleCanvas.width = window.innerWidth;
			this.particleCanvas.height = window.innerHeight;

			// Define the Bezier curve control points
			// this.P0 = {
			// 	x: -300,
			// 	y: this.particleCanvas.height,
			// };

			// this.P1 = {
			// 	x: this.particleCanvas.width / 2,
			// 	y: -300,
			// };

			// this.P2 = {
			// 	x: this.particleCanvas.width,
			// 	y: this.particleCanvas.height - 200,
			// };

			// P0 (Start Point): This is the beginning of the Bezier curve. In your code, it's set to { x: 100, y: canvas.height / 2 }, which places it 100 pixels from the left edge of the canvas and vertically centered. This is where the curve starts.

			// P1 (Control Point): Unlike the start and end points, the control point does not lie on the curve itself. Instead, it "pulls" the curve towards itself, determining its bend. In your code, P1 is { x: canvas.width / 2, y: 100 }, placing it at the center of the canvas width-wise and 100 pixels down from the top of the canvas. This high position forces the curve to arc upwards.

			// P2 (End Point): This marks the end of the Bezier curve. Your code sets it to { x: canvas.width - 100, y: canvas.height / 2 }, placing it 100 pixels from the right edge of the canvas and vertically centered, mirroring P0 but on the opposite side. This is where the curve ends.

			this.P0 = {
				x: this.particleCanvas.width / 1.5,
				y: 0,
			};

			this.P1 = {
				x: this.particleCanvas.width,
				y: this.particleCanvas.height,
			};

			this.P2 = {
				x: -1000,
				y: this.particleCanvas.height,
			};

			this.initParticles();
			this.animate();
		}

		/**
		 * @return void
		 */
		private createParticle(i: number) {
			const t = i / this.particleCount;
			const speed = 0.0005 + Math.random() * (Math.random() > 0.5 ? 0.0019 : 0.0049);
			const length = 1 + Math.random() * (Math.random() > 0.75 ? 60 : 10);
			const width = 0.25 + Math.random() * 2;
			let offset = (Math.random() - 0.5) * 80;

			if (Math.random() < 0.2) {
				offset += 50;
			}

			if (Math.random() > 0.8) {
				offset -= 50;
			}

			return {
				length,
				offset,
				speed,
				t,
				width,
			};
		}

		/**
		 * @return void
		 */
		private initParticles() {
			for (let i = 0; i < this.particleCount; i++) {
				this.particles.push(this.createParticle(i));
			}
		}

		/**
		 * @return void
		 */
		private updateParticle(particle: any) {
			if (!this.ctx) return;

			particle.t += particle.speed;

			if (particle.t > 1) {
				particle.t %= 1;
			}

			const x = Math.pow(1 - particle.t, 2) * this.P0.x + 2 * (1 - particle.t) * particle.t * this.P1.x + Math.pow(particle.t, 2) * this.P2.x;
			const y = Math.pow(1 - particle.t, 2) * this.P0.y + 2 * (1 - particle.t) * particle.t * this.P1.y + Math.pow(particle.t, 2) * this.P2.y;

			const dx = 2 * (1 - particle.t) * (this.P1.x - this.P0.x) + 2 * particle.t * (this.P2.x - this.P1.x);
			const dy = 2 * (1 - particle.t) * (this.P1.y - this.P0.y) + 2 * particle.t * (this.P2.y - this.P1.y);

			const length = Math.sqrt(dx * dx + dy * dy);
			const nx = -dy / length;
			const ny = dx / length;

			const offsetX = x + particle.offset * nx;
			const offsetY = y + particle.offset * ny;

			particle.x = offsetX;
			particle.y = offsetY;
			particle.angle = Math.atan2(dy, dx);
		}

		/**
		 * @return void
		 */
		private animate() {
			if (!this.ctx) return;
			if (!this.particleCanvas) return;

			this.ctx.clearRect(0, 0, this.particleCanvas.width, this.particleCanvas.height);

			this.particles.forEach((particle: any) => {
				this.updateParticle(particle);

				this.ctx.save();
				this.ctx.translate(particle.x, particle.y);
				this.ctx.rotate(particle.angle);
				this.ctx.beginPath();
				this.ctx.ellipse(0, 0, particle.length / 2, particle.width / 2, 0, 0, 2 * Math.PI);
				this.ctx.fillStyle = '#fff';
				this.ctx.fill();
				this.ctx.restore();
			});

			requestAnimationFrame(this.animate.bind(this));
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		private cleanup() {
			window.removeEventListener('resize', this.handleResize);
		}

		/**
		 * @return void
		 */
		private handleResize = () => {
			if (!this.particleCanvas) return;

			this.particleCanvas.width = window.innerWidth;
			this.particleCanvas.height = window.innerHeight;
			this.P0.y = this.particleCanvas.height / 2;
			this.P1.x = this.particleCanvas.width / 2;
			this.P2.y = this.particleCanvas.height / 2;
		};

		/**
		 * @return void
		 */
		@mounted
		public borkmounted() {
			window.addEventListener('resize', this.handleResize);
		}
	}
