import * as Config from '@/Config';
import Store from '@/Store';
import { Event } from 'buck-ts';
import { NavigationGuardNext, Route } from 'vue-router';

/**
 * Determine when we're supposed to complete the conversation.
 *
 * @return void
 */
export function ConversationCounter(to: Route, from: Route, next: NavigationGuardNext): void {
	const questionNumber: number = parseInt(to.params.questionNumber || '1');

	// mk: TEMPORARILY ALLOW
	// Check if we're on result with no answers
	if (!Config.Environment.IS_LOCAL) {
		if (to.name === 'PageResult' && !Store.state.answers.length) {
			next({
				name: 'PageConversation',
			});

			return;
		}
	}

	// If not on the Conversation page, ignore the rest of this middleware
	if (to.name !== 'PageConversationItem') {
		next();
		return;
	}

	// Check if conversation :questionNumber is above max questions
	if (questionNumber > Config.App.MAX_QUESTIONS) {
		Store.dispatch('calculateAnswers');

		next({
			name: 'PageBridge',
		});

		return;
	}

	// If we're going to the first one, reset our answers
	if (questionNumber === 1) {
		Store.dispatch('resetAnswers');
	}

	// Bus
	Event.Bus.dispatch('conversation:next');

	// Continue to next middleware
	next();
}
