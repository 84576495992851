
	import * as Manager from '@/Manager';
	import ViewBase from '@/View/Base.vue';
	import { Component } from 'vue-property-decorator';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Button
	 * @project BunnyDragon
	 */
	@Component
	export default class ViewButtonConfirmDay extends ViewBase {
		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClick(e: PointerEvent): Promise<void> {
			// Play sound effect
			Manager.Audio.playBirthdateButton();
		}
	}
