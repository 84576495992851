
	import * as UtilityDate from '@/Utility/Date';
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { assetRef } from '@/Utility/Helpers';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Birthdate
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewBirthdateConfirmation extends ViewBase {
		/**
		 * @return string
		 */
		protected get zodiacImage(): string {
			return assetRef('image/zodiac/' + this.$store.state.userZodiacSign?.toLowerCase() + '.png');
		}
	}
