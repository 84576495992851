
	import ViewBase from '@/View/Base.vue';
	import { Component } from 'vue-property-decorator';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Button
	 * @project BunnyDragon
	 */
	@Component
	export default class ViewButtonDownload extends ViewBase {
		// Not implemented
	}
