
	import ViewBase from '@/View/Base.vue';
	import { Component } from 'vue-property-decorator';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewLanguageSelection extends ViewBase {
		/**
		 * @param string languageCode
		 * @return Promise<void>
		 */
		protected async Handle_OnSelectLanguage(languageCode: string): Promise<void> {
			this.$store.state.showLanguageSelection = false;
			this.$store.dispatch('setLanguage', languageCode);
		}
	}
