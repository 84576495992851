
	import * as Model from '@/Model';
	import ViewBase from '@/View/Base.vue';
	import gsap from 'gsap';
	import { Component, Prop } from 'vue-property-decorator';
	import { Input, Utility } from 'buck-ts';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Conversation/Answer
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewConversationAnswerColorSelector extends ViewBase {
		/**
		 * @return string[]
		 */
		public get hexColors(): string[] {
			return this.interactionModel.option.colors.getHexColors();
		}

		/**
		 * @type string[]
		 */
		protected bindings: string[] = ['Handle_OnDrag'];

		/**
		 * @type Model.Interaction
		 */
		@Prop()
		public interactionModel!: Model.Interaction;

		/**
		 * @type number
		 */
		protected value: number = 0;

		/**
		 * @type Input.Pointer
		 */
		private pointer: Input.Pointer = new Input.Pointer('pointer', true);

		/**
		 * @return void
		 */
		@mounted
		public attachEvents(): void {
			this.pointer.on('drag', this.Handle_OnDrag);
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		public dettachEvents(): void {
			this.pointer.off('drag', this.Handle_OnDrag);
		}

		/**
		 * @return void
		 */
		@mounted
		protected async selectColor(): Promise<void> {
			await gsap.fromTo(
				this,
				{
					value: 0.3,
				},
				{
					duration: 1,
					ease: 'power2.inOut',
					onUpdate: this.emitAnswer,
					value: 0.75,
				},
			);

			await gsap.to(this, {
				duration: 1,
				ease: 'power2.inOut',
				onUpdate: this.emitAnswer,
				value: 0.3,
			});

			await gsap.to(this, {
				duration: 1,
				ease: 'power2.inOut',
				onUpdate: this.emitAnswer,
				value: 0.75,
			});
		}

		/**
		 * @return void
		 */
		protected emitAnswer(): void {
			const index: number = Math.floor(this.value * (this.hexColors.length - 1));

			this.$emit('selectColor', this.hexColors[index]);
			this.$emit('answer', this.getSelectedColorModel());
		}

		/**
		 * @return Model.Color
		 */
		public getSelectedColorModel(): Model.Color {
			const index: number = Math.floor(this.value * (this.hexColors.length - 1));

			return this.interactionModel.option.colors.at(index);
		}

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickColorStack(e: PointerEvent | TouchEvent): Promise<void> {
			const x: number = 'touches' in e ? e.touches[0].clientX : e.clientX;
			const ratio: number = x / (window.innerWidth - window.innerWidth / this.hexColors.length);
			this.value = ratio;

			this.emitAnswer();
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnDrag(): Promise<void> {
			const ratio: number = this.pointer.x / (window.innerWidth - window.innerWidth / this.hexColors.length);
			this.value = ratio;

			this.emitAnswer();
		}
	}
