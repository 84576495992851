
	import ViewBase from '@/View/Base.vue';
	import { Component } from 'vue-property-decorator';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Button
	 * @project BunnyDragon
	 */
	@Component
	export default class ViewButtonToggleAudio extends ViewBase {
		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClick(e: PointerEvent): Promise<void> {
			e.preventDefault();
			e.stopPropagation();
			e.stopImmediatePropagation();

			this.$store.dispatch('toggleAudio');
		}
	}
