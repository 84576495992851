import Store from '@/Store';
import { NavigationGuardNext, Route } from 'vue-router';

/**
 * Count the amount of times the user has visited the site
 *
 * @return void
 */
export function AccessCounter(to: Route, from: Route, next: NavigationGuardNext): void {
	// No name should indicate that we were nowhere before this
	if (!from?.name && from.path === '/') {
		Store.dispatch('incrementVisit');
	}

	// Continue to next middleware
	next();
}
