
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop, Ref } from 'vue-property-decorator';
	import { Utility } from 'buck-ts';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewStars extends ViewBase {
		/**
		 * @return CanvasRenderingContext2D
		 */
		protected get context(): CanvasRenderingContext2D {
			return this.starCanvas.getContext('2d')!;
		}

		/**
		 * @type number
		 */
		@Prop({ default: 0.6 })
		protected maxStarRatio!: number;

		/**
		 * @type string[]
		 */
		protected bindings: string[] = ['Handle_OnResize'];

		/**
		 * @type HTMLCanvasElement
		 */
		@Ref('starCanvas')
		protected starCanvas!: HTMLCanvasElement;

		/**
		 * @type number
		 */
		protected starAmount: number = 1000;

		/**
		 * @type number
		 */
		protected stars: any = [];

		/**
		 * @return void
		 */
		@mounted
		public attachEvents(): void {
			Utility.Interval.add(() => this.Handle_OnTwinkle(), 1000 / 15, 'starfield-twinkle');
			Utility.Interval.add(() => this.update(), 1000 / 60, 'starfield');
			window.addEventListener('resize', this.Handle_OnResize);
			this.Handle_OnResize(new Event('resize'));
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		public detachEvents(): void {
			Utility.Interval.remove('starfield-twinkle');
			Utility.Interval.remove('starfield');
			window.removeEventListener('resize', this.Handle_OnResize);
		}

		/**
		 * @return void
		 */
		@mounted
		protected createStars(): void {
			const colors: number[][] = [
				[100, 100, 150],
				[100, 100, 100],
				[31, 70, 116],
			];

			// Reset stars
			this.stars = [];

			// Create stars
			for (let i = 0; i < this.starAmount; i++) {
				const color: number[] = colors[Math.floor(Math.random() * colors.length)];
				const x: number = Math.random() * this.starCanvas.width;
				const y: number = Math.random() * this.starCanvas.height * 3;

				this.stars.push({
					alpha: 0,
					baseAlpha: Math.random() * 0.5 + 0.25,
					color: color,
					ox: x,
					oy: y,
					radius: Math.random() * 1.5,
					x: x,
					y: y,
				});
			}
		}

		/**
		 * @return void
		 */
		protected drawStarfield(): void {
			this.context.clearRect(0, 0, this.starCanvas.width, this.starCanvas.height);

			for (let i = 0; i < this.stars.length; i++) {
				const star = this.stars[i];
				this.context.beginPath();
				this.context.fillStyle = `rgba(${star.color[0]}, ${star.color[1]}, ${star.color[2]}, ${star.alpha})`;
				this.context.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
				this.context.fill();
			}
		}

		/**
		 * @return void
		 */
		protected updateStarfield(): void {
			for (let i = 0; i < this.stars.length; i++) {
				const star = this.stars[i];

				// Move stars as we scroll
				// There are four sections to the Starfield, so window.innerHeight * 2 brings
				// us to the second section
				star.y = star.oy - this.$store.state.starfieldScrollRatio * (window.innerHeight * 2);

				// Change alpha
				star.alpha = star.baseAlpha;
			}
		}

		/**
		 * @return void
		 */
		protected update(): void {
			this.drawStarfield();
			this.updateStarfield();
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnTwinkle(): Promise<void> {
			const amountToChange = 50;

			// Don't do this if we're in the mountain area
			if (this.$store.state.starfieldScrollRatio > this.maxStarRatio) {
				return;
			}

			// Start twinkling stars
			for (let i = 0; i < amountToChange; i++) {
				const randomIndex = Math.floor(Math.random() * this.stars.length);
				const valueBefore = this.stars[randomIndex].baseAlpha;

				// Set new value
				this.stars[randomIndex].baseAlpha = valueBefore + Math.random() * 0.15;

				// Return value
				setTimeout(() => (this.stars[randomIndex].baseAlpha = valueBefore), 1000);
			}
		}

		/**
		 * @param Event e
		 * @return Promise<void>
		 */
		protected async Handle_OnResize(e: Event): Promise<void> {
			this.starCanvas.height = window.innerHeight;
			this.starCanvas.width = window.innerWidth;

			// Create new stars on resize
			this.createStars();
		}
	}
