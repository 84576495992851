import * as Collection from '@/Collection';
import * as Core from '@/Core';
import { Shape } from '@/Model/Shape';

/**
 * @class InteractionOptions
 * @package Model
 * @project BunnyDragon
 */
export class InteractionOptions extends Core.Model {
	// region: Relationships
	// ---------------------------------------------------------------------------

	/**
	 * @return Collection.MultipleChoice
	 */
	public get choices(): Collection.MultipleChoice {
		return this.hasMany<Collection.MultipleChoice>('choices', Collection.MultipleChoice);
	}

	/**
	 * @return Collection.Color
	 */
	public get colors(): Collection.Color {
		return this.hasMany<Collection.Color>('colors', Collection.Color);
	}

	/**
	 * @return Shape
	 */
	public get shape(): Shape {
		return this.hasOne<Shape>('shape', Shape);
	}

	// endregion: Relationships
}
