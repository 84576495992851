/* eslint-disable */

// Spanish translation
export default {
	copy_birthdate_confirmday: 'Confirmar día',
	copy_birthdate_confirmmonth: 'Confirmar mes',
	copy_birthdate_confirmyear: 'Confirmar año',
	copy_birthdate_dragtoselect: 'Arrastra para seleccionar la fecha',
	copy_confirmation_birthdate_header: 'Con una fecha de nacimiento en',
	copy_confirmation_youarea: 'Eres un',
	copy_general_day: 'Día',
	copy_general_month: 'Mes',
	copy_general_year: 'Año',
	copy_landscape_tiltphone: 'Por favor, incline su teléfono.',
	copy_welcome_enterbirthday: 'Introduce mi cumpleaños',
	copy_welcome_touchtobegin: `Para comenzar, <strong>toque</strong> el cosmos`,

	month_january: 'Enero',
	month_february: 'Febrero',
	month_march: 'Marzo',
	month_april: 'Abril',
	month_may: 'Mayo',
	month_june: 'Junio',
	month_july: 'Julio',
	month_august: 'Agosto',
	month_september: 'Septiembre',
	month_october: 'Octubre',
	month_november: 'Noviembre',
	month_december: 'Diciembre',

	zodiac_element_wood: 'Madera',
	zodiac_element_fire: 'Fuego',
	zodiac_element_earth: 'Tierra',
	zodiac_element_metal: 'Metal',
	zodiac_element_water: 'Agua',

	zodiac_sign_rat: 'Rata',
	zodiac_sign_ox: 'Buey',
	zodiac_sign_tiger: 'Tigre',
	zodiac_sign_rabbit: 'Conejo',
	zodiac_sign_dragon: 'Dragón',
	zodiac_sign_snake: 'Serpiente',
	zodiac_sign_horse: 'Caballo',
	zodiac_sign_goat: 'Cabra',
	zodiac_sign_monkey: 'Mono',
	zodiac_sign_rooster: 'Gallo',
	zodiac_sign_dog: 'Perro',
	zodiac_sign_pig: 'Cerdo',

	ordinal_first: 'º',
	ordinal_second: 'º',
	ordinal_third: 'º',
	ordinal_suffix: 'º',
};
