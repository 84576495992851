/**
 * @class ManagerSocket
 * @package Manager
 * @project BunnyDragon
 */
export class ManagerHaptics {
	/**
	 * @return Record<string, string>
	 */
	public vibrate(durations: number[] = [500]): void {
		// Check if navigator has vibrate
		if (navigator.vibrate) {
			navigator.vibrate(durations);
		}
	}
}

export default new ManagerHaptics();
