import * as Config from '@/Config';
import * as Middleware from '@/Middleware';
import Store from '@/Store';
import Vue from 'vue';
import VueRouter, { NavigationGuard, RawLocation, Route, RouteConfig } from 'vue-router';

// Attach VueRouter
// -----------------------------------------------------------------------------

Vue.use(VueRouter);

// Define Routing
// -----------------------------------------------------------------------------

const routes: Array<RouteConfig> = [
	// {
	// 	component: () => import('@/Page/ComingSoon.vue'),
	// 	name: 'PageComingSoon',
	// 	path: '/',
	// },
	{
		component: () => {
			return !!Config.App.SHOW_COMINGSOON ? import('@/Page/ComingSoon.vue') : import('@/Page/Home.vue');
		},
		name: 'PageHome',
		path: '/',
	},
	{
		component: () => import('@/Page/Prologue.vue'),
		name: 'PagePrologue',
		path: '/prologue',
	},
	{
		component: () => import('@/Page/CardTiltTest.vue'),
		name: 'sfsdfasdf',
		path: '/card-tilt-test',
	},
	{
		name: 'PageBirthdate',
		path: '/birthdate',
		redirect: '/birthdate/year',
	},
	{
		component: () => import('@/Page/Birthdate.vue'),
		name: 'PageBirthdateConfirmation',
		path: '/birthdate/confirmation',
	},
	{
		component: () => import('@/Page/Birthdate/Year.vue'),
		name: 'PageBirthdateYear',
		path: '/birthdate/year',
	},
	{
		component: () => import('@/Page/Birthdate/Month.vue'),
		name: 'PageBirthdateMonth',
		path: '/birthdate/month',
	},
	{
		component: () => import('@/Page/Birthdate/Day.vue'),
		name: 'PageBirthdateDay',
		path: '/birthdate/day',
	},
	{
		component: () => import('@/Page/Interlude.vue'),
		name: 'PageInterlude',
		path: '/interlude',
	},
	{
		name: 'PageConversation',
		path: '/conversation',
		redirect: '/conversation/1',
	},
	{
		component: () => import('@/Page/Conversation.vue'),
		name: 'PageConversationItem',
		path: '/conversation/:questionNumber',
	},
	{
		component: () => import('@/Page/Bridge.vue'),
		name: 'PageBridge',
		path: '/bridge',
	},
	{
		component: () => import('@/Page/Epilogue.vue'),
		name: 'PageEpilogue',
		path: '/epilogue',
	},
	{
		component: () => import('@/Page/Result.vue'),
		name: 'PageResult',
		path: '/result',
	},
	{
		component: () => import('@/Page/NotFound.vue'),
		name: 'PageNotFound',
		path: '*',
	},
];

// Setup Router
// -----------------------------------------------------------------------------

const router = new VueRouter({
	base: process.env.BASE_URL,
	mode: 'history',
	routes: routes,
});

// Middleware hooks
// ---------------------------------------------------------------------------

const middlewares: NavigationGuard[] = [
	Middleware.AccessCounter,
	Middleware.Birthdate,
	Middleware.CompletionCounter,
	Middleware.EntrypointZodiac,
	Middleware.ConversationCounter,
	Middleware.StarfieldMotion,
];

middlewares.forEach((middleware: NavigationGuard) => router.beforeEach(middleware));

// Override push and replace
// mk: Reject removed because it throws the visual error
// -----------------------------------------------------------------------------

type ErrorHandler = (err: Error) => void;
const originalPush: any = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function (to: RawLocation): Promise<Route> {
	return new Promise((resolve: any, reject: ErrorHandler) => {
		originalPush.call(this, to, resolve); // , reject
	});
};

VueRouter.prototype.replace = function (to: RawLocation): Promise<Route> {
	return new Promise((resolve: any, reject: ErrorHandler) => {
		originalReplace.call(this, to, resolve); // , reject
	});
};

export default router;
