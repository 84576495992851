
	import * as Manager from '@/Manager';
	import * as UtilityDate from '@/Utility/Date';
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Birthdate
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewBirthdateDisplay extends ViewBase {
		/**
		 * @return string
		 */
		public get day(): string {
			return this.$store.state.userBirthdate.day || Manager.Language.get('copy_general_day');
		}

		/**
		 * We do +1 here because we're doing inclusive months even though
		 * JS usually uses 0
		 *
		 * @return string
		 */
		public get month(): string {
			return UtilityDate.getMonthName(this.$store.state.userBirthdate.month) || Manager.Language.get('copy_general_month');
		}

		/**
		 * @return string
		 */
		public get year(): string {
			return this.$store.state.userBirthdate.year || Manager.Language.get('copy_general_year');
		}
	}
