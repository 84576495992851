import * as Config from '@/Config';
import * as Manager from '@/Manager';
import State from './State';
import { isWindows } from '@/Utility/Helpers';

/**
 * Getters are used to calculate values based off state. They are generally
 * used for filtering or formatting data.
 *
 * @class Getters
 * @package Store
 * @project BunnyDragon
 */
const getters = {
	/**
	 * Format: card-monkey-career-treasure-map-d0-o2.png
	 *
	 * @param typeof State state
	 * @return string
	 */
	cardFilename(state: typeof State): string {
		const userZodiacSign: string = state.userZodiacSign || 'dragon';
		const category: string = state.winningCategory || 'health';
		const luckyBuddy: string = state.winningLuckyBuddy || 'overripe-banana';
		const directory: string = 'd0';
		const orbKey: string = getters.orbKeyForFilename(state);
		const output = `card-${userZodiacSign}-${category}-${luckyBuddy}-${directory}-${orbKey}.jpg`;

		return output.toLowerCase();
	},

	/**
	 * Determine if we have passed the age gate
	 *
	 * @return boolean
	 */
	foo(state: typeof State): boolean {
		return !!state.foo;
	},

	/**
	 * @param State state
	 * @return boolean
	 */
	isMuted(state: typeof State): boolean {
		return state.isMuted;
	},

	/**
	 * @param State state
	 * @return boolean
	 */
	isWindows(state: typeof State): boolean {
		return isWindows();
	},

	/**
	 * @return string
	 */
	luckyBuddyCardUrl(state: typeof State): string {
		return `${Config.App.CARD_URL_BASE}/${getters.cardFilename(state)}`;
	},

	/**
	 * @param typeof State state
	 * @return string
	 */
	orbKeyForFilename(state: typeof State): string {
		if (state.answerModifier === 0) {
			return 'o1';
		} else if (state.answerModifier === 10) {
			return 'o2';
		} else if (state.answerModifier === 50) {
			return 'o3';
		} else if (state.answerModifier === 100) {
			return 'o4';
		}

		return 'o5';
	},

	/**
	 * @return string
	 */
	prospectCardUrl(state: typeof State): string {
		return `${Config.App.CARD_URL_BASE}/${getters.prospectFilename(state)}`;
	},

	/**
	 * Format: prospect-monkey-career.png
	 *
	 * @param typeof State state
	 * @return string
	 */
	prospectFilename(state: typeof State): string {
		const userZodiacSign: string = state.userZodiacSign || 'dragon';
		const category: string = state.winningCategory || 'health';
		const output = `prospect-${userZodiacSign}-${category}.jpg`;

		return output.toLowerCase();
	},

	/**
	 * @return string
	 */
	shareableText(state: typeof State): string {
		const output: string = Manager.Language.get('shareable_description');
		return output;
	},

	/**
	 * @return string
	 */
	shareableTitle(state: typeof State): string {
		const output: string = Manager.Language.get('shareable_title');
		return output;
	},

	/**
	 * @todo look at analytics here
	 *
	 * @return string
	 */
	shareableUrl(state: typeof State): string {
		const url: URL = new URL(location.origin);
		url.searchParams.set('zodiac', state.userZodiacElement + '-' + state.userZodiacSign);
		url.searchParams.set('lucky-buddy', state.winningLuckyBuddy);

		return url.toString().toLowerCase();
	},

	/**
	 * @param State state
	 * @return boolean
	 */
	useTiltableCards(state: typeof State): boolean {
		return !!Config.App.ENABLE_TILT;
	},

	/**
	 * @return string
	 */
	userZodiac(state: typeof State): string {
		return `${state.userZodiacElement} ${state.userZodiacSign}`;
	},

	/**
	 * @return string
	 */
	userZodiacElement(state: typeof State): string {
		return state.userZodiacElement;
	},

	/**
	 * e.g. water, wood, fire, earth, metal
	 *
	 * @return string
	 */
	userZodiacElementSlug(state: typeof State): string {
		const element = state.userZodiacElement || '';
		return element.toLowerCase();
	},

	/**
	 * e.g. rat, ox, tiger, rabbit, dragon, snake, horse, goat, monkey, rooster, dog, pig
	 *
	 * @return string
	 */
	userZodiacSignSlug(state: typeof State): string {
		const sign = state.userZodiacSign || '';
		return sign.toLowerCase();
	},

	/**
	 * @return string
	 */
	userZodiacSlug(state: typeof State): string {
		return `${getters.userZodiacElementSlug(state)}_${getters.userZodiacSignSlug(state)}`;
	},
};

export default getters;
