
	import * as Model from '@/Model';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';
	import { Component, Prop } from 'vue-property-decorator';
	import ViewBase from '@/View/Base.vue';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewConversationAnswerEmojiImage extends ViewBase {
		/**
		 * @return string
		 */
		@Prop()
		public multipleChoiceModel!: Model.MultipleChoice;

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClick(e: PointerEvent): Promise<void> {
			e.preventDefault();

			this.$emit('select', this.multipleChoiceModel);
		}
	}
