
	/* eslint-disable */
	// @ts-nocheck

	import ViewBase from '@/View/Base.vue';
	import { Component, Prop, Ref } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';
	import { gsap } from 'gsap';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Birthdate
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewBirthdateParticles extends ViewBase {
		/**
		 * @type SVGElement
		 */
		@Ref('svgElement')
		public svgElement!: SVGElement;

		/**
		 * @type number
		 */
		protected spread: number = 50;

		/**
		 * @type number
		 */
		protected val: any = { distance: 0 };

		/**
		 * @return void
		 */
		@mounted
		public setup(): void {
			const fuse = this.svgElement.querySelector('.fuse') as SVGPathElement;

			// Create a tween
			gsap.to(this.val, {
				distance: fuse.getTotalLength(),
				duration: 2,
				onUpdate: () => {
					const point = fuse.getPointAtLength(this.val.distance);

					for (let i = 0; i < 100; i++) this.createParticle(point);
				},
				repeat: -1,
				repeatDelay: 1,
			});
		}

		/**
		 * @return void
		 */
		protected createParticle(point) {
			const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
			this.svgElement.prepend(circle);

			// Set the coordinates of that circle
			circle.setAttribute('cx', point.x + Math.random() * this.spread);
			circle.setAttribute('cy', point.y + Math.random() * this.spread);

			// Define a random radius for each circle
			circle.setAttribute('r', Math.random() + 0.1);

			// Define a random color
			circle.setAttribute('fill', gsap.utils.random(['#ffffff', '#cccccc', '#29368c', '#202859', '#2f3870']));

			// Set opacity low
			circle.setAttribute('opacity', 0.5);

			// Animate the circle
			gsap.to(circle, {
				// Prevent gsap from rounding the cx & cy values
				autoRound: false,
				cx: `+=random(-${this.spread / 2},${this.spread / 2})`,
				cy: `+=random(-${this.spread},${this.spread})`,
				duration: 'random(1, 5)',
				onComplete: () => {
					this.svgElement.removeChild(circle);
				},
				opacity: 0,
			});
		}
	}
