import * as Collection from '@/Collection';
import * as Factory from '@/Factory';
import * as Model from '@/Model';

/**
 * @class ManagerSocket
 * @package Manager
 * @project BunnyDragon
 */
export class LuckyBuddy {
	/**
	 * @type Collection.LuckyBuddy
	 */
	public collection: Collection.LuckyBuddy = Factory.LuckyBuddy.collection();

	/**
	 * @param string category
	 * @return Model.LuckyBuddy[]
	 */
	public getByCategory(category: string): Model.LuckyBuddy[] {
		return this.collection.getByCategory(category);
	}

	/**
	 * @param string tag
	 * @return Model.LuckyBuddy[]
	 */
	public getByTag(tag: string): Model.LuckyBuddy[] {
		return this.collection.getByTag(tag);
	}
}

// Default export
export default new LuckyBuddy();
