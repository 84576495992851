import * as Config from '@/Config';
import actions from './Actions';
import getters from './Getters';
import mutations from './Mutations';
import state from './State';
import Persist from '@/Store/Persist';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

// Attach Vuex
// -----------------------------------------------------------------------------

Vue.use(Vuex);

// Setup Persist
// -----------------------------------------------------------------------------

const persistPlugin = new VuexPersist(
	Object.assign(Persist.options, {
		key: Config.App.STORAGE_KEY,
		reducer: (state: IKeyValue) => ({
			languageCode: state.languageCode,
			timesCompleted: state.timesCompleted,
			timesVisited: state.timesVisited,
		}),
	}),
);
const plugins = [persistPlugin.plugin];

// Setup Store
// -----------------------------------------------------------------------------

const store = new Vuex.Store({
	/**
	 * Triggerable actions
	 * e.g. store.dispatch('increment');
	 */
	actions,

	/**
	 * Calculated values based off state
	 * e.g. store.getters.doubleCount;
	 */
	getters,

	/**
	 * Mutations are a way of modifying state indirectly
	 * e.g. store.commit('increment', 1);
	 */
	mutations,

	/**
	 * Vuex Plugin for persisting data across sessions
	 */
	plugins,

	/**
	 * Raw object of our current state
	 */
	state,
});

export default store;
