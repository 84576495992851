import bunny00 from './bunny-00.json';
import bunny01 from './bunny-01.json';
import cluster00 from './cluster-00.json';
import dispersed00 from './dispersed-00.json';
import dragon00 from './dragon-00.json';
import dragon01 from './dragon-01.json';
import heart00 from './heart-00.json';
import star00 from './star-00.json';
import star01 from './star-01.json';
import triangle00 from './triangle-00.json';

export default {
	pairs: [
		['triangle00', 'star00'],
		['star01', 'heart00'],
		['cluster00', 'dispersed00'],
		['dragon00', 'dragon01'],
		['bunny00', 'bunny01'],
	],
	patterns: {
		bunny00: bunny00,
		bunny01: bunny01,
		cluster00: cluster00,
		dispersed00: dispersed00,
		dragon00: dragon00,
		dragon01: dragon01,
		heart00: heart00,
		star00: star00,
		star01: star01,
		triangle00: triangle00,
	},
};
