triangle-00
star-00

star-01
heart-00

cluster-00
dispersed-00

dragon-00
dragon-01

bunny-00
bunny-01
