
	import ViewBase from '@/View/Base.vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';
	import { checkGyroscopeAccess } from '@/Utility/Helpers';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewTiltableCard extends ViewBase {
		/**
		 * @type string
		 */
		@Prop({
			default: 'https://d2oma53q453rud.cloudfront.net/output/card-rat-health-overripe-banana-d0-o1.jpg',
		})
		public cardUrl!: string;

		/**
		 * @type number
		 */
		private MAX_TILT_X: number = 15;

		/**
		 * @type number
		 */
		private MAX_TILT_Y: number = 15;

		/**
		 * @type string
		 */
		private cardRarity: string = 'common holo';

		/**
		 * @type number
		 */
		private degreesX: number = 0;

		/**
		 * @type number
		 */
		private degreesY: number = 0;

		/**
		 * @type number
		 */
		private ratioX: number = 0;

		/**
		 * @type number
		 */
		private ratioY: number = 0;

		/**
		 * @type number
		 */
		private initialTiltX: number | null = null;

		/**
		 * @type number
		 */
		private initialTiltY: number | null = null;

		/**
		 * @type boolean
		 */
		private isDevice: boolean = false;

		/**
		 * @return Record<string, string>
		 */
		get cardStyle() {
			const maxPointer = 80;
			let pointerX;
			let pointerY;

			// Subtracting puts the glare in the opposite direction
			// Works on desktop. To check on mobile.
			if (!this.isDevice) {
				pointerX = `${maxPointer - this.ratioX * maxPointer}%`;
				pointerY = `${maxPointer - this.ratioY * maxPointer}%`;
			} else {
				pointerX = `${this.ratioY * maxPointer}%`;
				pointerY = `${this.ratioX * maxPointer}%`;
			}

			return {
				'--pointer-x': pointerX,
				'--pointer-y': pointerY,
				'--rotate-x': `${this.degreesX}deg`,
				'--rotate-y': `${this.degreesY}deg`,
			};
		}

		/**
		 * @return void
		 */
		@mounted
		protected setupCard() {
			checkGyroscopeAccess((hasGyroAccess) => {
				hasGyroAccess ? this.setupTilt() : this.setupPointer();
			});
		}

		/**
		 * @return void
		 */
		private setupTilt() {
			this.isDevice = true;
			window.addEventListener('deviceorientation', this.handleDeviceOrientation, true);
		}

		/**
		 * @return void
		 */
		private handleDeviceOrientation(e: DeviceOrientationEvent) {
			const { beta: x, gamma: y } = e;

			if (this.initialTiltX === null || this.initialTiltY === null) {
				this.initialTiltX = x;
				this.initialTiltY = y;
			}

			this.calculateTilt({
				x,
				y,
			});
		}

		/**
		 * @return void
		 */
		private calculateTilt({ x, y }: { x: number | null; y: number | null }): void {
			if (x === null || y === null) return;

			let tiltX = x - (this.initialTiltX ?? 0);
			let tiltY = y - (this.initialTiltY ?? 0);

			tiltX = Math.max(Math.min(tiltX, this.MAX_TILT_X), -this.MAX_TILT_X);
			tiltY = Math.max(Math.min(tiltY, this.MAX_TILT_Y), -this.MAX_TILT_Y);

			this.degreesX = tiltX;
			this.degreesY = tiltY * -1;
		}

		/**
		 * mk: Clean this up
		 *
		 * @param PointerEvent e
		 * @return void
		 */
		private setupPointer() {
			window.addEventListener('pointermove', (e: PointerEvent) => {
				const { clientX, clientY } = e;

				const width = window.innerWidth;
				const height = window.innerHeight;

				this.ratioX = clientX / width;
				this.ratioY = clientY / height;

				this.degreesX = (this.ratioX - 0.5) * this.MAX_TILT_X;
				this.degreesY = (this.ratioY - 0.5) * -this.MAX_TILT_Y;
			});
		}
	}
