
	import ViewBase from '@/View/Base.vue';
	import { Component } from 'vue-property-decorator';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewIntro extends ViewBase {
		/**
		 * @type boolean
		 */
		protected skipAhead: boolean = false;

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClick(e: PointerEvent): Promise<void> {
			e.preventDefault();

			this.skipAhead = true;
		}

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickNavigate(e: PointerEvent): Promise<void> {
			e.preventDefault();
			e.stopPropagation();
			e.stopImmediatePropagation();

			this.animateOut();

			this.$router.push({ name: 'PageBirthdate' });
		}
	}
