
	import ViewBase from '@/View/Base.vue';
	import { Component } from 'vue-property-decorator';
	import { Event } from 'buck-ts';
	import { beforeDestroy, created, mounted } from '@/Utility/Decorators';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project BunnyDragon
	 */
	@Component
	export default class PageBase extends ViewBase {
		/**
		 * @type string[]
		 */
		protected bindings: string[] = ['Handle_OnMotionStart'];

		/**
		 * @return Promise<void>
		 */
		@mounted
		public async animateInStart(): Promise<void> {
			setTimeout(() => this.animateIn(), 50);
		}

		/**
		 * @return void
		 */
		@mounted
		public attachEvents(): void {
			Event.Bus.on('motion:start', this.Handle_OnMotionStart);
		}

		/**
		 * @return void
		 */
		@beforeDestroy
		public detachEvents(): void {
			Event.Bus.off('motion:start', this.Handle_OnMotionStart);
		}

		/**
		 * @return Promise<void>
		 */
		public async animateIn(): Promise<void> {
			this.className.push('animate-in');
			this.className = this.className.filter((className: string) => className !== 'animate-out');

			// Async await animateOut on all children
			await Promise.all(this.$children.map((child: any) => child.animateIn && child.animateIn()));
		}

		/**
		 * @return Promise<void>
		 */
		public async animateOut(): Promise<void> {
			this.className.push('animate-out');
			this.className = this.className.filter((className: string) => className !== 'animate-in');

			// Async await animateOut on all children
			await Promise.all(this.$children.map((child: any) => child.animateOut && child.animateOut()));
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnMotionStart(e: any): Promise<void> {
			// Do nothing
		}
	}
