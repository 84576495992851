import * as Core from '@/Core';
import * as Model from '@/Model';

/**
 * @class LuckyBuddy
 * @package Collection
 * @project BunnyDragon
 */
export class LuckyBuddy extends Core.Collection<Model.LuckyBuddy> {
	/**
	 * @type Model.LuckyBuddy
	 */
	public model: Model.LuckyBuddy = new Model.LuckyBuddy();

	/**
	 * @param string category
	 * @return Model.LuckyBuddy[]
	 */
	public getByCategory(category: string): Model.LuckyBuddy[] {
		return this.filter((luckyBuddy: Model.LuckyBuddy) => {
			return luckyBuddy.categories.includes(category.trim().toLowerCase());
		});
	}

	/**
	 * @param string tag
	 * @return Model.LuckyBuddy[]
	 */
	public getByTag(tag: string): Model.LuckyBuddy[] {
		return this.filter((luckyBuddy: Model.LuckyBuddy) => {
			const tags: string[] = luckyBuddy.tags || [];
			return tags.includes(tag.trim().toLowerCase());
		});
	}
}
