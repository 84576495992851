
	import * as Config from '@/Config';
	import ViewBase from '@/View/Base.vue';
	import ViewBirthdateSelector from '@/View/Birthdate/Selector.vue';
	import ViewGraphicBirthdayHelper from '@/View/Graphic/BirthdayHelper.vue';
	import { Component, Prop, Ref } from 'vue-property-decorator';
	import { beforeDestroy, mounted } from '@/Utility/Decorators';
	import { sleep } from '@/Utility/Helpers';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package View/Birthdate
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class ViewBirthdateSelectYear extends ViewBase {
		/**
		 * @type ViewBirthdateSelector
		 */
		@Ref('birthdaySelector')
		private birthdaySelector!: ViewBirthdateSelector;

		/**
		 * @type ViewGraphicBirthdayHelper
		 */
		@Ref('birthdayHelper')
		private birthdayHelper!: ViewGraphicBirthdayHelper;

		/**
		 * @return number
		 */
		public get year(): string {
			return this.$store.state.userBirthdate.year;
		}

		/**
		 * @type string
		 */
		public set year(value: string) {
			this.$store.commit('birthdateYear', parseInt(value));
		}

		/**
		 * @type boolean
		 */
		protected enableSelector: boolean = true;

		/**
		 * @type number
		 */
		protected maxYear: number = new Date().getFullYear();

		/**
		 * @type number
		 */
		protected minYear: number = 1940;

		/**
		 * @type boolean
		 */
		protected showHelper: boolean = false;

		/**
		 * @return void
		 */
		@mounted
		protected async scrollExample(): Promise<void> {
			// Don't use helper
			if (this.$store.state.userBirthdateRedo) {
				this.showHelper = false;
				this.birthdaySelector.scrollToApproximateIndex(0, 1, Config.App.BIRTHDATE_DEMO_DURATION, true);
				return;
			}

			// Show the helper immediately if we're allowed to
			this.showHelper = true;

			// Then animate it out after a couple seconds
			setTimeout(() => (this.showHelper = false), 1000 * 3);

			// Check if the selector is available
			if (this.birthdaySelector) {
				this.birthdayHelper.swipe();
				await this.birthdaySelector.scrollToApproximateIndex(0, 1, Config.App.BIRTHDATE_DEMO_DURATION, true);
				await this.birthdaySelector.scrollToApproximateIndex(1, 2, Config.App.BIRTHDATE_DEMO_DURATION, true);
			}
		}

		/**
		 * @param PointerEvent e
		 * @return Promise<void>
		 */
		protected async Handle_OnClickConfirm(e: PointerEvent): Promise<void> {
			// Disable scroll
			this.enableSelector = false;

			// Animate this view out
			this.animateOut();
		}
	}
