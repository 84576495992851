var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"view-global-starfield",class:[_vm.className],attrs:{"id":"globalStarfield"}},[_c('div',{staticClass:"background",style:({
			height: `${_vm.scrollHeight}px`,
			transform: `translateY(-${this.$store.state.starfieldScrollRatio * 100}%)`,
		})},[_c('section',{staticClass:"nothingness"}),_c('section',{staticClass:"space"},[_vm._m(0),(_vm.$store.state.sphereColor)?_c('div',{staticClass:"colored-ball",style:({ backgroundColor: _vm.$store.state.sphereColor })}):_vm._e()]),_vm._m(1),_vm._m(2),_c('section',{staticClass:"land"})]),_c('ViewStars',{attrs:{"maxStarRatio":"0.6"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('figure',{staticStyle:{"bottom":"5vh","left":"55vw"}},[_c('img',{attrs:{"src":require("@/Asset/image/starfield-alt/sun.png"),"height":"500"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"clouds"},[_c('figure',{staticClass:"cloud",staticStyle:{"left":"60vw","top":"5vh"}},[_c('img',{attrs:{"src":require("@/Asset/image/starfield-alt/small/cloud-1.png")}})]),_c('figure',{staticClass:"cloud",staticStyle:{"left":"25vw","top":"25vh"}},[_c('img',{attrs:{"src":require("@/Asset/image/starfield-alt/small/cloud-2.png")}})]),_c('figure',{staticClass:"cloud",staticStyle:{"left":"60vw","top":"30vh"}},[_c('img',{attrs:{"src":require("@/Asset/image/starfield-alt/small/cloud-3.png")}})]),_c('figure',{staticClass:"cloud",staticStyle:{"left":"30vw","top":"65vh"}},[_c('img',{attrs:{"src":require("@/Asset/image/starfield-alt/small/cloud-4.png")}})]),_c('figure',{staticClass:"cloud",staticStyle:{"left":"70vw","top":"70vh"}},[_c('img',{attrs:{"src":require("@/Asset/image/starfield-alt/small/cloud-5.png")}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"mountains"},[_c('figure',{staticClass:"mountain",staticStyle:{"left":"53vw","top":"30vh"}},[_c('img',{attrs:{"src":require("@/Asset/image/starfield-alt/small/mountain-a.webp")}})]),_c('figure',{staticClass:"mountain",staticStyle:{"left":"69vw","top":"38vh"}},[_c('img',{attrs:{"src":require("@/Asset/image/starfield-alt/small/mountain-b.webp")}})]),_c('figure',{staticClass:"mountain",staticStyle:{"left":"43vw","top":"67vh"}},[_c('img',{attrs:{"src":require("@/Asset/image/starfield-alt/small/mountain-c.webp")}})]),_c('figure',{staticClass:"mountain",staticStyle:{"left":"68vw","top":"82vh"}},[_c('img',{attrs:{"src":require("@/Asset/image/starfield-alt/small/mountain-d.webp")}})]),_c('figure',{staticClass:"mountain",staticStyle:{"left":"39vw","top":"65vh"}},[_c('img',{attrs:{"src":require("@/Asset/image/starfield-alt/small/mountain-e.webp")}})]),_c('figure',{staticClass:"mountain",staticStyle:{"left":"57vw","top":"81vh"}},[_c('img',{attrs:{"src":require("@/Asset/image/starfield-alt/small/mountain-f.webp")}})]),_c('figure',{staticClass:"mountain",staticStyle:{"left":"37vw","top":"96vh"}},[_c('img',{attrs:{"src":require("@/Asset/image/starfield-alt/small/mountain-g.webp")}})]),_c('figure',{staticClass:"mountain",staticStyle:{"left":"65vw","top":"94vh"}},[_c('img',{attrs:{"src":require("@/Asset/image/starfield-alt/small/mountain-h.webp")}})])])
}]

export { render, staticRenderFns }